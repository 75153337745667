import { useMutation } from "react-query";
import { postDeactivateRequest } from "../../Services/apiBloom";

export function useDeactivateRequest() {
  const {
    mutate: deactiveRequestData,
    isLoading: isDeactiveRequestData,
    error: errorDeactiveRequestData,
  } = useMutation({
    mutationFn: postDeactivateRequest,
  });

  return {
    deactiveRequestData,
    isDeactiveRequestData,
    errorDeactiveRequestData,
  };
}
