import { message } from "antd";
import { Button, Form, Input } from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { ReactComponent as ErrorIcon } from "../../assets/Vector2.svg";
import { useRequestNewPassword } from "./useRequestNewPassword";
import { useNavigate, useParams } from "react-router-dom";

function errorMessage(message) {
  return (
    <>
      <ErrorIcon className="inline-block mb-0.5.l;" />
      <span className="inline-block font-medium	pl-1">{message}</span>
    </>
  );
}

function ChangePassForm() {
  const { requestNewPassData, isRequestNewPassData, errorRequestNewPass } =
    useRequestNewPassword();

  const { slug } = useParams("slug");
  const navigate = useNavigate();

  const onFinish = (values) => {
    if (values.password !== values.confirmPassword) {
      message.error("Passwords do not match. Please try again.!");
    } else {
      requestNewPassData(
        {
          username: values.email,
          password: values.confirmPassword,
          slug: slug,
        },
        {
          onSuccess: (data) => {
            localStorage.setItem("userToken", JSON.stringify(data));

            message.success("New password has been set to your account");
            setTimeout(() => {
              if (localStorage.getItem("userToken")) {
                navigate("/");
                window.location.reload();
              }
            }, 3000);
          },
          onError: (error) => {
            if (error.response && error.response.status === 404) {
              message.error("There is no account with your email address!");
            } else {
              message.error(
                "Connection with the server faild, please try again!"
              );
            }
          },
        }
      );
    }
  };

  return (
    <>
      <h2 className="block text-center mx-auto font-semibold text-2xl mb-0.5">
        Welcome to Agteq <span style={{ color: "#16a34a" }}>Bloom</span>
      </h2>
      <p className="text-center font-light text-base mb-4 text-neutral-500">
        Fill out the form with your information
      </p>
      <div
        className={` 
        register-form w-80 md:w-96 lg:w-96 shadow-md p-6	rounded-2xl border border-slate-200 bg-white`}
      >
        <Form
          name="password"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "string",
                message: errorMessage("Please enter your password!"),
              },
              {
                min: 8,
                message: errorMessage("8 Characher minimum!"),
              },
            ]}
          >
            <Input
              name="email"
              className="p-2.5 rounded-lg mb-2"
              placeholder="Email"
              prefix={<MailOutlined />}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                type: "string",
                message: errorMessage("Please enter your password!"),
              },
              {
                min: 8,
                message: errorMessage("8 Characher minimum!"),
              },
            ]}
          >
            <Input.Password
              name="password"
              className="p-2.5 rounded-lg mb-2"
              placeholder="Password"
              prefix={<LockOutlined />}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            rules={[
              {
                required: true,
                type: "string",
                message: errorMessage("Please enter your password!"),
              },
              {
                min: 8,
                message: errorMessage("8 Characher minimum!"),
              },
            ]}
          >
            <Input.Password
              name="confirmPassword"
              className="p-2.5 rounded-lg mb-2"
              placeholder="Confirm Password"
              prefix={<LockOutlined />}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          <Form.Item>
            <Button
              className="px-4 py-5"
              block
              type="primary"
              htmlType="submit"
              disabled={isRequestNewPassData}
            >
              Change Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default ChangePassForm;
