import { Button, Form, Input, message } from "antd";
import { UserOutlined } from "@ant-design/icons";

import { ReactComponent as ErrorIcon } from "../../assets/Vector2.svg";
import { useForgotPassword } from "./useForgotPassword";

function errorMessage(message) {
  return (
    <>
      <ErrorIcon className="inline-block mb-0.5" />
      <span className="inline-block font-medium	pl-1">{message}</span>
    </>
  );
}

function ForgotpassForm() {
  const { forgotPassData, isForgotPassDataData, errorForgotPassDataData } =
    useForgotPassword();

  const onFinish = (values) => {
    forgotPassData(
      { username: values.email },
      {
        onSuccess: (data) => {
          message.success(
            "Please continue with the link that we sended to your email address"
          );
        },
        onError: (error) => {
          if (error.response && error.response.status === 404) {
            message.error("There is no account with your email address!");
          } else {
            message.error(
              "Connection with the server faild, please try again!"
            );
          }
        },
      }
    );
  };

  return (
    <>
      <h2 className="block text-center mx-auto font-semibold text-2xl mb-0.5">
        Welcome to Agteq <span style={{ color: "#16a34a" }}>Bloom</span>
      </h2>
      <p className="text-center font-light text-base mb-4 text-neutral-500">
        Fill out the form with your information
      </p>
      <div className="login-form w-80 md:w-96 lg:w-96 shadow-md	p-6	rounded-2xl	border	border-slate-200 bg-white">
        <Form
          name="login"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: errorMessage("Please enter your Email address!"),
              },
            ]}
          >
            <Input
              name="email"
              className="p-2.5 rounded-lg mb-2"
              prefix={<UserOutlined />}
              placeholder="Email Address"
            />
          </Form.Item>
          <Form.Item>
            <Button
              className="px-4 py-5"
              block
              type="primary"
              htmlType="submit"
              disabled={isForgotPassDataData}
            >
              Send Email
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default ForgotpassForm;
