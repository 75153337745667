import { useMutation } from "react-query";
import { getGeneralSessionChat } from "../../Services/apiBloom";

export function useGeneralSessionChat() {
  const {
    mutate: generalSessionChatData,
    isLoading: isGeneralSessionChatData,
    error: erroGeneralSessionChatData,
  } = useMutation({
    mutationFn: getGeneralSessionChat,
  });

  return {
    generalSessionChatData,
    isGeneralSessionChatData,
    erroGeneralSessionChatData,
  };
}
