import NavRegister from "../Features/Authentication/NavRegister";
import AuthGrid from "../Ui/AuthGrid";
import BasicLayout from "../Ui/BasicLayout";
import { FloatButton } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

function Register() {
  return (
    <div>
      <BasicLayout>
        <FloatButton icon={<QuestionCircleOutlined />} type="default" />
        <AuthGrid>
          <NavRegister />
        </AuthGrid>
      </BasicLayout>
    </div>
  );
}

export default Register;
