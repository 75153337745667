import { Button, Form, Input, message, Select, Typography } from "antd";
import { useAuth } from "../../Context/AuthProvider";
import { ReactComponent as ErrorIcon } from "../../assets/Vector2.svg";
const { Title } = Typography;

function errorMessage(message) {
  return (
    <>
      <ErrorIcon className="inline-block mb-0.5" />
      <span className="inline-block font-medium	pl-1">{message}</span>
    </>
  );
}
function RegisterFormStep4() {
  const {
    formData,
    setFormData,
    isAuthenticating,
    current,
    handleContinue,
    userTypes,
  } = useAuth();

  function handleSelectReferral(value) {
    setFormData({ ...formData, referral: value });
  }
  function handleSelectRole(value) {
    setFormData({ ...formData, userType: value });
  }

  return (
    <div
      className={` ${current === 3 ? "block" : "hidden"}
      } register-form w-80 md:w-96 lg:w-96 shadow-md p-6	rounded-2xl border border-slate-200 bg-white`}
    >
      <Form
        name="registerStep4"
        initialValues={{
          remember: true,
          referral: "",
          role: "",
        }}
      >
        <Title level={5} className="discover-title">
          How did you discover Agteq?
        </Title>
        <Form.Item
          name="referral"
          rules={[
            {
              required: true,
              message: errorMessage(
                "Please select the way that you discover it!"
              ),
            },
          ]}
        >
          <Select
            showSearch
            className="register-step-4-select mb-2"
            size="large"
            placeholder="Select an option"
            onChange={handleSelectReferral}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={[
              {
                value: 1,
                label: "Linkedin",
              },
              {
                value: 2,
                label: "Google",
              },
              {
                value: 3,
                label: "Facebook",
              },
            ]}
          />
        </Form.Item>
        <Title level={5} className="role-title">
          What best describes your role?
        </Title>
        <Form.Item
          name="role"
          rules={[
            {
              required: true,
              message: errorMessage("Please select your role!"),
            },
          ]}
        >
          <Select
            showSearch
            className="register-step-4-select mb-2"
            size="large"
            placeholder="Select an option"
            onChange={handleSelectRole}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={userTypes?.map((userType) => ({
              value: userType.id,
              label: userType.type_name,
            }))}
          />
        </Form.Item>
        <Form.Item>
          <Button
            className="px-4 py-5"
            block
            type="primary"
            htmlType="submit"
            disabled={isAuthenticating}
            onClick={handleContinue}
          >
            Sign up
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default RegisterFormStep4;
