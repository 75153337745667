import { useMutation } from "react-query";
import { editUser } from "../../Services/apiAuth";

export function useEditUser() {
  const {
    mutate: editUserData,
    isLoading: isEditingUser,
    error: errorEditUserData,
  } = useMutation({
    mutationFn: editUser,
  });

  return {
    editUserData,
    isEditingUser,
    errorEditUserData,
  };
}
