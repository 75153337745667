import { Outlet } from "react-router-dom";
import AppLayout from "../Ui/Layout";

function Bloom() {
  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
}

export default Bloom;
