import { useMutation } from "react-query";
import { register } from "../../Services/apiAuth";

export function useRegister() {
  const { mutate: registerData, isLoading: isRegistering } = useMutation({
    mutationFn: register,
  });

  return {
    registerData,
    isRegistering,
  };
}
