import { useEffect } from "react";
import { useAuth } from "../Context/AuthProvider";
import { useNavigate } from "react-router-dom";

function ProtectedRoute({ children }) {
  const navigate = useNavigate();
  const { isAuthenticating, user } = useAuth();

  useEffect(() => {
    if (!user && !isAuthenticating && !localStorage.getItem("userToken")) {
      navigate("/login");
    }
  }, [user, isAuthenticating, navigate]);

  if (user) {
    return children;
  }
  return null;
}

export default ProtectedRoute;
