import React, { useEffect, useState } from "react";
import { TreeSelect, Typography } from "antd";
import { useChat } from "../Context/ChatProvider";
import { useFarmField } from "../Features/Chat/useFarmField";
import { useFarmFields } from "../Features/Chat/useFarmFields";
import { usePrecisionSessions } from "../Features/Chat/usePrecisionSessions";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const { Text } = Typography;

export default function HeaderSeason() {
  const { dispatch, handleFieldInputChange, currentFarm, currentField } =
    useChat();
  const [expandedkeys, setexpandedkeys] = useState([]);

  const { farmFieldsData, isFarmFieldsData, errorFarmFieldsData } =
    useFarmFields();

  const { farmFieldData, isFarmFieldData, errorFarmFieldData } = useFarmField();

  const {
    precisionSessionsData,
    isPrecisionSessionsData,
    erroPrecisionSessionsData,
  } = usePrecisionSessions();

  const navigate = useNavigate();

  const [treeData, setTreeData] = useState([]);

  const createdAt = jwtDecode(
    localStorage.getItem("userToken")
  ).registered_date;

  const getSeason = (month) => {
    if (month >= 2 && month <= 4) return "spring";
    if (month >= 5 && month <= 7) return "summer";
    if (month >= 8 && month <= 11) return "fall";
    return "winter";
  };

  const generateSeasonData = () => {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const oneYearsFromNow = new Date(
      currentDate.getFullYear() + 1,
      currentDate.getMonth()
    );

    let data = [];
    let year = createdDate.getFullYear();
    let month = createdDate.getMonth();
    let seasonIndex = ["spring", "summer", "fall", "winter"].indexOf(
      getSeason(month)
    );

    const seasons = ["spring", "summer", "fall", "winter"];

    // Generate seasons from createdAt to two years from now
    while (
      year < oneYearsFromNow.getFullYear() ||
      (year === oneYearsFromNow.getFullYear() &&
        seasonIndex <= seasons.indexOf(getSeason(oneYearsFromNow.getMonth())))
    ) {
      // Add a new year object if needed
      if (!data.find((y) => y.title === `${year}`)) {
        data.push({
          title: `${year}`,
          value: `${year}`,
          selectable: false,
          children: [],
        });
      }

      // Add the season to the current year
      data[data.length - 1].children.push({
        title: `${seasons[seasonIndex]} ${year}`,
        value: `${year} ${seasons[seasonIndex]}`, // e.g., "2023 spring"
      });

      // Move to the next season
      seasonIndex = (seasonIndex + 1) % 4;
      if (seasonIndex === 0) year++; // Increment year after Winter
    }

    return data;
  };

  useEffect(() => {
    setTreeData(generateSeasonData());
  }, [createdAt]);

  const [defautSeason, setDefaultSeason] = useState({});

  useEffect(() => {
    if (localStorage.getItem("selectedSeason")) {
      const { season, year } = JSON.parse(
        localStorage.getItem("selectedSeason")
      );
      setDefaultSeason(`${season} ${year}`);
    } else {
      const currentDate = jwtDecode(
        localStorage.getItem("userToken")
      ).registered_date;

      const createdDate = new Date(currentDate);

      let cYear = createdDate.getFullYear();
      let cMonth = createdDate.getMonth() + 1;
      let cSeason = getSeason(cMonth);

      const seasonString = `${cSeason} ${cYear}`;
      setDefaultSeason(seasonString);
    }
  }, []);

  const [selectedValue, setSelectedValue] = useState();

  const onChange = (value) => {
    setSelectedValue(value);

    const [year, season] = value.split(" ");

    localStorage.setItem("selectedSeason", JSON.stringify({ season, year }));
    dispatch({ type: "updateSeasonYear", payload: { season, year } });

    farmFieldsData(
      { farm_id: currentFarm, season: season, year: year },
      {
        onSuccess: (data) => {
          dispatch({ type: "getFarmFields", payload: data.fields });
          farmFieldData(
            {
              farm_field_id: currentField,
              year: year,
              season: season,
            },
            {
              onSuccess: (data) => {
                handleFieldInputChange(
                  "field_name",
                  data.data.field_name ? data.data.field_name : 0
                );
                handleFieldInputChange(
                  "soil_texture",
                  data.data.soil_type ? data.data.soil_type : 0
                );
                handleFieldInputChange(
                  "irregation_type",
                  data.data.irrigation_type ? data.data.irrigation_type : 0
                );
                handleFieldInputChange("planted_area", data?.data.area_in_acre);
                handleFieldInputChange(
                  "approximate_crop_height",
                  data.data?.approximate_crop_height
                );
                handleFieldInputChange(
                  "id",
                  data.data.farm_field_crops?.id
                    ? data.data.farm_field_crops?.id
                    : 0
                );
                handleFieldInputChange(
                  "crop_name",
                  data.data.farm_field_crops?.crop
                    ? data.data.farm_field_crops?.crop
                    : 0
                );
                handleFieldInputChange(
                  "crop_stage",
                  data.data.farm_field_crops?.crop_stage
                    ? data.data.farm_field_crops?.crop_stage
                    : 0
                );
                handleFieldInputChange(
                  "farm",
                  data.data.farm ? data.data.farm : 0
                );
                handleFieldInputChange(
                  "weeds",
                  data.data?.farm_field_crops?.farm_field_weeds
                    ? data.data.farm_field_crops.farm_field_weeds.map(
                        (weed) => ({
                          weed_name: weed.weed,
                          weed_density: weed.weed_density,
                          leaves_number: weed.weed_stage.number_of_leaves,
                          height: weed.weed_stage.heihgt_in_cm,
                          flowering: weed.weed_stage.flowering,
                        })
                      )
                    : []
                );
              },
            }
          );
        },
      }
    );

    precisionSessionsData(
      { season, year },
      {
        onSuccess: (data) => {
          if (data.length !== 0) {
            navigate(`/c/precision/${data[0].slug}`, {
              replace: true,
            });

            dispatch({
              type: "setCurrentPrecisionHash",
              payload: data[0].slug,
            });

            localStorage.setItem("precisionHash", data[0].slug);
          } else {
            localStorage.removeItem("precisionHash");
            navigate(`/c/precision`, {
              replace: true,
            });
          }
        },
      }
    );
  };

  const handleExpand = (keys) => {
    setexpandedkeys(keys);
  };

  return (
    <div className="flex items-center gap-4">
      <Text className="text-neutral-500">Seasons</Text>
      <TreeSelect
        className="w-52 text-neutral-800 season-input"
        showSearch
        defaultValue={defautSeason}
        value={selectedValue || defautSeason}
        treeData={treeData}
        placeholder="Select a season"
        treeDefaultExpandAll={false}
        onChange={onChange}
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
        expandedkeys={expandedkeys}
        onExpand={handleExpand}
      />
    </div>
  );
}
