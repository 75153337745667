import { useMutation } from "react-query";
import { getFarm } from "../../Services/apiBloom";

export function useFarm() {
  const {
    mutate: farmData,
    isLoading: isFarmData,
    error: errorFarmData,
  } = useMutation({
    mutationFn: getFarm,
  });

  return {
    farmData,
    isFarmData,
    errorFarmData,
  };
}
