import { useQuery } from "react-query";
import { getIrregationTypes } from "../../Services/apiBloom";

export function useIrregationTypes() {
  const {
    data: irregationTypesData,
    isLoading: isIrregationTypesData,
    error: errorIrregationTypesData,
  } = useQuery({
    queryKey: ["irregationTypes"],
    queryFn: getIrregationTypes,
  });

  return {
    irregationTypesData,
    isIrregationTypesData,
    errorIrregationTypesData,
  };
}
