import { useQuery } from "react-query";
import { getFarms } from "../../Services/apiBloom";

export function useFarms() {
  const {
    data: farmsData,
    isLoading: isFarms,
    error: errorFarms,
  } = useQuery({
    queryKey: ["farms"],
    queryFn: getFarms,
  });

  return {
    farmsData,
    isFarms,
    errorFarms,
  };
}