import { useQuery } from "react-query";
import { getCropGroups } from "../../Services/apiBloom";

export function useCropGroups() {
  const {
    data: cropGroupsData,
    isLoading: isCropGroups,
    error: errorCropGroups,
  } = useQuery({
    queryKey: ["cropGroups"],
    queryFn: getCropGroups,
  });

  return {
    cropGroupsData,
    isCropGroups,
    errorCropGroups,
  };
}
