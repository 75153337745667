import { useMutation } from "react-query";
import { editFarm } from "../../Services/apiBloom";

export function useEditFarm() {
  const {
    mutate: editFarmData,
    isLoading: isEditFarmData,
    error: errorEditFarmData,
  } = useMutation({
    mutationFn: editFarm,
  });

  return {
    editFarmData,
    isEditFarmData,
    errorEditFarmData,
  };
}
