import { message } from "antd";
import { createContext, useContext, useEffect, useState } from "react";
import { useSendEmail } from "../Features/Authentication/useSendEmail";
import { useOtpValidation } from "../Features/Authentication/useOtpValidation";
import { useRegister } from "../Features/Authentication/useRegister";
import { useRegisterMarketing } from "../Features/Authentication/useRegisterMarketing";
import { useUserType } from "../Features/Authentication/useUserTypes";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { useAuthGoogle } from "../Features/Authentication/useAuthGoogle";
import { useLogin } from "../Features/Authentication/useLogin";
import { useSendGoogleEmail } from "../Features/Authentication/useSendGoogleEmail";

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [current, setCurrent] = useState(0);
  const [userToken, setUserToken] = useState(() =>
    localStorage.getItem("userToken")
      ? JSON.parse(localStorage.getItem("userToken"))
      : null
  );
  const [user, setUser] = useState(
    localStorage.getItem("userToken")
      ? jwtDecode(localStorage.getItem("userToken"))
      : null
  );
  const [isAuthenticating, setIsAuthentication] = useState(false);
  const [userTypes, setUserTypes] = useState(null);
  const [formData, setFormData] = useState({
    email: "",
    otp: "",
    firstName: "",
    lastName: "",
    password: "",
    referral: 0,
    userType: 0,
  });

  const { sendedEmailData, isEmailSending } = useSendEmail();
  const { otpValidateData, isOtpValidating } = useOtpValidation();
  const { registerData, isRegistering } = useRegister();
  const { registerMarketingData, isRegisterMarketing } = useRegisterMarketing();
  const { userTypesData, isUserTypesData, errorUserTypes } = useUserType();
  const { googleData, isGoogling } = useAuthGoogle();
  const { loginData, isLogin } = useLogin();
  const navigate = useNavigate();

  const handleRegisterInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateEmail = () => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(formData.email);
  };

  const {
    sendedEmailGoogleData,
    isEmailGoogleSending,
    errorEmailGoogleSending,
  } = useSendGoogleEmail();

  useEffect(() => {
    if (
      isEmailSending ||
      isOtpValidating ||
      isRegistering ||
      isRegisterMarketing ||
      isGoogling ||
      isEmailGoogleSending ||
      isLogin
    ) {
      setIsAuthentication(true);
    } else {
      setIsAuthentication(false);
    }

    if (!isUserTypesData && !errorUserTypes) {
      setUserTypes(userTypesData);
    }
  }, [
    isEmailSending,
    isOtpValidating,
    isRegistering,
    isRegisterMarketing,
    isUserTypesData,
    errorUserTypes,
    isEmailGoogleSending,
    userTypesData,
    isGoogling,
    isLogin,
  ]);

  useEffect(() => {
    if (!isUserTypesData && !errorUserTypes) {
      setUserTypes(userTypesData);
    }
  }, [isUserTypesData, errorUserTypes, userTypesData]);

  useEffect(() => {
    if (userToken) {
      setUser(jwtDecode(userToken.access).email);
    }
  }, [userToken, user]);

  // Handle form submission for each step
  const handleContinue = async (e) => {
    e.preventDefault();

    if (current === 0) {
      // In step 1 (email validation), you'd typically send OTP to the email
      if (formData.email) {
        if (validateEmail()) {
          sendedEmailData(
            { email: formData.email },
            {
              onSuccess: () => {
                message.success("OTP has been sent to your email!");
                setCurrent(current + 1);
              },
              onError: (error) => {
                if (error.response && error.response.status === 400) {
                  message.error("User already exists");
                } else {
                  message.error(
                    "The email couldn't be sent, please try again!"
                  );
                }
              },
            }
          );
        } else {
          message.error("Please enter a valid email address!");
        }
      } else {
        message.error("Please enter your email address!");
      }
    } else if (current === 1) {
      // Validate OTP
      if (formData.otp) {
        otpValidateData(
          { email: formData.email, code: formData.otp },
          {
            onSuccess: (data) => {
              setUserToken(data);
              setUser(jwtDecode(data.access));
              message.success("OTP verified!");
              setCurrent(current + 1);
            },
            onError: (error) => {
              if (error.response && error.response.status === 422) {
                message.error("The OTP code in wrong!");
              } else {
                message.error(
                  "The OTP code couldn't be sent, please try again!"
                );
              }
            },
          }
        );
      } else {
        message.error("Please enter the otp code completely!");
      }
    } else if (current === 2) {
      // Ensure names and password are valid
      if (formData.firstName && formData.lastName && formData.password) {
        registerData(
          {
            first_name: formData.firstName,
            last_name: formData.lastName,
            password: formData.password,
            access: userToken?.access,
          },
          {
            onSuccess: (data) => {
              if (userTypes) {
                message.success("The information sended successfully");
                localStorage.setItem("userToken", JSON.stringify(data));
                setUserToken(data);
                setUser(jwtDecode(data.access));

                setCurrent(current + 1);
              } else {
                message.error("Sending information failed, please try again!");
              }
            },
            onError: (error) => {
              if (error.response && error.response.status === 400) {
                message.error(
                  "The entire password consists numeric, please use letters as well!"
                );
              } else {
                message.error("Sending information failed, please try again!");
              }
            },
          }
        );
      } else {
        message.error("Please fill in all fields!");
      }
    } else if (current === 3) {
      // Ensure role and discovery are valid
      if (formData.referral && formData.userType) {
        registerMarketingData(
          {
            user_type: formData.userType,
            referral: formData.referral,
            token: JSON.parse(localStorage.getItem("userToken")),
          },
          {
            onSuccess: (data) => {
              setUserToken(data);
              setUser(jwtDecode(data.access));
              localStorage.setItem("userToken", JSON.stringify(data));

              navigate("/");
              setCurrent(0);
              message.success("Your Registration completed.");
            },
            onError: () => {
              message.error("Sending information failed, please try again!");
            },
          }
        );
      } else {
        message.error("Please select the information options!");
      }
    }
  };

  // Go back to the previous step
  const handlePrev = (e) => {
    e.preventDefault();

    setCurrent(current - 1);
  };

  function handleLogin() {
    if (formData.email && formData.password) {
      if (validateEmail()) {
        loginData(
          { email: formData.email, password: formData.password },
          {
            onSuccess: (data) => {
              localStorage.setItem("userToken", JSON.stringify(data));
              setUserToken(data);
              setUser(jwtDecode(data.access));
              message.success("You logged in successfully.");
              navigate("/");
            },
            onError: (error) => {
              if (error.response && error.response.status === 401) {
                message.error("There is no account with your information!");
              } else {
                message.error("Sending data failed, please try again!");
              }
            },
          }
        );
      } else {
        message.error("Please enter a valid password!");
      }
    } else {
      message.error("Please fill in all fields!");
    }
  }

  //handling authentication with email from google account
  const handleRegisterGoogle = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      googleData(
        { access: credentialResponse.access_token },
        {
          onSuccess: (data) => {
            sendedEmailGoogleData(
              {
                email: data.email,
                method: "google",
                first_name: data.given_name,
                last_name: data.family_name,
              },
              {
                onSuccess: (data) => {

                  setUserToken(data);
                  localStorage.setItem("userToken", JSON.stringify(data));
                  setCurrent(2);
                  message.success("Your Google email has been entered");
                },
                onError: (error) => {
                  if (error.response && error.response.status === 422) {
                    message.error("User already exists");
                  } else {
                    message.error(
                      "Connection with the server faild, please try again!"
                    );
                  }
                },
              }
            );
          },
        }
      );
    },
  });

  const handleContinueFacebook = () => {
    message.success("facebook register");
  };

  const handleContinueApple = () => {
    message.success("apple register");
  };

  // Validate email function
  return (
    <AuthContext.Provider
      value={{
        current,
        formData,
        isAuthenticating,
        userToken,
        userTypes,
        user,
        setUserToken,
        setFormData,
        handleContinue,
        handleRegisterInputChange,
        handlePrev,
        validateEmail,
        handleRegisterGoogle,
        handleContinueFacebook,
        handleContinueApple,
        handleLogin,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined)
    throw new Error("AuthContext was used outside of Auth provider");

  return context;
}

export { AuthProvider, useAuth };
