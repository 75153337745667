import { useMutation } from "react-query";
import { authGoogle } from "../../Services/apiAuth";

export function useAuthGoogle() {
  const { mutate: googleData, isLoading: isGoogling } = useMutation({
    mutationFn: authGoogle,
  });

  return {
    googleData,
    isGoogling,
  };
}
