import { useQuery } from "react-query";
import { getGeneralSessions } from "../../Services/apiBloom";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";

export function useGeneralSessions() {
  const {
    data: generalSessionsData,
    isLoading: isGeneralSessionsData,
    error: errorGeneralSessionsData,
  } = useQuery({
    queryKey: ["sessions"],
    queryFn: getGeneralSessions,
  });

  return {
    generalSessionsData,
    isGeneralSessionsData,
    errorGeneralSessionsData,
  };
}
