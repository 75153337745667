import { useMutation } from "react-query";
import { NewPasswordRequest } from "../../Services/apiAuth";

export function useRequestNewPassword() {
  const {
    mutate: requestNewPassData,
    isLoading: isRequestNewPassData,
    error: errorRequestNewPass,
  } = useMutation({
    mutationFn: NewPasswordRequest,
  });

  return {
    requestNewPassData,
    isRequestNewPassData,
    errorRequestNewPass,
  };
}
