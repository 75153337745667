import { Typography } from "antd";
const { Text } = Typography;

function HeaderHelps() {
  return (
    <div className="flex items-center gap-10 mr-7">
      <div className="flex gap-2">
        <span>
          <svg
            className="border-2 rounded-full border-neutral-500"
            width="20px"
            height="20px"
            strokeWidth="5.5"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color="#000000"
          >
            <path
              d="M7.90039 8.07954C7.90039 3.30678 15.4004 3.30682 15.4004 8.07955C15.4004 11.4886 11.9913 10.8067 11.9913 14.8976"
              stroke="#737373"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M12 19.01L12.01 18.9989"
              stroke="#737373"
              strokeWidth="3.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </span>{" "}
        <Text className="text-neutral-500 text-base	font-normal ">Help</Text>
      </div>
      <div className="flex gap-2">
        <span>
          <svg
            width="20px"
            height="20px"
            strokeWidth="5.5"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color="#737373"
          >
            <path
              d="M5 18L3.13036 4.91253C3.05646 4.39524 3.39389 3.91247 3.90398 3.79912L11.5661 2.09641C11.8519 2.03291 12.1481 2.03291 12.4339 2.09641L20.096 3.79912C20.6061 3.91247 20.9435 4.39524 20.8696 4.91252L19 18C18.9293 18.495 18.5 21.5 12 21.5C5.5 21.5 5.07071 18.495 5 18Z"
              stroke="#737373"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </span>{" "}
        <Text className="text-neutral-500 text-base	font-normal">Ai policy</Text>
      </div>
    </div>
  );
}

export default HeaderHelps;
