import { FloatButton } from "antd";
import BasicLayout from "../Ui/BasicLayout";
import AuthGrid from "../Ui/AuthGrid";
import { QuestionCircleOutlined } from "@ant-design/icons";
import ChangePasswordForm from "../Features/Authentication/ChangePassForm";

function ChangePassword() {
  return (
    <BasicLayout>
      <FloatButton icon={<QuestionCircleOutlined />} type="default" />
      <AuthGrid>
        <ChangePasswordForm />
      </AuthGrid>
    </BasicLayout>
  );
}

export default ChangePassword;
