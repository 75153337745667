import { useMutation } from "react-query";
import { editGeneralSessionTitle } from "../../Services/apiBloom";

export function useEditGeneralSessionTitle() {
  const {
    mutate: editGeneralSessionTitleData,
    isLoading: isEditGeneralSessionTitleData,
    error: errorEditGeneralSessionTitleData,
  } = useMutation({
    mutationFn: editGeneralSessionTitle,
  });

  return {
    editGeneralSessionTitleData,
    isEditGeneralSessionTitleData,
    errorEditGeneralSessionTitleData,
  };
}
