import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useChat } from "../../Context/ChatProvider";
import {
  Input,
  Card,
  Button,
  Space,
  Tooltip,
  Avatar,
  message,
  Skeleton,
} from "antd";
import { BrightStar } from "iconoir-react";
import { SendOutlined } from "@ant-design/icons";
import logo from "../../assets/logo.png";
import { useSendGeneralChat } from "./useSendGeneralChat";
import CryptoJS from "crypto-js";
import { useGeneralSessionChat } from "./useGeneralSessionChat";
import { useSendPrecisionChat } from "./useSendPrecisionChat";
import { usePrecisionSessionChat } from "./usePrecisionSessionChat";
import { useFarmFields } from "./useFarmFields";
import { useGeneralHostSendChat } from "./useGeneralHostSendChat";
import { marked } from "marked";
import { usePrecisionHostSendChat } from "./usePrecisionHostSendChat";
import { useSoilTexture } from "./useSoilTypes";
import { useIrregationTypes } from "./useIrregationTypes";
import { useCropGroups } from "./useCropGroups";
import { useCrops } from "./useCrops";
import { useWeeds } from "./useWeeds";
import { useWeeedDensities } from "./useWeedDensities";
import { useFarm } from "./useFarm";
import { useFarms } from "./useFarms";
import { useFarmField } from "./useFarmField";
import SyncLoader from "react-spinners/SyncLoader";
import { Copy } from 'iconoir-react';

const { TextArea } = Input;

function Chat() {
  const { mode, hash } = useParams();
  const {
    dispatch,
    bloomUser,
    fieldFormData,
    year,
    season,
    currentFarm,
    generalSessions,
    farmFields,
    currentField,
    stringLog,
    handleFarmInputChange,
    handleFieldInputChange,
  } = useChat();

  const navigate = useNavigate();

  const {
    sendGeneralChatData,
    isSendGeneralChatData,
    errorSendGeneralChatData,
  } = useSendGeneralChat();

  const {
    sendHostGeneralChatData,
    isHostSendGeneralChatData,
    errorHostSendQuickChatData,
  } = useGeneralHostSendChat();

  const {
    sendHostPrecisionChatData,
    isHostSendPrecisionChatData,
    errorHostSendPrecisionChatData,
  } = usePrecisionHostSendChat();

  const {
    generalSessionChatData,
    isGeneralSessionChatData,
    erroGeneralSessionChatData,
  } = useGeneralSessionChat();

  const { sendPrecisionData, isSendPrecisionData, errorSendPrecisionData } =
    useSendPrecisionChat();

  const { farmFieldsData, isFarmFieldsData, errorFarmFieldsData } =
    useFarmFields();

  const {
    precisionSessionChatData,
    isPrecisionSessionChatData,
    erroPrecisionSessionChatData,
  } = usePrecisionSessionChat();

  const { soilTextureData, isSoilTextureData, errorSoilTextureData } =
    useSoilTexture();

  const {
    irregationTypesData,
    isIrregationTypesData,
    errorIrregationTypesData,
  } = useIrregationTypes();

  const { cropGroupsData, isCropGroups, errorCropGroups } = useCropGroups();

  function findCropById(id) {
    // Recursive function to search for the crop by id
    const search = (crops) => {
      if (!isCropGroups && !errorCropGroups) {
        for (let category of crops) {
          // Iterate through the crops array
          for (let crop of category.crops) {
            if (crop.id === id) {
              return crop.crop_name; // Return the crop_name if id matches
            }

            // If there are children, search recursively
            if (crop.children.length > 0) {
              const result = search([{ crops: crop.children }]);
              if (result) return result; // Return if found in children
            }
          }
        }
      }
      return null; // Return null if not found
    };

    return search(cropGroupsData);
  }
  const { cropData, isCropData } = useCrops();
  const [previewStage, setPreviewStages] = useState();

  useEffect(() => {
    cropData(
      { crop_id: fieldFormData.crop_name },
      {
        onSuccess: (data) => {
          setPreviewStages(data?.crop_stages);
        },
        onError: () => {
          return null;
        },
      }
    );
  }, [fieldFormData.crop_name, cropData]);
  const { weedsData, isWeeds, errorWeedsData } = useWeeds();

  const { weedDensitiesData, isWeedDensities, errorWeedDensitiesData } =
    useWeeedDensities();

  useEffect(() => {
    if (mode === "general") {
      dispatch({ type: "selectChat", payload: "general" });
    } else if (mode === "precision") {
      dispatch({ type: "selectChat", payload: "precision" });
    }
  }, [mode, dispatch]);

  const [textMessage, setTextMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [precisionChatHistory, setPrecisionChatHistory] = useState([]);
  const [skeletonChecker, setSkeletonChecker] = useState(false);
  const messagesEndRef = useRef(null);
  const messagesPrecisionEndRef = useRef(null);

  useEffect(() => {
    if (mode === "general") {
      if (hash) {
        generalSessionChatData(
          { slug: hash },
          {
            onSuccess: (data) => {
              setChatHistory(
                data.data.chats?.flatMap((chat) => [
                  {
                    type: "user",
                    message: chat.query,
                  },
                  {
                    type: "ai",
                    message: chat.answer,
                  },
                ])
              );
              document.getElementById("generalTxtBox")?.focus();
            },
            onError: () => message.error("Connection with the server failed!"),
          }
        );
      } else {
        setChatHistory([]);
      }
    } else {
      if (hash) {
        if (farmFields) {
          const current = farmFields?.find((field) =>
            field?.sessions.find((session) =>
              session.slug === hash ? field : null
            )
          )?.id;

          precisionSessionChatData(
            { slug: hash },
            {
              onSuccess: (data) => {
                setPrecisionChatHistory(
                  data.chats?.flatMap((chat) => [
                    {
                      type: "user",
                      message: chat.query,
                      field_updated: chat.field_updated,
                    },
                    {
                      type: "ai",
                      message: chat.answer,
                      field_updated: chat.field_updated,
                    },
                  ])
                );
                dispatch({ type: "selectSideBarMode", payload: "Field" });
                document.getElementById("precisionTxtBox")?.focus();
              },
              onError: () =>
                message.error("Connection with the server failed!"),
            }
          );
        }
      } else {
        setPrecisionChatHistory([]);
      }
    }

    setTextMessage("");
  }, [hash]);

  // useEffect(() => {
  //   if (messagesEndRef.current) {
  //     messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [chatHistory]);
  // //  messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const precisionScrollToBottom = () => {
    if (messagesPrecisionEndRef.current) {
      messagesPrecisionEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  useEffect(() => {
    precisionScrollToBottom();
  }, [precisionChatHistory]);

  useEffect(() => {
    // Replace `currentResponseText` with the variable holding the ongoing AI response text
    if (chatHistory && chatHistory.some((chat) => chat.type === "ai")) {
      scrollToBottom(); // Scroll on every chunk update
    }
  }, [chatHistory.some((chat) => chat.type === "ai")]);


  const hashGenerator = () => {
    const date = new Date();

    const data = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}-${String(
      date.getHours()
    ).padStart(2, "0")}-${String(date.getMinutes()).padStart(2, "0")}-${String(
      date.getSeconds()
    ).padStart(2, "0")}-${bloomUser.user_id}`;

    const hash =
      CryptoJS.SHA256(data).toString(CryptoJS.enc.Hex) +
      "-" +
      currentFarm +
      "-" +
      currentField;

    return hash;
  };

  const [fieldText, setFieldText] = useState("");

  useEffect(() => {
    if (!fieldFormData.crop_name && !fieldFormData.crop_stage) {
      setFieldText(
        JSON.stringify({
          farm: fieldFormData.farm,
          field_name: fieldFormData.field_name,
          soil_texture: soilTextureData?.find(
            (soil) => soil.id === fieldFormData.soil_texture
          )
            ? soilTextureData?.find(
                (soil) => soil.id === fieldFormData.soil_texture
              )?.type_name
            : 0,
          irregation_type: irregationTypesData?.find(
            (irregation) => irregation.id === fieldFormData.irregation_type
          )
            ? irregationTypesData?.find(
                (irregation) => irregation.id === fieldFormData.irregation_type
              )?.type_name
            : 0,
          planted_area: fieldFormData.planted_area,
          approximate_crop_height: fieldFormData.approximate_crop_height,
          crop_name: "",
          crop_stage: "",
          weeds: fieldFormData.weeds
            ? fieldFormData.weeds?.map((weed) => ({
                weed_name: weedsData?.find(
                  (secWeed) => secWeed.id === weed?.weed_name
                )?.weed_type
                  ? weedsData?.find((secWeed) => secWeed.id === weed?.weed_name)
                      ?.weed_type
                  : 0,
                weed_density: weedDensitiesData?.find(
                  (density) => density.id === weed.weed_density
                )?.weed_density
                  ? weedDensitiesData?.find(
                      (density) => density.id === weed.weed_density
                    )?.weed_density
                  : 0,
                leaves_number: weed.leaves_number,
                height: weed.height,
                flowering: weed.flowering,
              }))
            : [],
        })
      );
    }

    if (fieldFormData.crop_name && !fieldFormData.crop_stage) {
      setFieldText(
        JSON.stringify({
          farm: fieldFormData.farm,
          field_name: fieldFormData.field_name,
          soil_texture: soilTextureData?.find(
            (soil) => soil.id === fieldFormData.soil_texture
          )
            ? soilTextureData?.find(
                (soil) => soil.id === fieldFormData.soil_texture
              )?.type_name
            : 0,
          irregation_type: irregationTypesData?.find(
            (irregation) => irregation.id === fieldFormData.irregation_type
          )
            ? irregationTypesData?.find(
                (irregation) => irregation.id === fieldFormData.irregation_type
              )?.type_name
            : 0,
          planted_area: fieldFormData.planted_area,
          approximate_crop_height: fieldFormData.approximate_crop_height,
          crop_name: findCropById(fieldFormData.crop_name),
          crop_stage: "",
          weeds: fieldFormData.weeds
            ? fieldFormData.weeds?.map((weed) => ({
                weed_name: weedsData?.find(
                  (secWeed) => secWeed.id === weed?.weed_name
                )?.weed_type
                  ? weedsData?.find((secWeed) => secWeed.id === weed?.weed_name)
                      ?.weed_type
                  : 0,
                weed_density: weedDensitiesData?.find(
                  (density) => density.id === weed?.weed_density
                )?.weed_density
                  ? weedDensitiesData?.find(
                      (density) => density.id === weed?.weed_density
                    )?.weed_density
                  : 0,
                leaves_number: weed.leaves_number,
                height: weed.height,
                flowering: weed.flowering,
              }))
            : [],
        })
      );
    }

    if (fieldFormData.crop_name && fieldFormData.crop_stage) {
      cropData(
        { crop_id: fieldFormData?.crop_name },
        {
          onSuccess: (cropData) => {
            setFieldText(
              JSON.stringify({
                farm: fieldFormData.farm,
                field_name: fieldFormData.field_name,
                soil_texture: soilTextureData?.find(
                  (soil) => soil.id === fieldFormData.soil_texture
                )
                  ? soilTextureData?.find(
                      (soil) => soil.id === fieldFormData.soil_texture
                    )?.type_name
                  : 0,
                irregation_type: irregationTypesData?.find(
                  (irregation) =>
                    irregation.id === fieldFormData.irregation_type
                )?.type_name
                  ? irregationTypesData?.find(
                      (irregation) =>
                        irregation.id === fieldFormData.irregation_type
                    )?.type_name
                  : 0,
                planted_area: fieldFormData.planted_area,
                approximate_crop_height: fieldFormData.approximate_crop_height,
                crop_name: findCropById(fieldFormData.crop_name),
                crop_stage: !cropData.crop_stages
                  ? null
                  : cropData.crop_stages.find(
                      (stage) => stage.id === fieldFormData.crop_stage
                    )?.stage_name,
                weeds: fieldFormData.weeds
                  ? fieldFormData.weeds?.map((weed) => ({
                      weed_name: weedsData?.find(
                        (secWeed) => secWeed.id === weed?.weed_name
                      )?.weed_type
                        ? weedsData?.find(
                            (secWeed) => secWeed.id === weed?.weed_name
                          )?.weed_type
                        : 0,
                      weed_density: weedDensitiesData?.find(
                        (density) => density.id === weed.weed_density
                      )
                        ? weedDensitiesData?.find(
                            (density) => density.id === weed.weed_density
                          )?.weed_density
                        : 0,
                      leaves_number: weed.leaves_number,
                      height: weed.height,
                      flowering: weed.flowering,
                    }))
                  : [],
              })
            );
          },
          onError: (error) => {
            console.error(error);
          },
        }
      );
    }
  }, [fieldFormData]);

  const handleSendMessage = () => {
    if (textMessage.trim()) {
      if (mode === "general") {
        setTextMessage("");

        if (!hash) {
          const elementId = "testGenerate";

          setChatHistory([
            ...chatHistory,
            ...[
              {
                type: "user",
                message: textMessage,
              },
              {
                type: "ai",
                message: "",
                elementId,
              },
            ],
          ]);

          sendHostGeneralChatData(
            {
              query: textMessage,
              thread_id: null,
              elementId: elementId,
            },
            {
              onSuccess: (data) => {
                setSkeletonChecker(true);
                sendGeneralChatData(
                  {
                    query: textMessage,
                    slug: encodeURIComponent(hashGenerator()),
                    thread_id: data.threadId,
                    answer: data.assistantResponse,
                  },
                  {
                    onSuccess: (data) => {
                      setTextMessage("");
                      navigate(`/c/general/${data.session.slug}`, {
                        replace: true,
                      });

                      dispatch({
                        type: "setCurrentGeneralHash",
                        payload: data.session.slug,
                      });

                      localStorage.setItem("generalHash", data.session.slug);

                      dispatch({
                        type: "tempGeneralSessionsUpdate",
                        payload: data.session,
                      });

                      generalSessionChatData(
                        { slug: data.session.slug },
                        {
                          onSuccess: (data) => {
                            setChatHistory(
                              data.data.chats?.flatMap((chat) => [
                                {
                                  type: "user",
                                  message: chat.query,
                                },
                                {
                                  type: "ai",
                                  message: chat.answer,
                                },
                              ])
                            );
                            setSkeletonChecker(true);
                            document.getElementById("generalTxtBox").focus();
                          },
                        }
                      );
                    },
                  }
                );
              },
              onError: () => {
                setChatHistory([]);
                setSkeletonChecker(false);
                message.error("Connection with the server failed!");
              },
            }
          );
        } else {
          const thread = generalSessions.find(
            (session) => session.slug === hash
          )?.thread_id;

          const elementId = "testGenerate";

          setChatHistory((prevChatHistory) => [
            ...prevChatHistory,
            {
              type: "user",
              message: textMessage,
            },
            {
              type: "ai",
              message: "",
              elementId,
            },
          ]);

          sendHostGeneralChatData(
            { query: textMessage, thread_id: thread, elementId },
            {
              onSuccess: (data) => {
                setSkeletonChecker(true);
                sendGeneralChatData(
                  {
                    query: textMessage,
                    slug: hash,
                    thread_id: data.threadId,
                    answer: data.assistantResponse,
                  },
                  {
                    onSuccess: (data) => {
                      setTextMessage("");
                      // generalSessionChatData(
                      //   { slug: data.session.slug },
                      //   {
                      //     onSuccess: (data) => {
                      //       // setChatHistory((prevChatHistory) => [
                      //       //   ...prevChatHistory,
                      //       //   ...data.data.chats?.flatMap((chat) => [
                      //       //     {
                      //       //       type: "user",
                      //       //       message: chat.query,
                      //       //     },
                      //       //     {
                      //       //       type: "ai",
                      //       //       message: chat.answer,
                      //       //     },
                      //       //   ]),
                      //       // ]);

                      //       setChatHistory((prevChatHistory) => {
                      //         const existingMessages = prevChatHistory.map(
                      //           (chat) => chat.message
                      //         ); // Get existing messages
                      //         const newMessages = data.data.chats.filter(
                      //           (chat) =>
                      //             !existingMessages.includes(chat.answer) // Filter out duplicates
                      //         );

                      //         return [
                      //           ...prevChatHistory,
                      //           ...newMessages.flatMap((chat) => [
                      //             { type: "user", message: chat.query },
                      //             { type: "ai", message: chat.answer },
                      //           ]),
                      //         ];
                      //       });

                      //       document.getElementById("generalTxtBox").focus();
                      //     },
                      //   }
                      // );

                      generalSessionChatData(
                        { slug: data.session.slug },
                        {
                          onSuccess: (data) => {
                            setChatHistory((prevChatHistory) =>
                              prevChatHistory.slice(0, -2)
                            );
                            setChatHistory((prevChatHistory) => {
                              const existingMessages = prevChatHistory.map(
                                (chat) => chat.message
                              ); // Get existing messages
                              const newMessages = data.data.chats.filter(
                                (chat) =>
                                  !existingMessages.includes(chat.answer) // Filter out duplicates
                              );

                              return [
                                ...prevChatHistory,
                                ...newMessages.flatMap((chat) => [
                                  { type: "user", message: chat.query },
                                  { type: "ai", message: chat.answer },
                                ]),
                              ];
                            });
                            setSkeletonChecker(false);
                            document.getElementById("generalTxtBox").focus();
                          },
                        }
                      );
                    },
                  }
                );
              },
              onError: () => {
                setChatHistory(chatHistory);
                setSkeletonChecker(false);
                message.error("Connection with the server failed!");
              },
            }
          );
        }
      } else {
        setTextMessage("");

        if (!hash) {
          const elementId = "testGenerate";
          const updatedText = `This is my field data : ${fieldText} , but don't mention anything about it in your first response to the user,just response to the question that sended to you (this was from admin) and my first question to you is : ${textMessage}`;

          if (fieldText) {
            setPrecisionChatHistory([
              ...precisionChatHistory,
              ...[
                {
                  type: "user",
                  message: textMessage,
                },
                {
                  type: "ai",
                  message: "",
                  elementId,
                },
              ],
            ]);

            sendHostPrecisionChatData(
              {
                query: updatedText,
                field: {
                  field_name: fieldFormData.field_name,
                  soil_texture: !fieldFormData.soil_texture
                    ? ""
                    : soilTextureData?.find(
                        (soil) => soil.id === fieldFormData.soil_texture
                      )?.type_name,
                  irregation_type: !fieldFormData.irregation_type
                    ? ""
                    : irregationTypesData?.find(
                        (type) => type.id === fieldFormData.irregation_type
                      )?.type_name,
                  planted_area: !fieldFormData.planted_area
                    ? ""
                    : fieldFormData.planted_area,
                  approximate_crop_height:
                    !fieldFormData.approximate_crop_height
                      ? ""
                      : fieldFormData.approximate_crop_height,
                  crop_name: !fieldFormData.crop_name
                    ? ""
                    : findCropById(fieldFormData.crop_name),
                  crop_stage: !fieldFormData.crop_stage
                    ? ""
                    : previewStage?.find(
                        (stage) => stage.id === fieldFormData.crop_stage
                      )?.stage_name,
                  weeds: !fieldFormData
                    ? []
                    : fieldFormData.weeds.map((weed) => ({
                        weed_name: weedsData?.find(
                          (type) => type.id === weed.weed_name
                        ).weed_type,
                        weed_density: weedDensitiesData?.find(
                          (density) => density.id === weed.weed_density
                        ).weed_density,
                        leaves_number: !weed.leaves_number
                          ? ""
                          : weed.leaves_number,
                        height: !weed.height ? "" : weed.height,
                        flowering: !weed.flowering ? false : weed.flowering,
                      })),
                },
                thread_id: null,
                elementId,
              },
              {
                onSuccess: (data) => {
                  setSkeletonChecker(true);
                  sendPrecisionData(
                    {
                      query: textMessage,
                      slug: encodeURIComponent(hashGenerator()),
                      farm_field_crop: fieldFormData.id,
                      thread_id: data.threadId,
                      answer: data.assistantResponse,
                    },
                    {
                      onSuccess: (data) => {
                        setTextMessage("");
                        navigate(`/c/precision/${data.session.slug}`, {
                          replace: true,
                        });

                        dispatch({
                          type: "setCurrentPrecisionHash",
                          payload: data.session.slug,
                        });

                        localStorage.setItem(
                          "precisionHash",
                          data.session.slug
                        );

                        dispatch({
                          type: "tempPrecisionSessionsUpdate",
                          payload: data.session,
                        });

                        farmFieldsData(
                          { farm_id: currentFarm, season: season, year: year },
                          {
                            onSuccess: (data) => {
                              dispatch({
                                type: "getFarmFields",
                                payload: data.fields,
                              });
                            },
                          }
                        );

                        precisionSessionChatData(
                          {
                            slug: data.session.slug,
                          },
                          {
                            onSuccess: (data) => {
                              setPrecisionChatHistory(
                                data.chats?.flatMap((chat) => [
                                  {
                                    type: "user",
                                    message: chat.query,
                                    field_updated: chat.field_updated,
                                  },
                                  {
                                    type: "ai",
                                    message: chat.answer,
                                    field_updated: chat.field_updated,
                                  },
                                ])
                              );
                              dispatch({
                                type: "selectSideBarMode",
                                payload: "Field",
                              });

                              document
                                .getElementById("precisionTxtBox")
                                .focus();
                              setTimeout(() => {
                                document
                                  .getElementById("precisionTxtBox")
                                  .focus();
                              }, 300);
                            },
                            onError: () => {
                              message.error(
                                "Connection with the server failed!"
                              );
                              setSkeletonChecker(false);
                              setPrecisionChatHistory([]);
                            },
                          }
                        );
                      },
                    }
                  );
                },
                onError: () => {
                  message.error("Connection with the server failed!");
                  setSkeletonChecker(false);
                  setPrecisionChatHistory([]);
                },
              }
            );
          }
        } else {
          const thread = farmFields
            ?.find((field) => field.id === currentField)
            ?.sessions.find((session) => session.slug === hash)?.thread_id;

          const elementId = "testGeneratePrecision";

          setPrecisionChatHistory([
            ...precisionChatHistory,
            ...[
              {
                type: "user",
                message: textMessage,
              },
              {
                type: "ai",
                message: "",
                elementId,
              },
            ],
          ]);

          sendHostPrecisionChatData(
            {
              query: textMessage,
              field: {
                field_name: fieldFormData.field_name,
                soil_texture: !fieldFormData.soil_texture
                  ? ""
                  : soilTextureData?.find(
                      (soil) => soil.id === fieldFormData.soil_texture
                    ).type_name,
                irregation_type: !fieldFormData.irregation_type
                  ? ""
                  : irregationTypesData?.find(
                      (type) => type.id === fieldFormData.irregation_type
                    ).type_name,
                planted_area: !fieldFormData.planted_area
                  ? ""
                  : fieldFormData.planted_area,
                approximate_crop_height: !fieldFormData.approximate_crop_height
                  ? ""
                  : fieldFormData.approximate_crop_height,
                crop_name: !fieldFormData.crop_name
                  ? ""
                  : findCropById(fieldFormData.crop_name),
                crop_stage: !fieldFormData.crop_stage
                  ? ""
                  : previewStage?.find(
                      (stage) => stage.id === fieldFormData.crop_stage
                    ).stage_name,
                weeds: !fieldFormData
                  ? []
                  : fieldFormData.weeds.map((weed) => ({
                      weed_name: weedsData?.find(
                        (type) => type.id === weed.weed_name
                      ).weed_type,
                      weed_density: weedDensitiesData?.find(
                        (density) => density.id === weed.weed_density
                      ).weed_density,
                      leaves_number: !weed.leaves_number
                        ? ""
                        : weed.leaves_number,
                      height: !weed.height ? "" : weed.height,
                      flowering: !weed.flowering ? false : weed.flowering,
                    })),
              },
              thread_id: thread,
              elementId,
            },
            {
              onSuccess: (data) => {
                setSkeletonChecker(true);
                sendPrecisionData(
                  {
                    query: textMessage,
                    slug: hash,
                    farm_field_crop: fieldFormData.id,
                    thread_id: data.threadId,
                    answer: data.assistantResponse,
                  },
                  {
                    onSuccess: (data) => {
                      setTextMessage("");

                      precisionSessionChatData(
                        { slug: hash },
                        {
                          onSuccess: (data) => {
                            setPrecisionChatHistory(
                              data.chats?.flatMap((chat) => [
                                {
                                  type: "user",
                                  message: chat.query,
                                  field_updated: chat.field_updated,
                                },
                                {
                                  type: "ai",
                                  message: chat.answer,
                                  field_updated: chat.field_updated,
                                },
                              ])
                            );
                            setSkeletonChecker(false);
                            document.getElementById("precisionTxtBox").focus();
                          },
                        }
                      );
                    },
                  }
                );
              },
              onError: () => {
                setSkeletonChecker(false);
                precisionSessionChatData(
                  { slug: hash },
                  {
                    onSuccess: (data) => {
                      setPrecisionChatHistory(
                        data.chats?.flatMap((chat) => [
                          {
                            type: "user",
                            message: chat.query,
                            field_updated: chat.field_updated,
                          },
                          {
                            type: "ai",
                            message: chat.answer,
                            field_updated: chat.field_updated,
                          },
                        ])
                      );
                      document.getElementById("precisionTxtBox")?.focus();
                    },
                    onError: () =>
                      message.error("Connection with the server failed!"),
                  }
                );
                message.error("Connection with the server failed!");
              },
            }
          );
        }
      }
    }
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  marked.setOptions({
    gfm: true,
    breaks: true, // Adds line breaks as needed
  });

  useEffect(() => {
    if (stringLog !== "") {
      if (farmFields.find((field) => field.id === currentField)?.sessions) {
        if (hash) {
          const thread = farmFields
            .find((field) => field.id === currentField)
            .sessions.find((session) => session.slug === hash)?.thread_id;

          const elementId = "testGeneratePrecision";

          farmFields
            .find((field) => field.id === currentField)
            .sessions?.map((session, i) => {
              if (session?.thread_id !== thread) {
                sendHostPrecisionChatData(
                  {
                    query: stringLog,
                    updating: true,
                    // field: {
                    //   field_name: fieldFormData.field_name,
                    //   soil_texture: !fieldFormData.soil_texture
                    //     ? ""
                    //     : soilTextureData?.find(
                    //         (soil) => soil.id === fieldFormData.soil_texture
                    //       ).type_name,
                    //   irregation_type: !fieldFormData.irregation_type
                    //     ? ""
                    //     : irregationTypesData?.find(
                    //         (type) => type.id === fieldFormData.irregation_type
                    //       ).type_name,
                    //   planted_area: !fieldFormData.planted_area
                    //     ? ""
                    //     : fieldFormData.planted_area,
                    //   approximate_crop_height: !fieldFormData.approximate_crop_height
                    //     ? ""
                    //     : fieldFormData.approximate_crop_height,
                    //   crop_name: !fieldFormData.crop_name
                    //     ? ""
                    //     : findCropById(fieldFormData.crop_name),
                    //   crop_stage: !fieldFormData.crop_stage
                    //     ? ""
                    //     : previewStage?.find(
                    //         (stage) => stage.id === fieldFormData.crop_stage
                    //       ).stage_name,
                    //   weeds: !fieldFormData
                    //     ? []
                    //     : fieldFormData.weeds.map((weed) => ({
                    //         weed_name: weedsData?.find(
                    //           (type) => type.id === weed.weed_name
                    //         ).weed_type,
                    //         weed_density: weedDensitiesData?.find(
                    //           (density) => density.id === weed.weed_density
                    //         ).weed_density,
                    //         leaves_number: !weed.leaves_number
                    //           ? ""
                    //           : weed.leaves_number,
                    //         height: !weed.height ? "" : weed.height,
                    //         flowering: !weed.flowering ? false : weed.flowering,
                    //       })),
                    // },
                    thread_id: session.thread_id,
                  },
                  {
                    onSuccess: (data) => {
                      sendPrecisionData(
                        {
                          query: stringLog,
                          slug: session.slug,
                          farm_field_crop: fieldFormData.id,
                          thread_id: session.thread_id,
                          answer: data?.assistantResponse,
                          field_updated: true,
                        },
                        {
                          onSuccess: (data) => {
                            setTextMessage("");
                          },
                        }
                      );
                    },
                  }
                );
              }
            });
        } else {
          farmFields
            .find((field) => field.id === currentField)
            ?.sessions?.map((session, i) => {
              sendHostPrecisionChatData(
                {
                  query: stringLog,
                  updating: true,
                  // field: {
                  //   field_name: fieldFormData.field_name,
                  //   soil_texture: !fieldFormData.soil_texture
                  //     ? ""
                  //     : soilTextureData?.find(
                  //         (soil) => soil.id === fieldFormData.soil_texture
                  //       ).type_name,
                  //   irregation_type: !fieldFormData.irregation_type
                  //     ? ""
                  //     : irregationTypesData?.find(
                  //         (type) => type.id === fieldFormData.irregation_type
                  //       ).type_name,
                  //   planted_area: !fieldFormData.planted_area
                  //     ? ""
                  //     : fieldFormData.planted_area,
                  //   approximate_crop_height: !fieldFormData.approximate_crop_height
                  //     ? ""
                  //     : fieldFormData.approximate_crop_height,
                  //   crop_name: !fieldFormData.crop_name
                  //     ? ""
                  //     : findCropById(fieldFormData.crop_name),
                  //   crop_stage: !fieldFormData.crop_stage
                  //     ? ""
                  //     : previewStage?.find(
                  //         (stage) => stage.id === fieldFormData.crop_stage
                  //       ).stage_name,
                  //   weeds: !fieldFormData
                  //     ? []
                  //     : fieldFormData.weeds.map((weed) => ({
                  //         weed_name: weedsData?.find(
                  //           (type) => type.id === weed.weed_name
                  //         ).weed_type,
                  //         weed_density: weedDensitiesData?.find(
                  //           (density) => density.id === weed.weed_density
                  //         ).weed_density,
                  //         leaves_number: !weed.leaves_number
                  //           ? ""
                  //           : weed.leaves_number,
                  //         height: !weed.height ? "" : weed.height,
                  //         flowering: !weed.flowering ? false : weed.flowering,
                  //       })),
                  // },
                  thread_id: session.thread_id,
                },
                {
                  onSuccess: (data) => {
                    sendPrecisionData(
                      {
                        query: stringLog,
                        slug: session.slug,
                        farm_field_crop: fieldFormData.id,
                        thread_id: session.thread_id,
                        answer: data?.assistantResponse,
                        field_updated: true,
                      },
                      {
                        onSuccess: (data) => {
                          setTextMessage("");
                          dispatch({
                            type: "getStringLog",
                            payload: "",
                          });
                          // precisionSessionChatData(
                          //   { slug: session.slug },
                          //   {
                          //     onSuccess: (data) => {
                          //       console.log(data);
                          //       setPrecisionChatHistory(
                          //         data.chats.flatMap((chat) => [
                          //           {
                          //             type: "user",
                          //             message: chat.query,
                          //             field_updated: chat.field_updated,
                          //           },
                          //           {
                          //             type: "ai",
                          //             message: chat.answer,
                          //             field_updated: chat.field_updated,
                          //           },
                          //         ])
                          //       );
                          //       document
                          //         .getElementById("precisionTxtBox")
                          //         .focus();

                          //       console.log("clear log");

                          //       dispatch({ type: "getStringLog", payload: "" });
                          //     },
                          //   }
                          // );
                        },
                      }
                    );
                  },
                  onError: () =>
                    message.error("Connection with the server failed!"),
                }
              );
            });
        }
      } else {
        dispatch({
          type: "getStringLog",
          payload: "",
        });

        dispatch({ type: "getBackUpFieldData", payload: {} });
      }

      if (hash) {
        const thread = farmFields
          ?.find((field) => field.id === currentField)
          ?.sessions?.find((session) => session.slug === hash)?.thread_id;

        const elementId = "testGeneratePrecision";

        setPrecisionChatHistory([
          ...precisionChatHistory,
          ...[
            {
              type: "user",
              message: stringLog,
              field_updated: true,
            },
            {
              type: "ai",
              message: "",
              field_updated: true,
              elementId,
            },
          ],
        ]);

        sendHostPrecisionChatData(
          {
            query: stringLog,
            // field: {
            //   field_name: fieldFormData.field_name,
            //   soil_texture: !fieldFormData.soil_texture
            //     ? ""
            //     : soilTextureData?.find(
            //         (soil) => soil.id === fieldFormData.soil_texture
            //       ).type_name,
            //   irregation_type: !fieldFormData.irregation_type
            //     ? ""
            //     : irregationTypesData?.find(
            //         (type) => type.id === fieldFormData.irregation_type
            //       ).type_name,
            //   planted_area: !fieldFormData.planted_area
            //     ? ""
            //     : fieldFormData.planted_area,
            //   approximate_crop_height: !fieldFormData.approximate_crop_height
            //     ? ""
            //     : fieldFormData.approximate_crop_height,
            //   crop_name: !fieldFormData.crop_name
            //     ? ""
            //     : findCropById(fieldFormData.crop_name),
            //   crop_stage: !fieldFormData.crop_stage
            //     ? ""
            //     : previewStage?.find(
            //         (stage) => stage.id === fieldFormData.crop_stage
            //       ).stage_name,
            //   weeds: !fieldFormData
            //     ? []
            //     : fieldFormData.weeds.map((weed) => ({
            //         weed_name: weedsData?.find(
            //           (type) => type.id === weed.weed_name
            //         ).weed_type,
            //         weed_density: weedDensitiesData?.find(
            //           (density) => density.id === weed.weed_density
            //         ).weed_density,
            //         leaves_number: !weed.leaves_number
            //           ? ""
            //           : weed.leaves_number,
            //         height: !weed.height ? "" : weed.height,
            //         flowering: !weed.flowering ? false : weed.flowering,
            //       })),
            // },
            updating: false,
            thread_id: thread,
            elementId,
          },
          {
            onSuccess: (data) => {
              setSkeletonChecker(true);
              sendPrecisionData(
                {
                  query: stringLog,
                  slug: hash,
                  farm_field_crop: fieldFormData.id,
                  thread_id: thread,
                  answer: data?.assistantResponse,
                  field_updated: true,
                },
                {
                  onSuccess: (data) => {
                    setTextMessage("");

                    precisionSessionChatData(
                      { slug: hash },
                      {
                        onSuccess: (data) => {
                          setPrecisionChatHistory(
                            data.chats?.flatMap((chat) => [
                              {
                                type: "user",
                                message: chat.query,
                                field_updated: chat.field_updated,
                              },
                              {
                                type: "ai",
                                message: chat.answer,
                                field_updated: chat.field_updated,
                              },
                            ])
                          );
                          document.getElementById("precisionTxtBox")?.focus();
                          setSkeletonChecker(false)
                          dispatch({ type: "getStringLog", payload: "" });
                        },
                      }
                    );
                  },
                }
              );
            },
            onError: () => {
              setPrecisionChatHistory(precisionChatHistory);
              setSkeletonChecker(false)
              message.error("Connection with the server failed!");
            },
          }
        );
      }
    }
  }, [stringLog]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        // Shift + Enter pressed, add a new line
        e.preventDefault();
        setTextMessage((prev) => prev + "\n");
      } else {
        // Enter alone pressed, send the message
        e.preventDefault();
        handleSendMessage();
      }
    }
  };

  return (
    <div className="h-full">
      {mode === "general" && (
        <div className="px-2 md:px-24 py-1 h-full flex flex-col">
          <div className="chat flex-1 overflow-y-auto flex flex-col" id="generalChatContainer">
            {isGeneralSessionChatData && !skeletonChecker ? (
              <div
                style={{
                  overflowY: "hidden",
                }}
              >
                <Skeleton
                  active
                  avatar
                  style={{
                    direction: "rtl",
                    marginBottom: "48px",
                    paddingLeft: "24px",
                  }}
                  paragraph={{
                    rows: 2,
                  }}
                />
                <Skeleton
                  active
                  avatar
                  style={{
                    marginBottom: "48px",
                    paddingLeft: "24px",
                  }}
                  paragraph={{
                    rows: 2,
                  }}
                />
                <Skeleton
                  active
                  avatar
                  style={{
                    direction: "rtl",
                    marginBottom: "48px",
                    paddingLeft: "24px",
                  }}
                  paragraph={{
                    rows: 4,
                  }}
                />
              </div>
            ) : (
              <>
              {chatHistory?.map((chat, index) => (
                <Card
                  key={index}
                  className={chat.type === "ai" ? "chat-card" : "chat-user"}
                  style={{
                    marginBottom: "10px",
                    borderRadius: "10px",
                    backgroundColor: chat.type === "user" ? "#FAFAFA" : "#fff",
                    border: chat.type === "user" ? "1px solid #D4D4D4" : "none",
                    minWidth: "70%",
                    alignSelf: chat.type === "user" ? "flex-end" : "flex-start",
                  }}
                >
                  <div className="flex gap-4">
                    {chat.type === "ai" && (
                      <Avatar
                        style={{
                          height: "100%",
                          width: "100%",
                          maxWidth: "36px",
                        }}
                        className="agteq-chat-logo border border-neutral-300 border-solid rounded-lg p-2"
                        shape="square"
                        size="large"
                        src={logo}
                      />
                    )}
                    <div className="group">
                      {chat.message === "" && chat.type === "ai" ? (
                        <div
                          id={chat.elementId ? chat.elementId : null}
                          style={{
                            wordBreak: "break-word",
                          }}
                          className={`markdown-content mt-2`}
                        >
                          <SyncLoader
                            color={"#16a34a"}
                            size={8}
                            speedMultiplier={0.5}
                          />
                        </div>
                      ) : (
                        <>
                          <div
                            id={chat.elementId ? chat.elementId : null}
                            style={{
                              wordBreak: "break-word",
                            }}
                            className={`markdown-content`}
                            dangerouslySetInnerHTML={{
                              __html: marked?.parse(chat?.message),
                            }}
                          />
                        </>
                      )}
                      {chat.type === "ai" && (
                        <div className="mt-4">
                          <Space className="opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            <Tooltip title="Copy">
                              <Button
                                className="rounded-lg p-2"
                                icon={<Copy />}
                                onClick={() => handleCopy(chat.message)}
                                size="middle"
                              />
                            </Tooltip>
                            {/* <Tooltip title="Like">
                        <Button icon={<LikeOutlined />} size="small" />
                      </Tooltip>
                      <Tooltip title="Dislike">
                        <Button icon={<DislikeOutlined />} size="small" />
                      </Tooltip> */}
                          </Space>
                        </div>
                      )}
                    </div>
                    
                  </div>
                  
                </Card>
              ))}
              <div ref={messagesEndRef} />
              </>
            )}
            
          </div>

          <div className="chat-input mt-auto">
            <div className="relative">
              {/* Prefix */}
              <div className="absolute top-1/2 left-6 transform -translate-y-1/2 z-50">
                <BrightStar style={{ width: 20, color: "#262626" }} />
              </div>
              <TextArea
                className="px-5 py-3.5 h-28 chat-input"
                placeholder="Ask Bloom your queries..."
                value={textMessage}
                id="generalTxtBox"
                disabled={isHostSendGeneralChatData}
                onChange={(e) => setTextMessage(e.target.value)}
                onKeyDown={handleKeyDown}
                size="large"
                autoSize={{ minRows: textMessage ? 1 : 0, maxRows: 4 }}
                style={{
                  resize: "none", // Prevents resizing of the TextArea
                  borderRadius: "30px", // Keeps the full rounded corners
                  paddingLeft: "50px", // Adjust padding for prefix
                  paddingTop: "13px",
                  paddingBottom: "13px",
                  paddingRight: "40px", // Adjust padding for suffix
                }}
              />

              {/* Suffix */}
              <div className="absolute top-1/2 right-4 transform -translate-y-1/2 z-50">
                <Button
                  disabled={isHostSendGeneralChatData}
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    border: "none",
                  }}
                >
                  <SendOutlined
                    disabled={isHostSendGeneralChatData}
                    width={40}
                    onClick={handleSendMessage}
                    style={{
                      cursor: "pointer",
                      color: "#262626",
                      marginLeft: "5px",
                    }}
                  />
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {mode === "precision" && (
        <div className="px-2 md:px-8 py-1 h-full flex flex-col">
          <div className="chat flex-1 overflow-y-auto flex flex-col" id="precisionChatContainer">
            {isPrecisionSessionChatData && !skeletonChecker ? (
              <div
                style={{
                  overflowY: "hidden",
                }}
              >
                <Skeleton
                  active
                  avatar
                  style={{
                    direction: "rtl",
                    marginBottom: "48px",
                    paddingLeft: "24px",
                  }}
                  paragraph={{
                    rows: 2,
                  }}
                />
                <Skeleton
                  active
                  avatar
                  style={{
                    marginBottom: "48px",
                    paddingLeft: "24px",
                  }}
                  paragraph={{
                    rows: 2,
                  }}
                />
                <Skeleton
                  active
                  avatar
                  style={{
                    direction: "rtl",
                    marginBottom: "48px",
                    paddingLeft: "24px",
                  }}
                  paragraph={{
                    rows: 4,
                  }}
                />
              </div>
            ) : (
              <>
             { precisionChatHistory?.map((chat, index) => {
                return (
                  <Card
                    key={index}
                    className={chat.type === "ai" ? "chat-card" : "chat-user"}
                    style={{
                      marginBottom: "10px",
                      borderRadius: "10px",
                      backgroundColor:
                        chat.type === "user" ? "#FAFAFA" : "#fff",
                      border:
                        chat.type === "user" ? "1px solid #D4D4D4" : "none",
                      minWidth: "70%",
                      alignSelf:
                        chat.type === "user" ? "flex-end" : "flex-start",
                    }}
                  >
                    <div className="flex gap-4">
                      {chat.type === "ai" && (
                        <Avatar
                          style={{
                            height: "100%",
                            width: "100%",
                            maxWidth: "36px",
                          }}
                          className="agteq-chat-logo border border-neutral-300 border-solid rounded-lg p-2"
                          shape="square"
                          size="large"
                          src={logo}
                        />
                      )}
                      <div className="group">
                        {chat.message === "" && chat.type === "ai" ? (
                          <div
                            id={chat.elementId ? chat.elementId : null}
                            style={{
                              wordBreak: "break-word",
                            }}
                            className={`markdown-content mt-2`}
                          >
                            <SyncLoader
                              color={"#16a34a"}
                              size={8}
                              speedMultiplier={0.5}
                            />
                          </div>
                        ) : (
                          <>
                            <div
                              id={chat.elementId ? chat.elementId : null}
                              className={`markdown-content`}
                              style={{
                                wordBreak: "break-word",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: marked?.parse(
                                  `${chat.field_updated && chat.type === "ai"
                                    ? "<b>Assistant update : </b>"
                                    : chat.field_updated && chat.type === "user"
                                      ? "<b>Field update : </b>"
                                      : ""
                                  } ${chat.message}`
                                ),
                              }}
                            />
                            
                          </>
                        )}
                        {chat.type === "ai" && (
                          <div className="mt-4">
                            <Space className="opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                              <Tooltip title="Copy">
                                <Button
                                  className="rounded-lg p-2"
                                  icon={<Copy />}
                                  onClick={() => handleCopy(chat.message)}
                                  size="middle"
                                />
                              </Tooltip>
                            </Space>
                          </div>
                        )}
                      </div>
                      
                    </div>
                    
                  </Card>
                );
              })}
              <div ref={messagesPrecisionEndRef} />
              </>
            )}
            
          </div>

          <div className="chat-input mt-auto">
            <div className="relative">
              {/* Prefix */}
              <div className="absolute top-1/2 left-6 transform -translate-y-1/2 z-50">
                <BrightStar style={{ width: 20, color: "#262626" }} />
              </div>
              <TextArea
                className="px-5 py-3.5 h-28 chat-input"
                placeholder="Ask Bloom your queries..."
                value={textMessage}
                disabled={isHostSendPrecisionChatData}
                id="precisionTxtBox"
                onChange={(e) => setTextMessage(e.target.value)}
                onKeyDown={handleKeyDown}
                size="large"
                autoSize={{ minRows: textMessage ? 1 : 0, maxRows: 4 }}
                style={{
                  resize: "none", // Prevents resizing of the TextArea
                  borderRadius: "30px", // Keeps the full rounded corners
                  paddingLeft: "50px", // Adjust padding for prefix
                  paddingTop: "13px",
                  paddingBottom: "13px",
                  paddingRight: "40px", // Adjust padding for suffix
                }}
              />

              {/* Suffix */}
              <div className="absolute top-1/2 right-4 transform -translate-y-1/2 z-50">
                <Button
                  disabled={isHostSendPrecisionChatData}
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    border: "none",
                  }}
                >
                  <SendOutlined
                    disabled={isHostSendPrecisionChatData}
                    width={40}
                    onClick={handleSendMessage}
                    style={{
                      cursor: "pointer",
                      color: "#262626",
                      marginLeft: "5px",
                    }}
                  />
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Chat;
