import { useMutation } from "react-query";
import { forgotPassword } from "../../Services/apiAuth";

export function useForgotPassword() {
  const {
    mutate: forgotPassData,
    isLoading: isForgotPassDataData,
    error: errorForgotPassDataData,
  } = useMutation({
    mutationFn: forgotPassword,
  });

  return {
    forgotPassData,
    isForgotPassDataData,
    errorForgotPassDataData,
  };
}
