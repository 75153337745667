import { useQuery } from "react-query";
import { getWeeds } from "../../Services/apiBloom";

export function useWeeds() {
  const {
    data: weedsData,
    isLoading: isWeeds,
    error: errorWeedsData,
  } = useQuery({
    queryKey: ["weeds"],
    queryFn: getWeeds,
  });

  return {
      weedsData,
      isWeeds,
      errorWeedsData,
  };
}
