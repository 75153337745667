import React from "react";

import logo2 from "../assets/logo2.png";
import ParticleBackground from "./Particles";

const AuthGrid = ({ children }) => {
  return (
    <>
      <div className="flex p-0">
        <div className="flex  flex-col items-center z-10 bg-neutral-50 flex-1 w-100 px-3 md:px-3 lg:px-0 py-8 place-content-center">
          {children}
        </div>
        <div className="hidden-mobile flex relative items-center bg-white m-0 flex-1">
          <div className="items-center flex flex-1 h-screen md:px-3">
            <ParticleBackground />
            <div className="block relative w-full px-3 md:px-3 lg:px-0">
              <img
                className="block w-52 h-52 mx-auto mb-8 rounded-e-full z-10"
                alt={"Logo"}
                src={logo2}
              />
              <h2 className="block relative text-center mx-auto font-bold text-4xl -mt-2.5 mb-4 z-10">
                Grow Smart Live <span style={{ color: "#16a34a" }}>Green</span>
              </h2>
              <p className="relative text-center font-normal text-base -mt-2.5 z-10 text-neutral-500">
                Advancing agricultural decision-making with AI
              </p>
              <ul className="flex relative text-center mt-10 justify-center	gap-6	z-10">
                <li className="services-list flex list-none font-normal items-end text-neutral-700">
                  AI Infrastructure
                </li>
                <li className="services-list flex list-none font-normal	items-end text-neutral-700">
                  Sustainability
                </li>
                <li className="services-list flex list-none font-normal	items-end text-neutral-700">
                  Integrated
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AuthGrid;
