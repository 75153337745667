import { useMutation } from "react-query";
import { createFarmField } from "../../Services/apiBloom";

export function useCreateField() {
  const {
    mutate: createFieldData,
    isLoading: isCreateFieldData,
    error: errorCreateFieldData,
  } = useMutation({
    mutationFn: createFarmField,
  });

  return {
    createFieldData,
    isCreateFieldData,
    errorCreateFieldData,
  };
}
