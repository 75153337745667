import React, { useEffect, useState } from "react";
import {
  BubbleStar,
  ChatBubbleEmpty,
  ChatLines,
  MoreHoriz,
  Plus,
  Farm,
  EditPencil,
  Trash,
} from "iconoir-react";
import {
  Layout,
  Menu,
  theme,
  Segmented,
  Button,
  Avatar,
  Dropdown,
  message,
  Input,
  Modal,
  Space,
} from "antd";
import BloomLogo from "../assets/bloom-logo.png";
import { useChat } from "../Context/ChatProvider";
import { useCreateFarm } from "../Features/Chat/useCreateFarm";
import { useNavigate, useParams } from "react-router-dom";
import CounterCyrcle from "./CounterCyrcle";
import { useFarmFields } from "../Features/Chat/useFarmFields";
import { useFarmField } from "../Features/Chat/useFarmField";
import { v4 } from "uuid";
import { useDeleteGeneralSession } from "../Features/Chat/useDeleteGeneralSession";
import { useEditGeneralSessionTitle } from "../Features/Chat/useEditGeneralSessionTitle";
import { useDeletePrecisionSession } from "../Features/Chat/useDeletePrecisionSession";
import { useEditPrecisionSessionTitle } from "../Features/Chat/useEditPrecisionSessionTitle";
import { CloseOutlined } from "@ant-design/icons";
const { confirm } = Modal;
const { Sider } = Layout;

function LeftSider() {
  const {
    chatMode,
    dispatch,
    farms,
    currentFarm,
    currentField,
    handleFieldInputChange,
    year,
    season,
    handleFarmInputChange,
    generalSessions,
    farmFields,
  } = useChat();
  const navigate = useNavigate();
  const { hash } = useParams();

  // const { countyData, isCountyData, errorCountyData } = useCounties();

  const { createFarmData, isCreateFarmData, errorCreateFarmData } =
    useCreateFarm();

  const { farmFieldsData, isFarmFieldsData, errorFarmFieldsData } =
    useFarmFields();

  const {
    deleteGeneralSessionData,
    isDeleteGeneralSessionData,
    errorDeleteGeneralSessionData,
  } = useDeleteGeneralSession();

  const {
    editGeneralSessionTitleData,
    isEditGeneralSessionTitleData,
    errorEditGeneralSessionTitleData,
  } = useEditGeneralSessionTitle();

  const {
    deletePrecisionSessionData,
    isDeletePrecisionSessionData,
    errorDeletePrecisionSessionData,
  } = useDeletePrecisionSession();

  const {
    editPrecisionSessionTitleData,
    isEditPrecisionSessionTitleData,
    errorEditPrecisionSessionTitleData,
  } = useEditPrecisionSessionTitle();

  const chatModeSelectorSegment = (value) => {
    if (value === "Precision Chat") {
      navigate("/c/precision");
      dispatch({ type: "selectChat", payload: "precision" });
    } else {
      navigate("/c/general");
      dispatch({ type: "selectChat", payload: "general" });
    }
  };

  const [farmsItems, setFarmsItems] = useState([]);

  useEffect(() => {
    setFarmsItems(farms);
  }, [farms]);

  const menuItemsData = farmsItems?.map((item, i) => ({
    key: i,
    label: item.farm_name,
    id: item.id,
  }));

  const { farmFieldData, isFarmFieldData, errorFarmFieldData } = useFarmField();

  const [selectedKeys, setSelectedKeys] = useState();

  useEffect(() => {
    if (chatMode === "general") {
      setSelectedKeys(hash);
    } else {
      setSelectedPKeys(hash);
    }
  }, [hash]);

  //adding farm

  function addFarmHandler() {
    createFarmData(
      {
        data: {
          farm_name: "My Farm",
          country: null,
          province_state: null,
          municipal_district: null,
          farm_area_in_acre: 0,
          farm_address: "",
          postal_address: "",
          lld: "",
          quarter_section: "",
          section: "",
          township: "",
          farm_range: "",
          meridian: "",
          season: season,
          year: year,
        },
      },
      {
        onSuccess: (data) => {
          dispatch({ type: "getFarms", payload: [...farms, data] });
          dispatch({ type: "selectFarm", payload: data.id });

          handleFarmInputChange("farm_name", data.farm_name);
          handleFarmInputChange("country", 0);
          handleFarmInputChange("countryText", "");
          handleFarmInputChange("province_state", 0);
          handleFarmInputChange("province_stateText", "");
          handleFarmInputChange("city", 0);
          handleFarmInputChange("county", 0);
          handleFarmInputChange("planted_area", 0);
          handleFarmInputChange("postal_address", "");
          handleFarmInputChange("lld", "");
          handleFarmInputChange("quarter_section", "");
          handleFarmInputChange("section", "");
          handleFarmInputChange("range", "");
          handleFarmInputChange("meridian", "");
          handleFarmInputChange("township", "");

          farmFieldsData(
            { farm_id: data.id, year: year, season: season },
            {
              onSuccess: (data) => {
                dispatch({ type: "getFarmFields", payload: data.fields });
                dispatch({
                  type: "selectField",
                  payload: data.fields[0] ? data.fields[0].id : null,
                });
                farmFieldData(
                  {
                    farm_field_id: data.fields[0].id,
                    year: year,
                    season: season,
                  },
                  {
                    onSuccess: (data) => {
                      handleFieldInputChange("field_name", "My Field");
                      handleFieldInputChange("soil_texture", 0);
                      handleFieldInputChange("irregation_type", 0);
                      handleFieldInputChange("planted_area", 0);
                      handleFieldInputChange("approximate_crop_height", 0);
                      handleFieldInputChange("crop_name", 0);
                      handleFieldInputChange("crop_stage", 0);
                      handleFieldInputChange("farm", data.data.farm);
                      handleFieldInputChange("weeds", []);
                      handleFieldInputChange(
                        "id",
                        data.data.farm_field_crops.id
                      );
                      message.success("Your Farm has been created");

                      localStorage.removeItem("precisionHash");
                      navigate("/c/precision");
                    },
                  }
                );
              },
            }
          );
        },
        onError: () =>
          message.error(
            "Something went wrong while adding the farm, Please try again!"
          ),
      }
    );
  }

  const farmMenuItems = (
    <>
      <Menu className="max-h-56 overflow-y-auto" style={{ padding: 8 }}>
        <Button
          type="primary"
          icon={<Plus />}
          block
          className="new-chat-btn mb-2"
          onClick={addFarmHandler}
          disabled={isCreateFarmData || isFarmFieldData}
        >
          New Farm
        </Button>
        {menuItemsData
          .slice()
          .reverse()
          ?.map((item) => (
            <Menu.Item
              key={v4()}
              style={{ marginBottom: 8 }}
              className={`flex items-center gap-4  ${
                item.id === currentFarm ? "bg-neutral-100" : null
              }`}
              onClick={() => {
                dispatch({ type: "selectFarm", payload: item.id });
                const farm = farms.filter((farm) => item.id === farm.id)[0];

                let hasCounty = !!farm.county

                handleFarmInputChange("country", hasCounty ? farm.county.province.country.id : 0);
                handleFarmInputChange("countryText", hasCounty ?farm.county.province.country.country_name : "");
                // handleFarmInputChange("county", hasCounty ? farm.municipal_district.id: 0);
                handleFarmInputChange("county", 0);
                handleFarmInputChange("province_state", hasCounty ? farm.county.province.id : 0);
                handleFarmInputChange("province_stateText", hasCounty ? farm.county.province.name : "");
                handleFarmInputChange("city", farm.farm_city);
                handleFarmInputChange("farm_name", farm.farm_name);
                handleFarmInputChange("planted_area", farm.farm_area_in_acre);
                handleFarmInputChange("range", farm.farm_range);
                handleFarmInputChange("lld", farm.lld);
                handleFarmInputChange("meridian", farm.meridian);
                handleFarmInputChange("postal_address", farm.postal_address);
                handleFarmInputChange("quarter_section", farm.quarter_section);
                handleFarmInputChange("section", farm.section);
                handleFarmInputChange("township", farm.township);
                handleFarmInputChange("postal_address", farm.postal_address);

                farmFieldsData(
                  { farm_id: item.id, year: year, season: season },
                  {
                    onSuccess: (data) => {
                      dispatch({ type: "getFarmFields", payload: data.fields });
                      dispatch({
                        type: "selectField",
                        payload: data.fields[0] ? data.fields[0].id : null,
                      });
                      farmFieldData(
                        {
                          farm_field_id: data.fields[0].id,
                          year: year,
                          season: season,
                        },
                        {
                          onSuccess: (data) => {
                            handleFieldInputChange("farm", data.data.farm);
                            handleFieldInputChange(
                              "field_name",
                              data.data.field_name ? data.data.field_name : 0
                            );
                            handleFieldInputChange(
                              "soil_texture",
                              data.data.soil_type ? data.data.soil_type : 0
                            );
                            handleFieldInputChange(
                              "irregation_type",
                              data.data.irrigation_type
                                ? data.data.irrigation_type
                                : 0
                            );
                            handleFieldInputChange(
                              "planted_area",
                              data.data.area_in_acre
                            );
                            handleFieldInputChange(
                              "approximate_crop_height",
                              data.data.approximate_crop_height
                            );
                            handleFieldInputChange(
                              "crop_name",
                              data.data.farm_field_crops?.crop
                                ? data.data.farm_field_crops.crop
                                : 0
                            );
                            handleFieldInputChange(
                              "crop_stage",
                              data.data.farm_field_crops?.crop_stage
                                ? data.data.farm_field_crops.crop_stage
                                : 0
                            );
                            handleFieldInputChange(
                              "weeds",
                              data.data.farm_field_crops?.farm_field_weeds
                                ? data.data.farm_field_crops.farm_field_weeds.map(
                                    (weed) => ({
                                      weed_name: weed.weed,
                                      weed_density: weed.weed_density,
                                      leaves_number:
                                        weed.weed_stage.number_of_leaves,
                                      height: weed.weed_stage.heihgt_in_cm,
                                      flowering: weed.weed_stage.flowering,
                                    })
                                  )
                                : []
                            );
                            handleFieldInputChange(
                              "id",
                              data.data.farm_field_crops?.id
                                ? data.data.farm_field_crops.id
                                : 0
                            );
                            localStorage.removeItem("precisionHash");
                            navigate("/c/precision");
                          },
                        }
                      );
                    },
                    onError: (error) =>
                      message.error(
                        "Connection with the server faild, please try again!"
                      ),
                  }
                );
              }}
            >
              <div className="flex w-9/12 justify-items-center content-center items-center ">
                <div className="justify-self-center	self-center	">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
                      fill="#F5F5F5"
                    />
                    <path
                      d="M7.33203 17.332H5.33203V9.33203L9.33203 7.33203L13.332 9.33203V17.332H11.332M7.33203 17.332V12.6654H11.332V17.332M7.33203 17.332H11.332"
                      stroke="#16A34A"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16 13.332V15.332V17.332"
                      stroke="#16A34A"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13.3346 15.332H16.0013H18.668"
                      stroke="#16A34A"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13.3346 13.332H16.0013H18.668"
                      stroke="#16A34A"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="ml-2 text-neutral-800 font-normal	">
                  {item.label}
                </div>
              </div>
            </Menu.Item>
          ))}
      </Menu>
    </>
  );

  const [selectedRenaming, setSelectedRenaming] = useState("");
  const [newSessionTitle, setNewSessionTitle] = useState("");

  const deleteGeneralSessionHandler = (slug) => {
    deleteGeneralSessionData(
      { slug: slug },
      {
        onSuccess: (data) => {
          dispatch({ type: "tempGeneralSessionsDelete", payload: slug });
          if (localStorage.getItem("generalHash")) {
            if (localStorage.getItem("generalHash") === slug) {
              localStorage.removeItem("quickHash");
              navigate("/c/general");
            }
          }
          message.success("Chat deleted");
        },
        onError: () => {
          message.error(
            "Something went wrong while deleting the chat, Please try again!"
          );
        },
      }
    );
  };

  const editGeneralSessionHandler = (e, slug, session_title) => {
    e.stopPropagation();
    if (session_title === "") {
      return false;
    } else {
      const newTitle =
        session_title?.length > 19
          ? session_title.substring(0, 19) + "..."
          : session_title;

      editGeneralSessionTitleData(
        { slug: slug, session_title: newTitle },
        {
          onSuccess: (data) => {
            dispatch({
              type: "tempGeneralSessionsEditTitle",
              payload: { slug: slug, newTitle: newTitle },
            });
            setSelectedRenaming("");
            setNewSessionTitle("");
          },
          onError: () =>
            message.error(
              "Something went wrong while renaming the chat, Please try again!"
            ),
        }
      );
    }
  };

  const deletePrecisionSessionHandler = (slug) => {
    deletePrecisionSessionData(
      { slug: slug },
      {
        onSuccess: (data) => {

          farmFieldsData(
            {
              farm_id: currentFarm,
              season: season,
              year: year,
            },
            {
              onSuccess: (data) => {
                dispatch({ type: "getFarmFields", payload: data.fields });
              },
            }
          );

          if (localStorage.getItem("precisionHash")) {
            if (localStorage.getItem("precisionHash") === slug) {
              localStorage.removeItem("precisionHash");
              navigate("/c/precision");
            }
          }
          message.success("Chat deleted");
        },
        onError: () =>
          message.error(
            "Something went wrong while deleting the chat, Please try again!"
          ),
      }
    );
  };

  const editPrecisionSessionHandler = (slug, session_title) => {
    if (session_title === "") {
      return false;
    } else {
      const newTitle =
        session_title?.length > 19
          ? session_title.substring(0, 19) + "..."
          : session_title;

      editPrecisionSessionTitleData(
        { slug: slug, session_title: newTitle },
        {
          onSuccess: (data) => {
            farmFieldsData(
              {
                farm_id: currentFarm,
                season: season,
                year: year,
              },
              {
                onSuccess: (data) => {
                  dispatch({ type: "getFarmFields", payload: data.fields });
                },
              }
            );
            setSelectedRenaming("");
            setNewSessionTitle("");
          },
          onError: () =>
            message.error(
              "Something went wrong while renaming the chat, Please try again!"
            ),
        }
      );
    }
  };

  const [select, setSelect] = useState(0);

  useEffect(() => {
    setSelect(currentField);
  }, [currentField]);

  document.body.onclick = () => {
    setSelectedRenaming("");
    setNewSessionTitle("");
  };

  function ModalLabel({ text }) {
    return <p>This will delete {<span className="font-bold">{text}</span>}</p>;
  }

  const handleOkDeleteModal = (slug) => {
    deletePrecisionSessionHandler(slug);

    setIsModalVisible(false);
  };

  const showDeleteConfirm = (slug, title, deleteHandler) => {
    confirm({
      width: 500, // Adjust width here
      styles: { top: 300 },
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span className="text-xl">Delete Chat</span>
          <Button
            icon={<CloseOutlined />}
            className="bg-zinc-50"
            type="text"
            onClick={() => Modal.destroyAll()} // This closes the modal
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#888",
            }}
          />
        </div>
      ),
      content: (
        <p className="text-sm text-neutral-500 mb-4 pb-4 border-neutral-300">
          This will delete <span className="font-bold">{title}</span>
        </p>
      ),
      icon: null, // Remove the icon
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      className: "custom-confirm", // Custom class for styling
      style: { top: 250 }, // Custom vertical position
      onOk() {
        deleteHandler(slug);
      },
      afterClose: () =>
        document.removeEventListener("mousedown", handleClickOutside), // Clean up event listener

      okButtonProps: {
        danger: true, // To make the button red
        className: "delete-modal-btn", // Add a custom class
        style: {
          backgroundColor: "#c53c3e", // Custom background color
          borderColor: "#dd4144", // Custom border color
          color: "#fff", // Text color
          fontWeight: "bold", // Custom font weight
          width: "100px", // Set width
          height: "40px", // Set width
        },
      },
      cancelButtonProps: {
        className: "cancel-modal-btn", // Add a custom class
        style: {
          borderColor: "#d9d9d9", // Custom border color for cancel
          color: "#737373", // Text color
          fontWeight: "bold", // Custom font weight
          width: "100px", // Set width for cancel
          height: "40px", // Set width for cancel
        },
      },
    });

    const handleClickOutside = (event) => {
      const modalContainer = document.querySelector(".ant-modal-confirm");
      if (modalContainer && !modalContainer.contains(event.target)) {
        Modal.destroyAll(); // Close the modal if click is outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
  };

  const leftGeneralMenuItems = !generalSessions
    ? []
    : generalSessions.map((session) => ({
      key: session.slug,
      icon: <ChatLines />,
      label: (
        <div
          key={session.slug}
          onClick={() => {
            navigate(`/c/general/${session.slug}`, { replace: true });
            localStorage.setItem("generalHash", session.slug);
          }}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span key={session.slug}>
            {selectedRenaming !== session.slug ? (
              session.session_title
            ) : (
              <Input
                key={session.slug}
                id="generalRenameInput"
                onClick={(e) => e.stopPropagation()}
                onBlur={() => setSelectedRenaming("")}
                value={newSessionTitle}
                defaultValue={session.session_title}
                onChange={(e) => setNewSessionTitle(e.target.value)}
                className="bg-transparent"
                onPressEnter={(e) =>
                  editGeneralSessionHandler(
                    e,
                    session.slug,
                    newSessionTitle
                  )
                }
              />
            )}
          </span>
          <Dropdown
            menu={{
              items: [
                {
                  label: (
                    <Space className="p-1">
                      <EditPencil
                        style={{
                          height: "20",
                          width: "20",
                          color: "#737373",
                        }}
                      />
                      <p
                        className="text-sm"
                        onClick={(e) => {
                          e.stopPropagation();
                          setNewSessionTitle(session.session_title);
                          setSelectedRenaming(session.slug);
                          setTimeout(() => {
                            document
                              .getElementById("generalRenameInput")
                              ?.focus();
                          }, 40);
                        }}
                      >
                        Rename
                      </p>
                    </Space>
                  ),
                  key: "0",
                },
                {
                  label: (
                    <Space wrap className="p-1">
                      <Trash
                        style={{
                          height: "20",
                          width: "20",
                          color: "#ef4444",
                        }}
                      />
                      <p
                        className="text-red-500 text-sm"
                        onClick={(e) => {
                          e.stopPropagation();
                          showDeleteConfirm(
                            session.slug,
                            session.session_title,
                            deleteGeneralSessionHandler
                          );
                        }}
                      >
                        Delete
                      </p>
                    </Space>
                  ),
                  key: "1",
                },
              ],
            }}
            trigger={["click"]}
          >
            <MoreHoriz
              style={{ marginLeft: "auto", color: "#737373" }}
              onClick={(e) => e.stopPropagation()}
            />
          </Dropdown>
        </div>
      ),
    }))
  const fields = farmFields?.map((field) => ({
    id: field.id,
    feildName: field.field_name,
    questionHistory:
      field.sessions.length === 0
        ? [
            {
              question: "Start a chat...",
              key: `${field.id} new chat`,
            },
          ]
        : field.sessions.map((session) => ({
            question: session.session_title,
            key: session.slug,
          })),
  }));

  const [selectedPKeys, setSelectedPKeys] = useState([]);

  function ModalBtn({ text, icon, handleClick }) {
    return (
      <button onClick={!handleClick ? null : handleClick}>
        <div className={`flex gap-2`}>
          {icon}
          <p>{text}</p>
        </div>
      </button>
    );
  }

  const [isModalVisible, setIsModalVisible] = useState(false);

  const leftPrecisionMenuItems = fields?.map((field, i) => {
    return {
      key: `sub${field.feildName} ${i}`,
      icon: (
        <CounterCyrcle isActive={select === field.id ? true : false}>
          {i + 1}
        </CounterCyrcle>
      ),
      label: field.feildName,
      children: field.questionHistory.map((question, j) => {
        return {
          key: question.key,
          icon: <ChatLines />,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              onClick={() => {
                setSelect(field.id);
                dispatch({ type: "selectSideBarMode", payload: "Field" });

                if (question.question === "Start a chat...") {
                  setSelectedPKeys([question.key]);
                } else {
                  navigate(`/c/precision/${question.key}`, { replace: true });
                  localStorage.setItem("precisionHash", question.key);
                }

                dispatch({ type: "selectField", payload: field.id });
                farmFieldData(
                  { farm_field_id: field.id, year: year, season: season },
                  {
                    onSuccess: (data) => {
                      handleFieldInputChange(
                        "field_name",
                        data.data.field_name ? data.data.field_name : ""
                      );
                      handleFieldInputChange(
                        "soil_texture",
                        data.data.soil_type ? data.data.soil_type : 0
                      );
                      handleFieldInputChange(
                        "irregation_type",
                        data.data.irrigation_type
                          ? data.data.irrigation_type
                          : 0
                      );
                      handleFieldInputChange(
                        "planted_area",
                        data.data.area_in_acre
                      );
                      handleFieldInputChange(
                        "approximate_crop_height",
                        data.data.approximate_crop_height
                      );
                      handleFieldInputChange(
                        "crop_name",
                        data.data?.farm_field_crops?.crop
                          ? data.data.farm_field_crops?.crop
                          : 0
                      );
                      handleFieldInputChange(
                        "crop_stage",
                        data.data?.farm_field_crops?.crop_stage
                          ? data.data?.farm_field_crops?.crop_stage
                          : 0
                      );
                      handleFieldInputChange(
                        "weeds",
                        data.data?.farm_field_crops?.farm_field_weeds
                          ? data.data.farm_field_crops.farm_field_weeds.map(
                              (weed) => ({
                                weed_name: weed.weed,
                                weed_density: weed.weed_density,
                                leaves_number: weed.weed_stage.number_of_leaves,
                                height: weed.weed_stage.heihgt_in_cm,
                                flowering: weed.weed_stage.flowering,
                              })
                            )
                          : []
                      );
                      handleFieldInputChange(
                        "id",
                        data.data?.farm_field_crops?.id
                          ? data.data.farm_field_crops.id
                          : 0
                      );
                    },
                  }
                );
              }}
            >
              <span key={question.key}>
                {selectedRenaming !== question.key ? (
                  question.question
                ) : (
                  <Input
                    key={question.key}
                    id="generalRenameInput"
                    onClick={(e) => e.stopPropagation()}
                    onBlur={() => setSelectedRenaming("")}
                    value={newSessionTitle}
                    onChange={(e) => setNewSessionTitle(e.target.value)}
                    className="bg-transparent"
                    onPressEnter={() =>
                      editPrecisionSessionHandler(question.key, newSessionTitle)
                    }
                    defaultValue={() => setNewSessionTitle(question.question)}
                  />
                )}
              </span>
              {question.question === "Start a chat..." ? null : (
                <Dropdown
                  onClick={(e) => e.stopPropagation()}
                  menu={{
                    items: [
                      {
                        label: (
                          <Space className="p-1">
                            <EditPencil
                              style={{
                                height: "20",
                                width: "20",
                                color: "#737373",
                              }}
                            />
                            <p
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedRenaming(question.key);
                                setNewSessionTitle(question.question);
                                setTimeout(() => {
                                  document
                                    .getElementById("generalRenameInput")
                                    ?.focus();
                                }, 40);
                              }}
                            >
                              Rename
                            </p>
                          </Space>
                        ),
                        key: "0",
                      },
                      {
                        label: (
                          <>
                            <div className="flex gap-2 pl-1">
                              <Trash
                                style={{
                                  height: "20",
                                  width: "20",
                                  color: "#ef4444",
                                }}
                              />
                              <p
                                className="text-red-500"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setIsModalVisible(true);
                                  showDeleteConfirm(
                                    question.key,
                                    question.question,
                                    handleOkDeleteModal
                                  );
                                }}
                              >
                                Delete
                              </p>
                            </div>
                            {/* <DeleteModalComponent
                              slug={question.key}
                              title={question.question}
                              open={isModalVisible}
                              onConfirm={handleOkDeleteModal}
                              onCancel={handleCancelDeleteModal}
                            /> */}
                          </>
                        ),
                        key: "1",
                      },
                    ],
                  }}
                  trigger={["click"]}
                >
                  <MoreHoriz
                    style={{ marginLeft: "auto", color: "#737373" }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </Dropdown>
              )}
            </div>
          ),
        };
      }),
    };
  });

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      theme="light"
      width="306px"
      style={{ background: colorBgContainer }}
      className={
        "border-e border-solid border-neutral-300 px-4 py-7 left-sidebar"
      }
    >
      <div className="logo-vertical mb-10">
        <img className="w-32" src={BloomLogo} alt="" />
      </div>
      <Segmented
        block
        size="small"
        className="chat-segmented mb-5"
        onChange={chatModeSelectorSegment}
        value={chatMode === "general" ? "General Chat" : "Precision Chat"}
        options={[
          {
            label: "General Chat",
            value: "General Chat",
            icon: <ChatBubbleEmpty height={16} width={16} />,
          },
          {
            label: "Precision Chat",
            value: "Precision Chat",
            icon: <BubbleStar height={16} width={16} />,
          },
        ]}
      />
      <Button
        type="primary"
        icon={<Plus />}
        block
        className="new-chat-btn mb-5"
        onClick={() => {
          if (chatMode === "general") {
            localStorage.removeItem("generalHash");
            navigate("/c/general");
          } else if (chatMode === "precision") {
            localStorage.removeItem("precisionHash");
            navigate("/c/precision");
          }
          setSelectedKeys([]);
          setSelectedPKeys([]);
        }}
      >
        New Chat
      </Button>
      <div style={{ flex: 1, overflowY: "auto" }}>
        <Menu
          theme="light"
          mode="inline"
          
          defaultSelectedKeys={["1"]}
          selectedKeys={chatMode === "general" ? selectedKeys : selectedPKeys}
          onSelect={({ key }) => {
            if (chatMode === "general") {
              setSelectedKeys([key]);
            } else {
              if (key.includes("new chat")) {
                localStorage.removeItem("precisionHash");
                navigate("/c/precision");
              }
              setTimeout(() => {
                setSelectedPKeys([key]);
              }, 50);
              setSelectedPKeys([key]);
            }
          }}
          items={
            chatMode === "precision"
              ? leftPrecisionMenuItems
              : leftGeneralMenuItems
          }
          style={{ borderInlineEnd: "0" }}
          className="left-sidebar-menu mb-5"
        />
      </div>
      <div style={{ marginTop: "auto" }}>
        <Dropdown overlay={farmMenuItems} placement="top" trigger={["click"]}>
          <Button
            style={{
              padding: "8px",
            }}
            className={`justify-start h-auto w-full rounded-lg ${
              chatMode !== "general" ? "farm-select-disabled" : null
            }`}
            disabled={chatMode === "general"}
          >
            <Avatar
              style={{ display: "flex", backgroundColor: "#f5f5f5" }}
              className="item-center justify-center"
              size={38}
              icon={<Farm height={24} width={24} color="#737373" />}
              shape="square"
            />
            <div
              style={{ flex: "1 0 0" }}
              className="flex flex-col justify-center items-start"
            >
              <p className="text-neutral-500 text-xs">Farms</p>
              <h3 className="text-neutral-800 text-sm font-semibold">
                {chatMode === "general"
                  ? "Farm name"
                  : farms.map((farm) =>
                      farm.id === currentFarm ? farm.farm_name : ""
                    )}
              </h3>
            </div>
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              color="#000000"
            >
              <path
                d="M17 8L12 3L7 8"
                stroke="#000000"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M17 16L12 21L7 16"
                stroke="#000000"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </Button>
        </Dropdown>
      </div>
    </Sider>
  );
}

export default LeftSider;
