import { useMutation } from "react-query";
import { getPrecisionSessionChat } from "../../Services/apiBloom";

export function usePrecisionSessionChat() {
  const {
    mutate: precisionSessionChatData,
    isLoading: isPrecisionSessionChatData,
    error: erroPrecisionSessionChatData,
  } = useMutation({
    mutationFn: getPrecisionSessionChat,
  });

  return {
    precisionSessionChatData,
    isPrecisionSessionChatData,
    erroPrecisionSessionChatData,
  };
}
