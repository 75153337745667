import axios from "axios";
import axiosInstance from "../Utilities/axiosInstance";
const baseUrl = process.env.REACT_APP_UI_BACK_END_URL;

//Email sending for otp validation in in register

export async function sendEmail(email) {
  const response = await axios.post(baseUrl + "/api/users/send_code/", email);
  return response.data;
}
export async function sendEmailWithGoogle({
  email,
  method,
  first_name,
  last_name,
}) {
  const response = await axios.post(baseUrl + "/api/users/send_code/", {
    email,
    method,
    first_name,
    last_name,
  });
  return response.data;
}

export async function otpValidation({ email, code }) {
  const response = await axios.post(baseUrl + "/api/users/verify_code/", {
    email: email,
    code: code,
  });

  return response.data;
}

export async function register({ first_name, last_name, password, access }) {
  const response = await axiosInstance.patch(
    "/api/users/edit/",
    {
      first_name,
      last_name,
      password,
    },
    { headers: { Authorization: `Bearer ${access}` } }
  );

  return response.data;
}

export async function editUser({
  first_name,
  last_name,
  email,
  address,
  user_type,
  access,
}) {
  const response = await axiosInstance.patch(
    "/api/users/edit/",
    {
      first_name,
      last_name,
      email,
      address,
      user_type,
    },
    { headers: { Authorization: `Bearer ${access}` } }
  );

  return response.data;
}

export async function registerMarketing({ user_type, referral, token }) {
  const access = token.access;

  const response = await axiosInstance.patch(
    "/api/users/edit/",
    { user_type, referral },
    { headers: { Authorization: `Bearer ${access}` } }
  );

  return response.data;
}

export async function userTypes() {
  const response = await axiosInstance.get("/api/user_types/");
  return response.data;
}

export async function authGoogle({ access }) {
  const response = await axios.get(
    "https://www.googleapis.com/oauth2/v3/userinfo",
    {
      headers: { Authorization: `Bearer ${access}` },
    }
  );

  return response.data;
}

export async function login({ email, password }) {
  const response = await axios.post(
    process.env.REACT_APP_UI_BACK_END_URL + "/api/token/",
    {
      email,
      password,
    }
  );

  return response.data;
}

export async function editPassword({
  password,
  new_password,
  confirm_password,
}) {
  const response = await axiosInstance.post("/api/users/new-password/", {
    password,
    new_password,
    confirm_password,
  });

  return response.data;
}

export async function forgotPassword(username) {
  const response = await axiosInstance.post("/api/users/forgot/", username);
  return response.data;
}

export async function NewPasswordRequest({ username, password, slug }) {
  const response = await axiosInstance.post(
    `/api/users/reset-password/${slug}/`,
    { username, password }
  );
  return response.data;
}
