import { useQuery } from "react-query";
import { getSoilTypes } from "../../Services/apiBloom";

export function useSoilTexture() {
  const {
    data: soilTextureData,
    isLoading: isSoilTextureData,
    error: errorSoilTextureData,
  } = useQuery({
    queryKey: ["soilTexture"],
    queryFn: getSoilTypes,
  });

  return { soilTextureData, isSoilTextureData, errorSoilTextureData };
}
