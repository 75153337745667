import React, { useEffect, useState } from "react";
import HeaderProfile from "./HeaderProfile";
import HeaderSeason from "./HeaderSeason";
import { useChat } from "../Context/ChatProvider";
import HeaderHelps from "./HeaderHelps";
import { Button } from "antd";
import { SidebarExpand, EditPencil } from "iconoir-react";
import BloomLogo from "../assets/bloom-logo.png";

export default function ChatHeader({ showDrawer, showEditDrawer }) {
  const { chatMode } = useChat();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 996);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 996) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);
  return (
    <>
      {chatMode ? (
        !isMobile ? (
          <HeaderProfile />
        ) : (
          <div className="flex items-center gap-4">
            <Button
              style={{ height: "40px", width: "40px", padding: "10px" }}
              onClick={showDrawer}
            >
              {<SidebarExpand color="#737373" height={18} width={18} />}
            </Button>
            <div className="logo-vertical">
              <img className="w-32" src={BloomLogo} alt="" />
            </div>
          </div>
        )
      ) : !isMobile ? (
        <HeaderProfile />
      ) : (
        <div className="logo-vertical">
          <img className="w-32" src={BloomLogo} alt="" />
        </div>
      )}
      {chatMode ? (
        !isMobile ? (
          chatMode === "precision" ? (
            <HeaderSeason />
          ) : null
        ) : null
      ) : !isMobile ? (
        // switch null with HeaderHelps
        // <HeaderHelps />
        null
      ) : null}
    </>
  );
}
