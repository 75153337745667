import { useMutation } from "react-query";
import { deletePrecisionSession } from "../../Services/apiBloom";

export function useDeletePrecisionSession() {
  const {
    mutate: deletePrecisionSessionData,
    isLoading: isDeletePrecisionSessionData,
    error: errorDeletePrecisionSessionData,
  } = useMutation({
    mutationFn: deletePrecisionSession,
  });

  return {
    deletePrecisionSessionData,
    isDeletePrecisionSessionData,
    errorDeletePrecisionSessionData,
  };
}