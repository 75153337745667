import axiosInstance from "../Utilities/axiosInstance";
import { marked } from "marked";

export const getCountries = async () => {
  const response = await axiosInstance.get("api/countries/");
  return response.data;
};

export const getProvince = async ({ country_id }) => {
  const response = await axiosInstance.get(`api/countries/${country_id}/`);
  return response.data;
};

export const getCities = async ({ country }) => {
  const response = await axiosInstance.post(
    "https://countriesnow.space/api/v0.1/countries/cities",
    { country }
  );
  return response.data;
};

export const getCounties = async ({ province_id }) => {
  const response = await axiosInstance.get(`api/provinces/${province_id}/`);
  return response.data;
};

export const getSoilTypes = async () => {
  const response = await axiosInstance.get("api/soil_types/");
  return response.data;
};

export const getIrregationTypes = async () => {
  const response = await axiosInstance.get("api/irrigation_types/");
  return response.data;
};

//weed/crop apis

export const getWeeds = async () => {
  const response = await axiosInstance.get("api/weeds/");
  return response.data;
};

export const getWeedDensities = async () => {
  const response = await axiosInstance.get("api/weed_densities/");
  return response.data;
};

export const getCrop = async ({ crop_id }) => {
  if (crop_id !== 0) {
    const response = await axiosInstance.get(`api/crops/${crop_id}/`);
    return response.data;
  }
};

export const getCropGroups = async () => {
  const response = await axiosInstance.get("api/crop_groups/");
  return response.data;
};

//farm/field apis

export const editFarm = async ({ farm_id, data }) => {
  const response = await axiosInstance.put(`api/farms/edit/${farm_id}/`, data);
  return response.data;
};

export const editField = async ({ farm_field_id, data }) => {
  const response = await axiosInstance.put(
    `api/farm_fields/edit/${farm_field_id}/`,
    data
  );
  return response;
};

export const getFarmField = async ({ farm_field_id, year, season }) => {
  const response = await axiosInstance.get(
    `api/farm_fields/${farm_field_id}/?year=${year}&season=${season}`
  );

  return response;
};

export const getFarms = async () => {
  const response = await axiosInstance.get("api/farms/");
  return response.data;
};

export const getFarm = async ({ farm_id }) => {
  const response = await axiosInstance.get(`api/farms/${farm_id}/`);
  return response.data;
};

export const getFarmFields = async ({ farm_id, season, year }) => {
  const response = await axiosInstance.get(
    `api/farms/${farm_id}/?season=${season}&year=${year}`
  );
  return response.data;
};

export const createFarm = async ({ data }) => {
  const response = await axiosInstance.post("api/farms/create/", data);
  return response.data;
};

export const createFarmField = async ({ data }) => {
  const response = await axiosInstance.post("api/farm_fields/create/", data);
  return response.data;
};

//chat/session api

export const generalSendChat = async ({ query, slug, thread_id, answer }) => {
  const response = await axiosInstance.post(`api/user_quick_chats/${slug}/`, {
    query,
    thread_id,
    answer,
  });

  return response.data;
};

export const getGeneralSessions = async () => {
  const response = await axiosInstance.get("api/user_quick_chat_sessions/");

  return response.data;
};

export const getGeneralSessionChat = async ({ slug }) => {
  const response = await axiosInstance.get(
    `/api/user_quick_chat_sessions/${slug}`
  );
  return response;
};

async function updateAssistantMessage(message, isStreaming = false) {
  if (isStreaming) {
    // Display words gradually for streaming
    const words = message.split(" ");
    let accumulatedMessage = "";

    for (let word of words) {
      accumulatedMessage += word + " ";

      await new Promise((resolve) => setTimeout(resolve, 50)); // Adjust this for streaming speed
    }
  } else {
  }
}

function scrollToBottomIfNeeded(containerId) {
  const container = document.getElementById(containerId);

  if (!container) return;

  // Check if the user is near the bottom of the container
  const isAtBottom =
    container.scrollTop + container.clientHeight >= container.scrollHeight - 50; // Allow a margin of 50px

  // Scroll to the bottom only if the user is at the bottom
  if (isAtBottom) {
    container.scrollTop = container.scrollHeight;
  }
}

export async function generalHostSendChat({ query, thread_id, elementId }) {
  const token = JSON.parse(localStorage.getItem("userToken")).access;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const body = {
    prompt: query,
    assistant_id: process.env.REACT_APP_ASSISTANT_ID,
    thread_id,
  };
  const response = await fetch(process.env.REACT_APP_CHAT_STREAM_API_URL, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  });

  const element = document.getElementById(elementId);

  const reader = response.body.getReader();
  const decoder = new TextDecoder();

  let threadId = null;
  let assistantResponse = "";

  while (true) {
    const { done, value } = await reader.read();
    if (done) break;

    const chunk = decoder.decode(value);
    const lines = chunk.split("\n");

    // let chat = null;

    for (const line of lines) {
      if (line.trim() !== "") {
        try {
          const data = JSON.parse(line);
          // Set threadId if it's received for the first time
          if (data.thread_id && !threadId) {
            threadId = data.thread_id;
          }

          if (data.message) {
            assistantResponse += data.message; // Accumulate the message only for this response
            element.innerHTML = marked.parse(assistantResponse);
            // document.getElementById("generalChatContainer").scrollTop =
            //   document.getElementById("generalChatContainer").scrollHeight;

            scrollToBottomIfNeeded("generalChatContainer");
          } else if (data.status) {
          } else if (data.error) {
          }
        } catch (error) {}
      }
    }
  }

  return { response, threadId, assistantResponse };
}

export const precisionHostSendChat = async ({
  query,
  field,
  thread_id,
  elementId,
  updating,
}) => {
  const token = JSON.parse(localStorage.getItem("userToken")).access;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const body = {
    prompt: query,
    assistant_id: process.env.REACT_APP_ASSISTANT_PRECISION_ID,
    thread_id,
    field,
  };
  const response = await fetch(process.env.REACT_APP_CHAT_STREAM_API_URL, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  });

  let element = null;

  if (elementId) {
    element = document.getElementById(elementId);
  }

  const reader = response.body.getReader();
  const decoder = new TextDecoder();

  let threadId = null;
  let assistantResponse = "";

  while (true) {
    const { done, value } = await reader.read();
    if (done) break;

    const chunk = decoder.decode(value);
    const lines = chunk.split("\n");

    for (const line of lines) {
      if (line.trim() !== "") {
        try {
          const data = JSON.parse(line);

          // Set threadId if it's received for the first time
          if (data.thread_id && !threadId) {
            threadId = data.thread_id;
          }

          if (data.message) {
            assistantResponse += data.message; // Accumulate the message only for this response
            if (element && !updating) {
              element.innerHTML = marked.parse(assistantResponse);
              scrollToBottomIfNeeded("precisionChatContainer");
            }
          } else if (data.status) {
          } else if (data.error) {
          }
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      }
    }
  }

  return { response, threadId, assistantResponse };
};

export const postDeactivateRequest = async () => {
  const response = await axiosInstance.post("/api/users/deactivate/");
  return response;
};

export const postDeactivateVerification = async (data) => {
  const response = await axiosInstance.post(
    "/api/users/deactivate/verify/",
    data
  );

  return response.data;
};

export const precisionSendChat = async ({
  query,
  slug,
  farm_field_crop,
  thread_id,
  answer,
  field_updated,
}) => {
  const response = await axiosInstance.post(`/api/user_farm_chats/${slug}/`, {
    farm_field_crop,
    query,
    thread_id,
    answer,
    field_updated,
  });

  return response.data;
};

export const getPrecisionSessionChat = async ({ slug }) => {
  const response = await axiosInstance.get(
    `/api/farm_field_chat_sessions/${slug}/`
  );

  return response.data;
};

export const getPrecisionSessions = async ({ season, year }) => {
  const response = await axiosInstance.get(
    `/api/farm_field_chat_sessions/?season=${season}&year=${year}`
  );

  return response.data;
};

export const editPrecisionSessionTitle = async ({ slug, session_title }) => {
  const response = await axiosInstance.put(
    `/api/farm_field_chat_sessions/edit/${slug}/`,
    { session_title }
  );

  return response.data;
};

export const deletePrecisionSession = async ({ slug }) => {
  const response = await axiosInstance.delete(
    `/api/farm_field_chat_sessions/delete/${slug}/`
  );

  return response.data;
};

export const editGeneralSessionTitle = async ({ slug, session_title }) => {
  const response = await axiosInstance.put(
    `/api/user_quick_chat_sessions/edit/${slug}/`,
    { session_title: session_title }
  );

  return response.data;
};

export const deleteGeneralSession = async ({ slug }) => {
  const response = await axiosInstance.delete(
    `/api/user_quick_chat_sessions/delete/${slug}/`
  );

  return response.data;
};
