import { useMutation } from "react-query";
import { editPassword } from "../../Services/apiAuth";

export function useEditPassword() {
  const { mutate: editPasswordData, isLoading: isEditPasswordData } =
    useMutation({
      mutationFn: editPassword,
    });

  return {
    editPasswordData,
    isEditPasswordData,
  };
}
