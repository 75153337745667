import { useQuery } from "react-query";
import { getCountries } from "../../Services/apiBloom";

export function useCountries() {
  const {
    data: countriesData,
    isLoading: isCountries,
    error: errorCountries,
  } = useQuery({
    queryKey: ["countries"],
    queryFn: getCountries,
  });

  return {
    countriesData,
    isCountries,
    errorCountries,
  };
}
