import { useMutation } from "react-query";
import { getFarmFields } from "../../Services/apiBloom";

export function useFarmFields() {
  const {
    mutate: farmFieldsData,
    isLoading: isFarmFieldsData,
    error: errorFarmFieldsData,
  } = useMutation({
    mutationFn: getFarmFields,
  });

  return {
    farmFieldsData,
    isFarmFieldsData,
    errorFarmFieldsData
  };
}
