import { useMutation } from "react-query";
import { editPrecisionSessionTitle } from "../../Services/apiBloom";

export function useEditPrecisionSessionTitle() {
  const {
    mutate: editPrecisionSessionTitleData,
    isLoading: isEditPrecisionSessionTitleData,
    error: errorEditPrecisionSessionTitleData,
  } = useMutation({
    mutationFn: editPrecisionSessionTitle,
  });

  return {
    editPrecisionSessionTitleData,
    isEditPrecisionSessionTitleData,
    errorEditPrecisionSessionTitleData,
  };
}
