import { useMutation } from "react-query";
import { getCities } from "../../Services/apiBloom";

export function useCities() {
  const {
    mutate: citiesData,
    isLoading: isCitiesData,
    error: errorCities,
  } = useMutation({
    mutationFn: getCities,
  });

  return {
    citiesData,
    isCitiesData,
    errorCities,
  };
}
