import { useMutation } from "react-query";
import { generalHostSendChat } from "../../Services/apiBloom";

export function useGeneralHostSendChat() {
  const {
    mutate: sendHostGeneralChatData,
    isLoading: isHostSendGeneralChatData,
    error: errorHostSendGeneralChatData,
  } = useMutation({
    mutationFn: generalHostSendChat,
  });

  return {
    sendHostGeneralChatData,
    isHostSendGeneralChatData,
    errorHostSendGeneralChatData,
  };
}
