import axios from "axios";
import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";

const baseURL = process.env.REACT_APP_UI_BACK_END_URL;

const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(async (req) => {
  let token = localStorage.getItem("userToken");

  if (token && token.length !== 0) {
    token = JSON.parse(token);
    if (token) {
      const user = jwtDecode(token?.access);
      const isAccessExp = dayjs.unix(user.exp).diff(dayjs()) < 1;

      if (!isAccessExp) {
        req.headers.Authorization = `Bearer ${token?.access}`;
        return req;
      }

      const isRefreshExp =
        dayjs.unix(jwtDecode(token?.refresh)).diff(dayjs()) < 1;
      if (!isRefreshExp) {
        return req;
      }

      const res = await axios.post(baseURL + "/token/refresh/", {
        refresh: token.refresh,
      });
      localStorage.setItem("authentication", JSON.stringify(res.data));
      req.headers.Authorization = `Bearer ${res.data.access}`;
    }
  }
  return req;
});

export default axiosInstance;
