import { useMutation } from "react-query";
import { getProvince } from "../../Services/apiBloom";

export function useProvinces() {
  const {
    mutate: provinceData,
    isLoading: isProvinceData,
    error: errorProvinceData,
  } = useMutation({
    mutationFn: getProvince,
  });

  return {
    provinceData,
    isProvinceData,
    errorProvinceData,
  };
}
