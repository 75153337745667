import { useMutation } from "react-query";
import { createFarm } from "../../Services/apiBloom";

export function useCreateFarm() {
  const {
    mutate: createFarmData,
    isLoading: isCreateFarmData,
    error: errorCreateFarmData,
  } = useMutation({
    mutationFn: createFarm,
  });

  return {
      createFarmData,
      isCreateFarmData,
      errorCreateFarmData,
  };
}
