import { useMutation } from "react-query";
import { deleteGeneralSession } from "../../Services/apiBloom";

export function useDeleteGeneralSession() {
  const {
    mutate: deleteGeneralSessionData,
    isLoading: isDeleteGeneralSessionData,
    error: errorDeleteGeneralSessionData,
  } = useMutation({
    mutationFn: deleteGeneralSession,
  });

  return {
    deleteGeneralSessionData,
    isDeleteGeneralSessionData,
    errorDeleteGeneralSessionData,
  };
}
