import { Typography, Card } from "antd";
import { useNavigate } from "react-router-dom";
import { useChat } from "../../Context/ChatProvider";
import { BubbleStar, ChatBubbleEmpty, NavArrowRight } from "iconoir-react";

import logo2 from "../../assets/logo2.png";
const { Text, Title } = Typography;
const { Meta } = Card;

function SelectChat() {
  const { dispatch } = useChat();
  const navigate = useNavigate();

  return (
    <div className="block">
      <img
        className="block w-40 h-40 mx-auto mb-8 mt-6 rounded-e-full z-10"
        alt={"Logo"}
        src={logo2}
      />
      <Title level={3} className="text-neutral-800 text-center">
        Your Virtual Crop Advisor
      </Title>
      <Text className="text-neutral-500 text-center mb-8 block">
        Smart guidance for your farm. Real-time answers, tailored to your crops,
        weeds, and conditions.
      </Text>
      <Text className="text-neutral-500 text-center mb-6 block">
        Select your chat type to start:
      </Text>
      <div className="flex gap-5 justify-center flex-wrap">
      <Card
        style={{
          width: 400,
        }}
        className="select-chat-cart"
        actions={[
          <button
            className="flex items-center"
            onClick={() => {
              navigate("/c/precision");
              dispatch({ type: "selectChat", payload: "precision" });
            }}
          >
            Start <NavArrowRight />
          </button>,
        ]}
      >
        <Meta
          avatar={<BubbleStar color="#73D13D" height={24} width={24} />}
          title="Precision Chat"
          description="Ask farm-specific questions to get detailed, accurate responses. Tailored advice for your unique farming needs."
        />
      </Card>
      <Card
        style={{
          width: 400,
        }}
        className="select-chat-cart"
        actions={[
          <button
            className="flex items-center"
            onClick={() => {
              navigate("/c/general");
              dispatch({ type: "selectChat", payload: "general" });
            }}
          >
            Start <NavArrowRight />
          </button>,
        ]}
      >
        <Meta
          avatar={<ChatBubbleEmpty color="#73D13D" height={24} width={24} />}
          title="General Chat"
          description="Get immediate responses to your general inquiries. Ideal for general advice on common farming issues."
        />
      </Card>
      </div>
    </div>
  );
}

export default SelectChat;
