import { Button, Form, Input, Divider, Typography, Flex } from "antd";
import { useAuth } from "../../Context/AuthProvider";
import { ReactComponent as ErrorIcon } from "../../assets/Vector2.svg";

function RegisterFormStep2() {
  const { formData, setFormData, handleContinue, current, isAuthenticating } =
    useAuth();

  function errorMessage(message) {
    return (
      <>
        <ErrorIcon className="inline-block mb-0.5" />
        <span className="inline-block font-medium	pl-1">{message}</span>
      </>
    );
  }

  const onChange = (e) => {
    setFormData({ ...formData, otp: e });
  };
  const sharedProps = {
    onChange,
  };

  return (
    <div
      className={`${
        current === 1 ? "block" : "hidden"
      } h-screen md:h-full lg:h-full`}
    >
      <div className="w-80 md:w-96 lg:w-96 shadow-md p-6 rounded-2xl border border-slate-200 bg-white">
        <Form
          initialValues={{
            remember: true,
          }}
        >
          <Form.Item
            name="otp"
            rules={[
              {
                required: true,
                message: errorMessage("Please enter the otp code!"),
              },
            ]}
          >
            <Flex gap="middle" vertical className="mb-2">
              <Input.OTP
                length={6}
                formatter={(str) => str.replace(/\D/g, "")}
                variant="filled"
                {...sharedProps}
                value={formData.otp}
              />
            </Flex>
          </Form.Item>
          <Form.Item>
            <Button
              className="px-4 py-5"
              block
              disabled={isAuthenticating}
              type="primary"
              onClick={handleContinue}
            >
              Continue
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default RegisterFormStep2;
