import React from "react";
import { Steps, Button } from "antd";
import "antd/dist/reset.css"; // Ant design styles
import { LeftOutlined } from "@ant-design/icons";

import RegisterFormStep1 from "./RegisterFormStep1";
import RegisterFormStep2 from "./RegisterFormStep2";
import RegisterFormStep3 from "./RegisterFormStep3";
import RegisterFormStep4 from "./RegisterFormStep4";
import { useAuth } from "../../Context/AuthProvider";

const NavRegister = () => {
  const { current, handlePrev } = useAuth();

  // Steps content
  const steps = [
    {
      title: "",
      content: <RegisterFormStep1 />,
    },
    {
      title: "",
      content: <RegisterFormStep2 />,
    },
    {
      title: "",
      content: <RegisterFormStep3 />,
    },
    {
      title: "",
      content: <RegisterFormStep4 />,
    },
  ];

  return (
    <div>
      {current === 0 && (
        <div className="mb-8">
          <h2 className="block text-center mx-auto font-semibold text-2xl mb-2 w-full">
            Create your account
          </h2>
          <p className="text-center font-light text-base mb-4 text-neutral-500 w-full">
            Fill out the form with your information
          </p>
        </div>
      )}
      {current === 1 && (
        <div className="mb-8">
          <Button
            type="text"
            shape="circle"
            icon={<LeftOutlined />}
            className="ms-0 lg:ms-14 text-neutral-500 mb-4"
            onClick={handlePrev}
          />
          <h2 className="block text-center mx-auto font-semibold text-2xl mb-2 w-full">
            Verify your email
          </h2>
          <p className="text-center font-light text-base mb-4 text-neutral-500 w-full">
            We sent an email to your email address <br /> Enter the OTP code
            below.
          </p>
        </div>
      )}
      {current === 2 && (
        <div className="mb-8">
          <Button
            type="text"
            shape="circle"
            icon={<LeftOutlined />}
            className="ms-0 lg:ms-14 text-neutral-500 mb-4"
            onClick={handlePrev}
          />
          <h2 className="block text-center mx-auto font-semibold text-2xl mb-2 w-full">
            Create your account
          </h2>
          <p className="text-center font-light text-base mb-4 text-neutral-500 w-full">
            Fill out the form with your information
          </p>
        </div>
      )}
      {current === 3 && (
        <div className="mb-5">
          <h2 className="block text-center mx-auto font-semibold text-2xl mb-2 w-full">
            Welcome to Agteq <span style={{ color: "#16a34a" }}>Bloom</span>
          </h2>
          <p className="text-center font-light text-base mb-4 text-neutral-500 w-full">
            Help us to understand you better to serve you best.
          </p>
        </div>
      )}

      <Steps
        progressDot
        labelPlacement="vertical"
        responsive={false}
        size="small"
        current={current}
        items={steps}
      />
      <div className="steps-content flex flex-wrap justify-center mt-6">
        <RegisterFormStep1 />
        <RegisterFormStep2 />
        <RegisterFormStep3 />
        <RegisterFormStep4 />
      </div>
    </div>
  );
};

export default NavRegister;
