import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthProvider";

function ProtectedAuth({ children }) {
  const navigate = useNavigate();
  const { user, current } = useAuth();

  useEffect(() => {
    if (user && current === 0) {
      navigate("/");
    }
  }, [user, current, navigate]);

  return children;
}

export default ProtectedAuth;
