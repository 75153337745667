import { useQuery } from "react-query";
import { getWeedDensities } from "../../Services/apiBloom";

export function useWeeedDensities() {
  const {
    data: weedDensitiesData,
    isLoading: isWeedDensities,
    error: errorWeedDensitiesData,
  } = useQuery({
    queryKey: ["weedDensities"],
    queryFn: getWeedDensities,
  });

  return {
    weedDensitiesData,
    isWeedDensities,
    errorWeedDensitiesData,
  };
}
