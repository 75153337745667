import { useMutation } from "react-query";
import { login } from "../../Services/apiAuth";

export function useLogin() {
  const { mutate: loginData, isLoading: isLogin } =
    useMutation({
      mutationFn: login,
    });

  return {
    loginData,
    isLogin,
  };
}
