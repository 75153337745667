import { useMutation } from "react-query";
import { sendEmail } from "../../Services/apiAuth";

export function useSendEmail() {
  const {
    mutate: sendedEmailData,
    isLoading: isEmailSending,
    error: errorEmailSending,
  } = useMutation({
    mutationFn: sendEmail,
  });

  return {
    sendedEmailData,
    isEmailSending,
    errorEmailSending,
  };
}
