// src/App.js
import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./Utilities/ProtectedRoute";

import Login from "./Pages/Login";
import Register from "./Pages/Register";
import { AuthProvider } from "./Context/AuthProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Chat from "./Features/Chat/Chat";
import SelectChat from "./Features/Chat/SelectChat";
import Bloom from "./Pages/Bloom";
import ProtectedAuth from "./Utilities/ProtectedAuth";
import { ChatProvider } from "./Context/ChatProvider";
import ForgotPassword from "./Pages/ForgotPassword";
import ChangePassword from "./Pages/ChangePassword";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      stateTime: 180 * 1000,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GoogleOAuthProvider clientId="686112212345-jeokapuammkltbr7532d1hm97be7aomj.apps.googleusercontent.com">
        <BrowserRouter>
          <AuthProvider>
            <ChatProvider>
              <Routes>
                <Route
                  path="login"
                  element={
                    <ProtectedAuth>
                      <Login />
                    </ProtectedAuth>
                  }
                />
                <Route
                  path="forgot_password"
                  element={
                    <ProtectedAuth>
                      <ForgotPassword />
                    </ProtectedAuth>
                  }
                />
                <Route
                  path="change_password"
                  element={
                    <ProtectedAuth>
                      <ChangePassword />
                    </ProtectedAuth>
                  }
                />
                <Route
                  path="change_password/:slug"
                  element={
                    <ProtectedAuth>
                      <ChangePassword />
                    </ProtectedAuth>
                  }
                />
                <Route
                  path="register"
                  element={
                    <ProtectedAuth>
                      <Register />
                    </ProtectedAuth>
                  }
                />
                <Route
                  element={
                    <ProtectedRoute>
                      <Bloom />
                    </ProtectedRoute>
                  }
                >
                  <Route index element={<SelectChat />} />

                  <Route path="/" element={<SelectChat />} />
                  <Route path="/c/:mode" element={<Chat />} />
                  <Route path="/c/:mode/:hash" element={<Chat />} />
                </Route>
              </Routes>
            </ChatProvider>
          </AuthProvider>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </QueryClientProvider>
  );
}

export default App;
