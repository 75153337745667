import { useMutation } from "react-query";
import { sendEmailWithGoogle } from "../../Services/apiAuth";

export function useSendGoogleEmail() {
  const {
    mutate: sendedEmailGoogleData,
    isLoading: isEmailGoogleSending,
    error: errorEmailGoogleSending,
  } = useMutation({
    mutationFn: sendEmailWithGoogle,
  });

  return {
    sendedEmailGoogleData,
    isEmailGoogleSending,
    errorEmailGoogleSending,
  };
}
