import { useMutation } from "react-query";
import { getFarmField } from "../../Services/apiBloom";

export function useFarmField() {
  const {
    mutate: farmFieldData,
    isLoading: isFarmFieldData,
    error: errorFarmFieldData,
  } = useMutation({
    mutationFn: getFarmField,
  });

  return {
    farmFieldData,
    isFarmFieldData,
    errorFarmFieldData,
  };
}
