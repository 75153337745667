import { useMutation } from "react-query";
import { getPrecisionSessions } from "../../Services/apiBloom";

export function usePrecisionSessions() {
  const {
    mutate: precisionSessionsData,
    isLoading: isPrecisionSessionsData,
    error: erroPrecisionSessionsData,
  } = useMutation({
    mutationFn: getPrecisionSessions,
  });

  return {
      precisionSessionsData,
      isPrecisionSessionsData,
      erroPrecisionSessionsData,
  };
}
