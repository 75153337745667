import React, { useEffect, useState } from "react";
import { Layout, message, theme } from "antd";
import ChatHeader from "./ChatHeader";
import { useChat } from "../Context/ChatProvider";
import { useLocation, useParams } from "react-router-dom";
import LeftSider from "./LeftSider";
import RightSider from "./RightSider";

import ChatDrawer from "./ChatDrawer";
import EditDrawer from "./EditDrawer";
import { useFarms } from "../Features/Chat/useFarms";
import { useFarmFields } from "../Features/Chat/useFarmFields";
import { useFarmField } from "../Features/Chat/useFarmField";
import { useFarm } from "../Features/Chat/useFarm";
import { useGeneralSessions } from "../Features/Chat/useGeneralSessions";
import HeaderProfile from "./HeaderProfile";

const { Header, Content } = Layout;

function AppLayout({ children }) {
  //setting render condition state for mobile
  const {
    chatMode,
    dispatch,
    handleFieldInputChange,
    year,
    season,
    handleFarmInputChange,
  } = useChat();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 996);

  const {
    generalSessionsData,
    isGeneralSessionsData,
    errorGeneralSessionsData,
  } = useGeneralSessions();

  useEffect(() => {
    dispatch({
      type: "setGeneralSessions",
      payload:
        isGeneralSessionsData || errorGeneralSessionsData
          ? []
          : generalSessionsData,
    });
  }, [errorGeneralSessionsData, isGeneralSessionsData, generalSessionsData]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 996) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile, setIsMobile]);

  const [openChatDrawer, setOpenChatDrawer] = useState(false);
  const showDrawer = () => {
    setOpenChatDrawer(true);
  };
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const showEditDrawer = () => {
    setOpenEditDrawer(true);
  };

  const { farmsData, isFarms, errorFarms } = useFarms();
  const { farmFieldsData, isFarmFieldsData, errorFarmFieldsData } =
    useFarmFields();

  const farm = isFarms || errorFarms ? [] : farmsData[0];

  const { farmFieldData, isFarmFieldData, errorFarmFieldData } = useFarmField();

  const { farmData, isFarmData, errorFarmData } = useFarm();
  const { mode, hash } = useParams();

  useEffect(() => {
    if (hash) {
      const parts = hash.split("-");
      const slugFarm = parts[1];

      const FarmCurrent =
        isFarms || errorFarms
          ? null
          : farmsData?.find((farmD) => farmD.id === Number(slugFarm));


      farmData(
        { farm_id: FarmCurrent?.id },
        {
          onSuccess: (data) => {
            handleFarmInputChange(
              "country",
              !data.municipal_district
                ? 0
                : data.municipal_district.province.country.id
            );
            handleFarmInputChange(
              "countryText",
              !data.municipal_district
                ? ""
                : data.municipal_district.province.country.country_name
            );
            handleFarmInputChange(
              "province_state",
              !data.municipal_district ? 0 : data.municipal_district.province.id
            );
            handleFarmInputChange(
              "province_stateText",
              !data.municipal_district
                ? ""
                : data.municipal_district.province.name
            );
            handleFarmInputChange(
              "county",
              !data.municipal_district ? 0 : data.municipal_district.id
            );
            handleFarmInputChange(
              "countyText",
              !data.municipal_district ? "" : data.municipal_district.name
            );
          },
        }
      );

      dispatch({ type: "selectSideBarMode", payload: "Field" });
    } else {
      if (farm?.id) {
        farmData(
          { farm_id: farm?.id },
          {
            onSuccess: (data) => {
              handleFarmInputChange(
                "country",
                !data.municipal_district
                  ? 0
                  : data.municipal_district.province.country.id
              );
              handleFarmInputChange(
                "countryText",
                !data.municipal_district
                  ? ""
                  : data.municipal_district.province.country.country_name
              );
              handleFarmInputChange(
                "province_state",
                !data.municipal_district
                  ? 0
                  : data.municipal_district.province.id
              );
              handleFarmInputChange(
                "province_stateText",
                !data.municipal_district
                  ? ""
                  : data.municipal_district.province.name
              );
              handleFarmInputChange(
                "county",
                !data.municipal_district ? 0 : data.municipal_district.id
              );
              handleFarmInputChange(
                "countyText",
                !data.municipal_district ? "" : data.municipal_district.name
              );
            },
          }
        );
      }
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: "getFarms",
      payload: isFarms || errorFarms ? [] : farmsData,
    });

    if (hash) {
      const parts = hash.split("-");
      const slugFarm = parts[1];
      const field = parts[2];

      dispatch({
        type: "selectFarm",
        payload: Number(slugFarm),
      });

      const FarmCurrent =
        isFarms || errorFarms
          ? null
          : farmsData?.find((farmD) => farmD.id === Number(slugFarm));

      handleFarmInputChange("city", FarmCurrent?.farm_city);
      handleFarmInputChange("farm_name", FarmCurrent?.farm_name);
      handleFarmInputChange("planted_area", FarmCurrent?.farm_area_in_acre);
      handleFarmInputChange("range", FarmCurrent?.farm_range);
      handleFarmInputChange("lld", FarmCurrent?.lld);
      handleFarmInputChange("meridian", FarmCurrent?.meridian);
      handleFarmInputChange("postal_address", FarmCurrent?.postal_address);
      handleFarmInputChange("quarter_section", FarmCurrent?.quarter_section);
      handleFarmInputChange("section", FarmCurrent?.section);
      handleFarmInputChange("township", FarmCurrent?.township);
      handleFarmInputChange("postal_address", FarmCurrent?.postal_address);

      if (FarmCurrent) {
        handleFieldInputChange("farm", FarmCurrent?.id);
        farmFieldsData(
          { farm_id: FarmCurrent?.id, season: season, year: year },
          {
            onSuccess: (data) => {
              dispatch({ type: "getFarmFields", payload: data.fields });
              dispatch({ type: "selectField", payload: Number(field) });
              farmFieldData(
                {
                  farm_field_id: Number(field),
                  year: year,
                  season: season,
                },
                {
                  onSuccess: (data) => {
                    handleFieldInputChange(
                      "field_name",
                      data.data.field_name ? data.data.field_name : 0
                    );
                    handleFieldInputChange(
                      "soil_texture",
                      data.data.soil_type ? data.data.soil_type : 0
                    );
                    handleFieldInputChange(
                      "irregation_type",
                      data.data.irrigation_type ? data.data.irrigation_type : 0
                    );
                    handleFieldInputChange(
                      "planted_area",
                      data?.data.area_in_acre
                    );
                    handleFieldInputChange(
                      "approximate_crop_height",
                      data.data?.approximate_crop_height
                    );
                    handleFieldInputChange(
                      "id",
                      data.data.farm_field_crops?.id
                        ? data.data.farm_field_crops?.id
                        : 0
                    );
                    handleFieldInputChange(
                      "crop_name",
                      data.data.farm_field_crops?.crop
                        ? data.data.farm_field_crops?.crop
                        : 0
                    );
                    handleFieldInputChange(
                      "crop_stage",
                      data.data.farm_field_crops?.crop_stage
                        ? data.data.farm_field_crops?.crop_stage
                        : 0
                    );
                    handleFieldInputChange(
                      "farm",
                      data.data.farm ? data.data.farm : 0
                    );
                    handleFieldInputChange(
                      "weeds",
                      data.data?.farm_field_crops?.farm_field_weeds
                        ? data.data.farm_field_crops.farm_field_weeds.map(
                            (weed) => ({
                              weed_name: weed.weed,
                              weed_density: weed.weed_density,
                              leaves_number: weed.weed_stage.number_of_leaves,
                              height: weed.weed_stage.heihgt_in_cm,
                              flowering: weed.weed_stage.flowering,
                            })
                          )
                        : []
                    );
                  },
                }
              );
            },
            onError: () => message.error("Connection with the server failed!"),
          }
        );
      }
      dispatch({ type: "selectSideBarMode", payload: "Field" });
    } else {
      dispatch({
        type: "selectFarm",
        payload: isFarms || errorFarms ? [] : farmsData[0]?.id,
      });

      handleFarmInputChange("city", farm.farm_city);
      handleFarmInputChange("farm_name", farm.farm_name);
      handleFarmInputChange("planted_area", farm.farm_area_in_acre);
      handleFarmInputChange("range", farm.farm_range);
      handleFarmInputChange("lld", farm.lld);
      handleFarmInputChange("meridian", farm.meridian);
      handleFarmInputChange("postal_address", farm.postal_address);
      handleFarmInputChange("quarter_section", farm.quarter_section);
      handleFarmInputChange("section", farm.section);
      handleFarmInputChange("township", farm.township);
      handleFarmInputChange("postal_address", farm.postal_address);

      if (farmsData) {
        handleFieldInputChange("farm", farmsData[0]?.id);
        farmFieldsData(
          { farm_id: farmsData[0]?.id, season: season, year: year },
          {
            onSuccess: (data) => {
              dispatch({ type: "getFarmFields", payload: data.fields });
              dispatch({ type: "selectField", payload: data.fields[0].id });
              farmFieldData(
                {
                  farm_field_id: data.fields[0].id,
                  year: year,
                  season: season,
                },
                {
                  onSuccess: (data) => {
                    handleFieldInputChange(
                      "field_name",
                      data.data.field_name ? data.data.field_name : 0
                    );
                    handleFieldInputChange(
                      "soil_texture",
                      data.data.soil_type ? data.data.soil_type : 0
                    );
                    handleFieldInputChange(
                      "irregation_type",
                      data.data.irrigation_type ? data.data.irrigation_type : 0
                    );
                    handleFieldInputChange(
                      "planted_area",
                      data?.data.area_in_acre
                    );
                    handleFieldInputChange(
                      "approximate_crop_height",
                      data.data?.approximate_crop_height
                    );
                    handleFieldInputChange(
                      "id",
                      data.data.farm_field_crops?.id
                        ? data.data.farm_field_crops?.id
                        : 0
                    );
                    handleFieldInputChange(
                      "crop_name",
                      data.data.farm_field_crops?.crop
                        ? data.data.farm_field_crops?.crop
                        : 0
                    );
                    handleFieldInputChange(
                      "crop_stage",
                      data.data.farm_field_crops?.crop_stage
                        ? data.data.farm_field_crops?.crop_stage
                        : 0
                    );
                    handleFieldInputChange(
                      "farm",
                      data.data.farm ? data.data.farm : 0
                    );
                    handleFieldInputChange(
                      "weeds",
                      data.data?.farm_field_crops?.farm_field_weeds
                        ? data.data.farm_field_crops.farm_field_weeds.map(
                            (weed) => ({
                              weed_name: weed.weed,
                              weed_density: weed.weed_density,
                              leaves_number: weed.weed_stage.number_of_leaves,
                              height: weed.weed_stage.heihgt_in_cm,
                              flowering: weed.weed_stage.flowering,
                            })
                          )
                        : []
                    );
                  },
                }
              );
            },
            onError: () => message.error("Connection with the server failed!"),
          }
        );
      }
    }
  }, [isFarms]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout style={{ height: "100svh", flexDirection: "row" }}>
      {/* Set the entire Layout to fill the viewport */}
      {/* Left Sider */}
      {location.pathname !== "/" ? (
        !isMobile ? (
          <LeftSider />
        ) : (
          <ChatDrawer
            openDrawer={openChatDrawer}
            setOpenDrawer={setOpenChatDrawer}
            showEditDrawer={showEditDrawer}
          />
        )
      ) : null}
      <Layout
        style={{ display: "flex", flexWrap: "wrap", flexDirection: "column", width: "100%" }}
      >
        {/* Added flex and flex-wrap here */}
        {/* Full-width Header */}
        <Header
          style={{
            background: colorBgContainer,
            width: "100%", // Ensure the header spans the full width
            position: "relative",
            height: "auto",
          }}
          className="border-b border-neutral-300 py-4 px-5 flex items-center justify-between"
        >
          <ChatHeader showDrawer={showDrawer} showEditDrawer={showEditDrawer} />
          {isMobile ? <HeaderProfile isMobile={isMobile} /> : null}
        </Header>
        <Content
          style={{
            margin: "0",
            display: "flex", // Use flex to control layout of main content and right sidebar
            flex: 1, // Make sure it occupies remaining space
            width: "100%",
          }}
        >
          {/* Main Content Container */}
          <div
            style={{
              padding: 24,
              background: colorBgContainer,
              flex: "1 1 60%", // Allow the main content to grow, shrink, and start with 60% width
              marginRight: "0", // Space between main content and right sider
              minWidth: "300px", // Set a minimum width to prevent it from shrinking too much
              overflow: "auto", // Enable scrolling if the content overflows
              alignContent: "center",
            }}
          >
            {children}
          </div>

          {/* Right Sider */}
          {!isMobile ? (
            chatMode === "precision" ? (
              <RightSider isMobile={isMobile} />
            ) : null
          ) : (
            <EditDrawer
              setOpenEditDrawer={setOpenEditDrawer}
              openEditDrawer={openEditDrawer}
            />
          )}
        </Content>
      </Layout>
    </Layout>
  );
}

export default AppLayout;
