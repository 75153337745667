import { useMutation } from "react-query";
import { getCounties } from "../../Services/apiBloom";

export function useCounties() {
  const {
    mutate: countyData,
    isLoading: isCountyData,
    error: errorCountyData,
  } = useMutation({
    mutationFn: getCounties,

  });

  return {
    countyData,
    isCountyData,
    errorCountyData,
  };
}
