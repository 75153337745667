import { useMutation } from "react-query";
import { otpValidation } from "../../Services/apiAuth";

export function useOtpValidation() {
  const {
    mutate: otpValidateData,
    isLoading: isOtpValidating,
    error: errorOtpValidation,
  } = useMutation({
    mutationFn: otpValidation,
  });

  return {
    otpValidateData,
    isOtpValidating,
    errorOtpValidation,
  };
}
