import React, { useEffect, useState } from "react";
import { Farm, SquareDashed, EditPencil } from "iconoir-react";
import {
  Layout,
  theme,
  Segmented,
  Button,
  Form,
  Select,
  Collapse,
  Divider,
  Typography,
  message,
} from "antd";
import { useChat } from "../Context/ChatProvider";
import ChatModal from "./ChatModal";
import { useSoilTexture } from "../Features/Chat/useSoilTypes";
import { useIrregationTypes } from "../Features/Chat/useIrregationTypes";
import { useCropGroups } from "../Features/Chat/useCropGroups";
import { useCrops } from "../Features/Chat/useCrops";
import { useWeeds } from "../Features/Chat/useWeeds";
import { useWeeedDensities } from "../Features/Chat/useWeedDensities";
import { useFarmField } from "../Features/Chat/useFarmField";

const { Panel } = Collapse;
const { Sider } = Layout;
const { Title, Text } = Typography;

function RightSider({ isMobile }) {
  const {
    dispatch,
    sideBarMode,
    farmFormData,
    fieldFormData,
    farms,
    currentFarm,
    currentField,
    season,
    year,
    handleFieldInputChange,
  } = useChat();

  const { cropGroupsData, isCropGroups, errorCropGroups } = useCropGroups();
  function findCropById(id) {
    // Recursive function to search for the crop by id
    const search = (crops) => {
      if (!isCropGroups && !errorCropGroups) {
        for (let category of crops) {
          // Iterate through the crops array
          for (let crop of category.crops) {
            if (crop.id === id) {
              return crop.crop_name; // Return the crop_name if id matches
            }

            // If there are children, search recursively
            if (crop.children.length > 0) {
              const result = search([{ crops: crop.children }]);
              if (result) return result; // Return if found in children
            }
          }
        }
      }
      return null; // Return null if not found
    };

    return search(cropGroupsData);
  }

  const sideBarModeSelectorSegment = (value) => {
    if (value === "Field") {
      dispatch({ type: "selectSideBarMode", payload: "Field" });
    } else {
      dispatch({ type: "selectSideBarMode", payload: "Farm" });
    }
  };
  const { farmFieldData, isFarmFieldData, errorFarmFieldData } = useFarmField();
  const { soilTextureData, isSoilTextureData, errorSoilTextureData } =
    useSoilTexture();

  const {
    irregationTypesData,
    isIrregationTypesData,
    errorIrregationTypesData,
  } = useIrregationTypes();

  const { cropData, isCropData } = useCrops();
  const { weedsData, isWeeds, errorWeedsData } = useWeeds();
  const { weedDensitiesData, isWeedDensities, errorWeedDensitiesData } =
    useWeeedDensities();

  const [open, setOpen] = useState(false);
  const showModal = () => {
    if (sideBarMode === "Field") {
      farmFieldData(
        {
          farm_field_id: currentField,
          year: year,
          season: season,
        },
        {
          onSuccess: (data) => {
            if (!data.data.farm_field_crops?.crop) {
              dispatch({
                type: "getBackUpFieldData",
                payload: {
                  farm: currentFarm,
                  field_name: data.data.field_name,
                  soil_texture: soilTextureData?.find(
                    (soil) => soil.id === data.data.soil_type
                  )
                    ? soilTextureData?.find(
                        (soil) => soil.id === data.data.soil_type
                      )?.type_name
                    : 0,
                  irregation_type: irregationTypesData?.find(
                    (irregation) => irregation.id === data.data.irrigation_type
                  )
                    ? irregationTypesData?.find(
                        (irregation) =>
                          irregation.id === data.data.irrigation_type
                      )?.type_name
                    : 0,
                  planted_area: data?.data.area_in_acre,
                  approximate_crop_height: data.data?.approximate_crop_height,
                  crop_name: "",
                  crop_stage: "",
                  weeds: data.data?.farm_field_crops?.farm_field_weeds
                    ? data.data.farm_field_crops.farm_field_weeds?.map(
                        (weed) => ({
                          weed_name: weedsData?.find(
                            (secWeed) => secWeed.id === weed.weed
                          )
                            ? weedsData?.find(
                                (secWeed) => secWeed.id === weed.weed
                              )?.weed_type
                            : 0,
                          weed_density: weedDensitiesData?.find(
                            (density) => density.id === weed.weed_density
                          )
                            ? weedDensitiesData?.find(
                                (density) => density.id === weed.weed_density
                              )?.weed_density
                            : 0,
                          leaves_number: weed.weed_stage.number_of_leaves,
                          height: weed.weed_stage.heihgt_in_cm,
                          flowering: weed.weed_stage.flowering,
                        })
                      )
                    : [],
                },
              });
              setOpen(true);
            }

            if (
              data.data?.farm_field_crops?.crop &&
              !data.data.farm_field_crops.crop_stage
            ) {
              dispatch({
                type: "getBackUpFieldData",
                payload: {
                  farm: currentFarm,
                  field_name: data.data.field_name,
                  soil_texture: soilTextureData?.find(
                    (soil) => soil.id === data.data.soil_type
                  )
                    ? soilTextureData?.find(
                        (soil) => soil.id === data.data.soil_type
                      )?.type_name
                    : 0,
                  irregation_type: irregationTypesData?.find(
                    (irregation) => irregation.id === data.data.irrigation_type
                  )
                    ? irregationTypesData?.find(
                        (irregation) =>
                          irregation.id === data.data.irrigation_type
                      )?.type_name
                    : 0,
                  planted_area: data?.data.area_in_acre,
                  approximate_crop_height: data.data?.approximate_crop_height,
                  crop_name: findCropById(data.data.farm_field_crops?.crop),
                  crop_stage: "",
                  weeds: data.data?.farm_field_crops?.farm_field_weeds
                    ? data.data.farm_field_crops.farm_field_weeds?.map(
                        (weed) => ({
                          weed_name: weedsData?.find(
                            (secWeed) => secWeed.id === weed.weed
                          )
                            ? weedsData?.find(
                                (secWeed) => secWeed.id === weed.weed
                              )?.weed_type
                            : 0,
                          weed_density: weedDensitiesData?.find(
                            (density) => density.id === weed.weed_density
                          )
                            ? weedDensitiesData?.find(
                                (density) => density.id === weed.weed_density
                              )?.weed_density
                            : 0,
                          leaves_number: weed.weed_stage.number_of_leaves,
                          height: weed.weed_stage.heihgt_in_cm,
                          flowering: weed.weed_stage.flowering,
                        })
                      )
                    : [],
                },
              });
              setOpen(true);
            }

            if (
              data.data?.farm_field_crops?.crop &&
              data.data.farm_field_crops?.crop_stage
            ) {
              cropData(
                { crop_id: data.data?.farm_field_crops?.crop },
                {
                  onSuccess: (seccondData) => {
                    dispatch({
                      type: "getBackUpFieldData",
                      payload: {
                        farm: currentFarm,
                        field_name: data.data.field_name,
                        soil_texture: soilTextureData?.find(
                          (soil) => soil.id === data.data.soil_type
                        )
                          ? soilTextureData?.find(
                              (soil) => soil.id === data.data.soil_type
                            )?.type_name
                          : 0,
                        irregation_type: irregationTypesData?.find(
                          (irregation) =>
                            irregation.id === data.data.irrigation_type
                        )
                          ? irregationTypesData?.find(
                              (irregation) =>
                                irregation.id === data.data.irrigation_type
                            )?.type_name
                          : 0,
                        planted_area: data?.data.area_in_acre,
                        approximate_crop_height:
                          data.data?.approximate_crop_height,
                        crop_name: findCropById(
                          data.data.farm_field_crops?.crop
                        ),
                        crop_stage: seccondData.crop_stages.find(
                          (stage) =>
                            stage.id === data.data.farm_field_crops?.crop_stage
                        )?.stage_name,
                        weeds: data.data?.farm_field_crops?.farm_field_weeds
                          ? data.data.farm_field_crops.farm_field_weeds?.map(
                              (weed) => ({
                                weed_name: weedsData?.find(
                                  (secWeed) => secWeed.id === weed.weed
                                )
                                  ? weedsData?.find(
                                      (secWeed) => secWeed.id === weed.weed
                                    )?.weed_type
                                  : 0,
                                weed_density: weedDensitiesData?.find(
                                  (density) => density.id === weed.weed_density
                                )
                                  ? weedDensitiesData?.find(
                                      (density) =>
                                        density.id === weed.weed_density
                                    )?.weed_density
                                  : 0,
                                leaves_number: weed.weed_stage.number_of_leaves,
                                height: weed.weed_stage.heihgt_in_cm,
                                flowering: weed.weed_stage.flowering,
                              })
                            )
                          : [],
                      },
                    });
                    setOpen(true);
                  },
                }
              );
            }
          },
          onError: () =>
            message.error(
              "Something went wrong while opening modal, Please try again!"
            ),
        }
      );
    } else {
      setOpen(true);
    }
  };
  const handleOk = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    dispatch({ type: "getBackUpFieldData", payload: {} });
    setOpen(false);
  };

  const [openDrawer, setOpenDrawer] = useState(false);
  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const [previewStage, setPreviewStages] = useState();

  useEffect(() => {
    cropData(
      { crop_id: fieldFormData.crop_name },
      {
        onSuccess: (data) => {
          setPreviewStages(data?.crop_stages);
        },
        onError: () => {
          return null;
        },
      }
    );
  }, [fieldFormData.crop_name, cropData]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Sider
      width={286}
      style={{
        background: colorBgContainer,
        flex: "0 1 250px", // Ensure it stays at 250px but can shrink if needed
        minWidth: "200px", // Minimum width to prevent disappearing
        height: "100%", // Ensure it fills the container height
      }}
      className={
        "border-s border-solid border-neutral-300 px-4 py-7 right-sidebar"
      }
      theme="light"
    >
      <Segmented
        block
        size="small"
        className="farm-segmented mb-5"
        onChange={sideBarModeSelectorSegment}
        value={sideBarMode === "Field" ? "Field" : "Farm"}
        options={[
          {
            label: "Farm",
            value: "Farm",
            icon: <Farm height={16} width={16} />,
          },
          {
            label: "Field",
            value: "Field",
            icon: <SquareDashed height={16} width={16} />,
          },
        ]}
      />
      {sideBarMode === "Farm" && (
        <Button
          type="primary"
          icon={<EditPencil height={16} width={16} />}
          block
          className="edit-field-farm-btn mb-5"
          onClick={showModal}
        >
          {farms?.map((farm) =>
            farm.id === currentFarm ? farm.farm_name : ""
          )}
        </Button>
      )}
      {sideBarMode === "Field" && (
        <Button
          type="primary"
          icon={<EditPencil height={16} width={16} />}
          block
          className="edit-field-farm-btn mb-5"
          onClick={showModal}
        >
          {fieldFormData.field_name ? fieldFormData.field_name : "Field"}
        </Button>
      )}
      <ChatModal
        open={open}
        setOpen={setOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <div style={{ flex: 1, overflowY: "auto" }}>
        {sideBarMode === "Field" && (
          <Form layout="vertical">
            <Collapse
              expandIconPosition="end"
              className="mb-4"
              defaultActiveKey={['1']}
            >
              <Panel
                header="Field Details"
                key="1"
                style={{ fontWeight: "600", fontSize: "16px" }}
              >
                <Form.Item>
                  <Title
                    level={5}
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      marginBottom: "6px",
                      color: "#737373",
                    }}
                  >
                    Field Name
                  </Title>
                  <Text className="font-semibold text-neutral-800">
                    {fieldFormData.field_name ? fieldFormData.field_name : ""}
                  </Text>
                </Form.Item>
                <Form.Item>
                  <Title
                    level={5}
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      marginBottom: "6px",
                      color: "#737373",
                    }}
                  >
                    Soil Texture
                  </Title>
                  <Text className="font-semibold text-neutral-800">
                    {isSoilTextureData && errorSoilTextureData
                      ? ""
                      : !fieldFormData.soil_texture
                      ? "Unknown"
                      : soilTextureData?.map((soilTexture) =>
                          soilTexture.id === fieldFormData.soil_texture
                            ? soilTexture.type_name
                            : null
                        )}
                  </Text>
                </Form.Item>
                <Form.Item>
                  <Title
                    level={5}
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      marginBottom: "6px",
                      color: "#737373",
                    }}
                  >
                    Irrigation Type
                  </Title>
                  <Text className="font-semibold text-neutral-800">
                    {isIrregationTypesData && errorIrregationTypesData
                      ? ""
                      : !fieldFormData.irregation_type
                      ? "Unknown"
                      : irregationTypesData?.map((irregationType) =>
                          irregationType.id === fieldFormData.irregation_type
                            ? irregationType.type_name
                            : null
                        )}
                  </Text>
                </Form.Item>
                <Form.Item>
                  <Title
                    level={5}
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      marginBottom: "6px",
                      color: "#737373",
                    }}
                  >
                    Planted Area
                  </Title>
                  <Text className="font-semibold text-neutral-800">
                    {`${
                      !fieldFormData.planted_area
                        ? ""
                        : fieldFormData.planted_area
                    } ${fieldFormData.planted_area ? "Acres" : ""}`}
                  </Text>
                </Form.Item>
                <Form.Item>
                  <Title
                    level={5}
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      marginBottom: "6px",
                      color: "#737373",
                    }}
                  >
                    Approximate Crop Height
                  </Title>
                  <Text className="font-semibold text-neutral-800">
                    {`${
                      !fieldFormData.approximate_crop_height
                        ? ""
                        : fieldFormData.approximate_crop_height
                    } ${fieldFormData.approximate_crop_height ? "CM" : ""}`}
                  </Text>
                </Form.Item>
              </Panel>
            </Collapse>
            <Collapse
              expandIconPosition="end"
              className="mb-4"
            >
              <Panel
                header="Corp Details"
                key="2"
                style={{ fontWeight: "600", fontSize: "16px" }}
              >
                <Form.Item>
                  <Title
                    level={5}
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      marginBottom: "6px",
                      color: "#737373",
                    }}
                  >
                    Crop Name
                  </Title>
                  <Text className="font-semibold text-neutral-800">
                    {isCropGroups && errorCropGroups
                      ? ""
                      : !fieldFormData.crop_name
                      ? ""
                      : findCropById(fieldFormData.crop_name)}
                  </Text>
                </Form.Item>
                <Form.Item>
                  <Title
                    level={5}
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      marginBottom: "6px",
                      color: "#737373",
                    }}
                  >
                    Crop Stage
                  </Title>
                  <Text className="font-semibold text-neutral-800">
                    {!fieldFormData.crop_name
                      ? ""
                      : !fieldFormData.crop_stage
                      ? ""
                      : previewStage?.map((stage) =>
                          stage.id === fieldFormData.crop_stage
                            ? stage.stage_name
                            : null
                        )}
                  </Text>
                </Form.Item>
              </Panel>
            </Collapse>
            <Collapse
              expandIconPosition="end"
              className="mb-4"
            >
              <Panel
                header="Weeds Detail"
                key="3"
                style={{ fontWeight: "600", fontSize: "16px" }}
              >
                {!fieldFormData.weeds ? (
                  <>
                    <Form.Item>
                      <Title
                        level={5}
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          marginBottom: "6px",
                          color: "#737373",
                        }}
                      >
                        Weed Name
                      </Title>
                      <Text className="font-semibold text-neutral-800">
                        Dandelion
                      </Text>
                    </Form.Item>
                    <Form.Item>
                      <Title
                        level={5}
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          marginBottom: "6px",
                          color: "#737373",
                        }}
                      >
                        Weed Density
                      </Title>
                      <Text className="font-semibold text-neutral-800">
                        Medium
                      </Text>
                    </Form.Item>
                    <Form.Item>
                      <Title
                        level={5}
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          marginBottom: "6px",
                          color: "#737373",
                        }}
                      >
                        Number of Leaves
                      </Title>
                      <Text className="font-semibold text-neutral-800">3</Text>
                    </Form.Item>
                    <Form.Item>
                      <Title
                        level={5}
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          marginBottom: "6px",
                          color: "#737373",
                        }}
                      >
                        Height
                      </Title>
                      <Text className="font-semibold text-neutral-800">
                        40 CM
                      </Text>
                    </Form.Item>
                    <Form.Item>
                      <Title
                        level={5}
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          marginBottom: "6px",
                          color: "#737373",
                        }}
                      >
                        Flowering
                      </Title>
                      <Text className="font-semibold text-neutral-800">
                        Yes
                      </Text>
                    </Form.Item>
                  </>
                ) : (
                  fieldFormData.weeds?.map((weed, index) => (
                    <div key={index}>
                      <Form.Item>
                        <Title
                          level={5}
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            marginBottom: "6px",
                            color: "#737373",
                          }}
                        >
                          Weed Name
                        </Title>
                        <Text className="font-semibold text-neutral-800">
                          {isWeeds || errorWeedsData
                            ? ""
                            : !weed.weed_name
                            ? "Unknown"
                            : weedsData?.map((weedType) =>
                                weedType.id === weed.weed_name
                                  ? weedType.weed_type
                                  : null
                              )}
                        </Text>
                      </Form.Item>
                      <Form.Item>
                        <Title
                          level={5}
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            marginBottom: "6px",
                            color: "#737373",
                          }}
                        >
                          Weed Density
                        </Title>
                        <Text className="font-semibold text-neutral-800">
                          {isWeedDensities || errorWeedDensitiesData
                            ? ""
                            : !weed.weed_density
                            ? "Unknown"
                            : weedDensitiesData?.map((density) =>
                                density.id === weed.weed_density
                                  ? density.weed_density
                                  : null
                              )}
                        </Text>
                      </Form.Item>
                      <Form.Item>
                        <Title
                          level={5}
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            marginBottom: "6px",
                            color: "#737373",
                          }}
                        >
                          Number of Leaves
                        </Title>
                        <Text className="font-semibold text-neutral-800">
                          {!weed.leaves_number ? "" : weed.leaves_number}
                        </Text>
                      </Form.Item>
                      <Form.Item>
                        <Title
                          level={5}
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            marginBottom: "6px",
                            color: "#737373",
                          }}
                        >
                          Height
                        </Title>
                        <Text className="font-semibold text-neutral-800">
                          {`${!weed.height ? "" : weed.height} ${
                            !weed.height ? "" : "CM"
                          }`}
                        </Text>
                      </Form.Item>
                      <Form.Item>
                        <Title
                          level={5}
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            marginBottom: "6px",
                            color: "#737373",
                          }}
                        >
                          Flowering
                        </Title>
                        <Text className="font-semibold text-neutral-800">
                          {weed.flowering ? "yes" : "Not yet"}
                        </Text>
                      </Form.Item>
                      {fieldFormData.weeds?.length > 0 ? (
                        index !== fieldFormData?.weeds.length - 1 ? (
                          <Divider />
                        ) : null
                      ) : null}
                    </div>
                  ))
                )}
              </Panel>
            </Collapse>
          </Form>
        )}
        {sideBarMode === "Farm" && (
          <Form layout="vertical">
            <Collapse
              expandIconPosition="end"
              className="mb-4"
            >
              <Panel
                header="Farm Detail"
                key="1"
                style={{ fontWeight: "600", fontSize: "16px" }}
              >
                <Form.Item>
                  <Title
                    level={5}
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      marginBottom: "6px",
                      color: "#737373",
                    }}
                  >
                    Farm Name
                  </Title>
                  <Text className="font-semibold text-neutral-800">
                    {!farmFormData.farm_name ? "" : farmFormData.farm_name}
                  </Text>
                </Form.Item>
                <Form.Item>
                  <Title
                    level={5}
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      marginBottom: "6px",
                      color: "#737373",
                    }}
                  >
                    Country
                  </Title>
                  <Text className="font-semibold text-neutral-800">
                    {!farmFormData.countryText
                      ? "Unknown"
                      : farmFormData.countryText}
                  </Text>
                </Form.Item>
                <Form.Item>
                  <Title
                    level={5}
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      marginBottom: "6px",
                      color: "#737373",
                    }}
                  >
                    Province/State
                  </Title>
                  <Text className="font-semibold text-neutral-800">
                    {!farmFormData.province_stateText
                      ? "Unknown"
                      : farmFormData.province_stateText}
                  </Text>
                </Form.Item>
                <Form.Item>
                  <Title
                    level={5}
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      marginBottom: "6px",
                      color: "#737373",
                    }}
                  >
                    City
                  </Title>
                  <Text className="font-semibold text-neutral-800">
                    {farmFormData.city === "unknown" || !farmFormData.city
                      ? "Unknown"
                      : farmFormData.city}
                  </Text>
                </Form.Item>
                <Form.Item>
                  <Title
                    level={5}
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      marginBottom: "6px",
                      color: "#737373",
                    }}
                  >
                    Municipal District (County)
                  </Title>
                  <Text className="font-semibold text-neutral-800">
                    {!farmFormData.countyText
                      ? "Unknown"
                      : farmFormData.countyText}
                  </Text>
                </Form.Item>
                <Form.Item>
                  <Title
                    level={5}
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      marginBottom: "6px",
                      color: "#737373",
                    }}
                  >
                    Planted Area
                  </Title>
                  <Text className="font-semibold text-neutral-800">
                    {` ${
                      !farmFormData.planted_area ||
                      farmFormData.planted_area === undefined
                        ? ""
                        : farmFormData.planted_area
                    } ${farmFormData.planted_area ? "Acres" : ""}`}
                  </Text>
                </Form.Item>
                <Form.Item>
                  <Title
                    level={5}
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      marginBottom: "6px",
                      color: "#737373",
                    }}
                  >
                    Postal Address
                  </Title>
                  <Text className="font-semibold text-neutral-800">
                    {!farmFormData.postal_address
                      ? ""
                      : farmFormData.postal_address}
                  </Text>
                </Form.Item>
              </Panel>
            </Collapse>
            <Collapse
              expandIconPosition="end"
              className="mb-4"
            >
              <Panel
                header="Legal Land Description"
                key="2"
                style={{ fontWeight: "600", fontSize: "16px" }}
              >
                <Form.Item>
                  <Title
                    level={5}
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      marginBottom: "6px",
                      color: "#737373",
                    }}
                  >
                    LLD
                  </Title>
                  <Text className="font-semibold text-neutral-800">
                    {farmFormData.lld === "0" ? "" : farmFormData.lld}
                  </Text>
                </Form.Item>
                <Form.Item>
                  <Title
                    level={5}
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      marginBottom: "6px",
                      color: "#737373",
                    }}
                  >
                    Quarter Section
                  </Title>
                  <Text className="font-semibold text-neutral-800">
                    {farmFormData.quarter_section === "0"
                      ? ""
                      : farmFormData.quarter_section}
                  </Text>
                </Form.Item>
                <Form.Item>
                  <Title
                    level={5}
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      marginBottom: "6px",
                      color: "#737373",
                    }}
                  >
                    Section
                  </Title>
                  <Text className="font-semibold text-neutral-800">
                    {farmFormData.section === "0" ? "" : farmFormData.section}
                  </Text>
                </Form.Item>
                <Form.Item>
                  <Title
                    level={5}
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      marginBottom: "6px",
                      color: "#737373",
                    }}
                  >
                    Township
                  </Title>
                  <Text className="font-semibold text-neutral-800">
                    {farmFormData.township === "0" ? "" : farmFormData.township}
                  </Text>
                </Form.Item>
                <Form.Item>
                  <Title
                    level={5}
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      marginBottom: "6px",
                      color: "#737373",
                    }}
                  >
                    Range
                  </Title>
                  <Text className="font-semibold text-neutral-800">
                    {farmFormData.range === "0" ? "" : farmFormData.range}
                  </Text>
                </Form.Item>
                <Form.Item>
                  <Title
                    level={5}
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      marginBottom: "6px",
                      color: "#737373",
                    }}
                  >
                    Meridian
                  </Title>
                  <Text className="font-semibold text-neutral-800">
                    {farmFormData.meridian === "0" ? "" : farmFormData.meridian}
                  </Text>
                </Form.Item>
              </Panel>
            </Collapse>
          </Form>
        )}
      </div>
    </Sider>
  );
}

export default RightSider;
