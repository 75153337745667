import { useMutation } from "react-query";
import { generalSendChat } from "../../Services/apiBloom";

export function useSendGeneralChat() {
  const {
    mutate: sendGeneralChatData,
    isLoading: isSendGeneralChatData,
    error: errorSendGeneralChatData,
  } = useMutation({
    mutationFn: generalSendChat,
  });

  return {
    sendGeneralChatData,
    isSendGeneralChatData,
    errorSendGeneralChatData,
  };
}
