function CounterCyrcle({ children, isActive }) {
  return (
    <span
      style={{ padding: "0px, 7px, 0px, 7px" }}
      className={`${
        isActive
          ? "bg-green-500 text-white "
          : " bg-white border-2 border-neutral-300 text-neutral-500 w-5 h-5"
      }w-5 h-5 rounded-full flex justify-center items-center mr-2 transition`}
    >
      {children}
    </span>
  );
}

export default CounterCyrcle;
