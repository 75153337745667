import { Button, Form, Input, Divider, Space } from "antd";
import { useAuth } from "../../Context/AuthProvider";
import {
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
} from "@ant-design/icons";
import { ReactComponent as ErrorIcon } from "../../assets/Vector2.svg";

function errorMessage(message) {
  return (
    <>
      <ErrorIcon className="inline-block mb-0.5.l;" />
      <span className="inline-block font-medium	pl-1">{message}</span>
    </>
  );
}

function RegisterFormStep3() {
  const {
    formData,
    handleContinue,
    current,
    handleRegisterInputChange,
    isAuthenticating,
  } = useAuth();

  return (
    <div
      className={` ${
        current === 2 ? "block" : "hidden"
      } register-form w-80 md:w-96 lg:w-96 shadow-md p-6	rounded-2xl border border-slate-200 bg-white`}
    >
      <Form
        name="register"
        initialValues={{
          remember: true,
        }}
      >
        <Form.Item
          name="firstName"
          rules={[
            {
              required: true,
              type: "string",
              message: errorMessage("Please enter your first name!"),
            },
          ]}
        >
          <Input
            id="registerFName"
            name="firstName"
            className="p-2.5 rounded-lg mb-2"
            prefix={<UserOutlined />}
            placeholder="First Name"
            onChange={handleRegisterInputChange}
            value={formData.firstName}
          />
        </Form.Item>
        <Form.Item
          name="lastName"
          rules={[
            {
              required: true,
              type: "string",
              message: errorMessage("Please enter your last name!"),
            },
          ]}
        >
          <Input
            id="registerLName"
            name="lastName"
            className="p-2.5 rounded-lg mb-2"
            prefix={<UserOutlined />}
            placeholder="Last Name"
            value={formData.lastName}
            onChange={handleRegisterInputChange}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              type: "string",
              message: errorMessage("Please enter your password!"),
            },
            {
              min: 8,
              message: errorMessage("8 Characher minimum!"),
            },
          ]}
        >
          <Input.Password
            id="registerPassword"
            name="password"
            className="p-2.5 rounded-lg mb-2"
            placeholder="Password"
            prefix={<LockOutlined />}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            value={formData.password}
            onChange={handleRegisterInputChange}
          />
        </Form.Item>
        <Form.Item>
          <Button
            className="px-4 py-5"
            block
            type="primary"
            htmlType="submit"
            onClick={handleContinue}
            disabled={isAuthenticating}
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default RegisterFormStep3;
