import AuthGrid from "../Ui/AuthGrid";
import BasicLayout from "../Ui/BasicLayout";
import LoginForm from "../Features/Authentication/LoginForm";
import { FloatButton } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';


function Login() {
  return (
    <BasicLayout>
      <FloatButton
      icon={<QuestionCircleOutlined />}
      type="default"
      />
      <AuthGrid>
        <LoginForm />
      </AuthGrid>
    </BasicLayout>
  );
}

export default Login;
