import { FloatButton } from "antd";
import BasicLayout from "../Ui/BasicLayout";
import AuthGrid from "../Ui/AuthGrid";
import { QuestionCircleOutlined } from "@ant-design/icons";
import ForgotpassForm from "../Features/Authentication/ForgotPassForm";

function ForgotPassword() {
  return (
    <BasicLayout>
      <FloatButton icon={<QuestionCircleOutlined />} type="default" />
      <AuthGrid>
        <ForgotpassForm />
      </AuthGrid>
    </BasicLayout>
  );
}

export default ForgotPassword;
