import { useMutation } from "react-query";
import { editField } from "../../Services/apiBloom";

export function useEditField() {
  const {
    mutate: editFieldData,
    isLoading: isEditFieldData,
    error: errorEditFieldData,
  } = useMutation({
    mutationFn: editField,
  });

  return {
    editFieldData,
    isEditFieldData,
    errorEditFieldData,
  };
}
