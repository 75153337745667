import { useMutation } from "react-query";
import { getCrop } from "../../Services/apiBloom";

export function useCrops() {
  const { mutate: cropData, isLoading: isCropData } = useMutation({
    mutationFn: getCrop,
    onError: () => {
      return null;
    },
  });

  return {
    cropData,
    isCropData,
  };
}
