import { useChat } from "../Context/ChatProvider";
import React, { useEffect, useState } from "react";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  Farm,
  SquareDashed,
  CheckCircle,
  XmarkCircle,
  Plus,
} from "iconoir-react";
import {
  Layout,
  Menu,
  Button,
  Form,
  Select,
  Input,
  InputNumber,
  Collapse,
  Switch,
  Typography,
  Divider,
  Row,
  Col,
  Modal,
  AutoComplete,
  TreeSelect,
  message,
} from "antd";
import { useCountries } from "../Features/Chat/useCountries";
import { useSoilTexture } from "../Features/Chat/useSoilTypes";
import { useIrregationTypes } from "../Features/Chat/useIrregationTypes";
import { useCropGroups } from "../Features/Chat/useCropGroups";
import { useCrops } from "../Features/Chat/useCrops";
import { useWeeds } from "../Features/Chat/useWeeds";
import { useWeeedDensities } from "../Features/Chat/useWeedDensities";
import { useCities } from "../Features/Chat/useCities";
import { useProvinces } from "../Features/Chat/useProvinces";
import { useCounties } from "../Features/Chat/useCounties";
import { useEditFarm } from "../Features/Chat/useEditFarm";
import { useEditField } from "../Features/Chat/useEditField";
import { useCreateField } from "../Features/Chat/useCreateField";
import { useFarmField } from "../Features/Chat/useFarmField";
import { useFarm } from "../Features/Chat/useFarm";
import { useFarmFields } from "../Features/Chat/useFarmFields";
import { useNavigate } from "react-router-dom";
import { usePrecisionSessions } from "../Features/Chat/usePrecisionSessions";
import { usePrecisionHostSendChat } from "../Features/Chat/usePrecisionHostSendChat";
import { useSendPrecisionChat } from "../Features/Chat/useSendPrecisionChat";

const { Text, Title } = Typography;
const { Panel } = Collapse;
const { Content, Sider } = Layout;

function ChatModal({ open, handleOk, handleCancel }) {
  const {
    sideBarMode,
    dispatch,
    handleFarmInputChange,
    updateWeed,
    handleFieldInputChange,
    farmFormData,
    currentFarm,
    currentField,
    fieldFormData,
    season,
    year,
    farmFields,
    farms,
    backUpFieldData,
  } = useChat();

  const { farmFieldData, isFarmFieldData, errorFarmFieldData } = useFarmField();
  const { farmData, isFarmData, errorFarmData } = useFarm();
  const { farmFieldsData, isFarmFieldsData, errorFarmFieldsData } =
    useFarmFields();

  const navigate = useNavigate();

  let stringLog = "";

  function compareObjects(obj1, obj2) {
    // Helper function to compare each field
    function compareField(key, value1, value2) {
      if (
        typeof value1 === "object" &&
        value1 !== null &&
        Array.isArray(value1)
      ) {
        // Handling arrays (weeds in this case) and only logging if there are differences
        if (compareArrays(key, value1, value2)) {
          stringLog += `The ${key} array has changed.\n`;
        }
      } else if (value1 !== value2) {
        stringLog += `The ${key} was changed from ${value1} to ${value2}.\n`;
      }
    }

    // Helper function to compare arrays
    function compareArrays(arrayKey, arr1, arr2) {
      let hasChanges = false;
      arr1.forEach((item, index) => {
        const item2 = arr2[index];
        if (item2) {
          // Compare each property in the array's objects
          for (let key in item) {
            if (item[key] !== item2[key]) {
              hasChanges = true;
              stringLog += `In ${arrayKey}, item ${
                index + 1
              }: ${key} changed from ${item[key]} to ${item2[key]}.\n`;
            }
          }
        } else {
          hasChanges = true;
          stringLog += `In ${arrayKey}, item ${index + 1} was removed.\n`;
        }
      });

      // Check if there are extra items in the second array
      if (arr2.length > arr1.length) {
        hasChanges = true;
        arr2.slice(arr1.length).forEach((item, index) => {
          stringLog += `In ${arrayKey}, a new item was added: ${JSON.stringify(
            item
          )}.\n`;
        });
      }

      return hasChanges;
    }

    // Main comparison loop
    for (let key in obj1) {
      if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
        compareField(key, obj1[key], obj2[key]);
      }
    }

    // Check for any fields present in obj2 but missing in obj1
    for (let key in obj2) {
      if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
        stringLog += `The field ${key} was added with value ${obj2[key]}.\n`;
      }
    }

    return stringLog || "";
  }

  function handleDiscard() {
    if (sideBarMode === "Farm") {
      const farm = farms.filter((farm) => farm.id === currentFarm)[0];

      farmData(
        { currentFarm },
        {
          onSuccess: (data) => {
            handleFarmInputChange(
              "country",
              !data.municipal_district
                ? 0
                : data.municipal_district.province.country.id
            );
            handleFarmInputChange(
              "countryText",
              !data.municipal_district
                ? ""
                : data.municipal_district.province.country.country_name
            );
            handleFarmInputChange(
              "province_state",
              !data.municipal_district ? 0 : data.municipal_district.province.id
            );
            handleFarmInputChange(
              "province_stateText",
              !data.municipal_district
                ? ""
                : data.municipal_district.province.name
            );
            handleFarmInputChange(
              "county",
              !data.municipal_district ? 0 : data.municipal_district.id
            );
            handleFarmInputChange(
              "countyText",
              !data.municipal_district.name ? "" : data.municipal_district.name
            );
          },
        }
      );

      handleFarmInputChange("farm_name", farm.farm_name);
      handleFarmInputChange("planted_area", farm.farm_area_in_acre);
      handleFarmInputChange("lld", farm.lld);
      handleFarmInputChange("meridian", farm.meridian);
      handleFarmInputChange("postal_address", farm.postal_address);
      handleFarmInputChange("quarter_section", farm.postal_address);
      handleFarmInputChange("section", farm.section);
      handleFarmInputChange("township", farm.township);
      handleFarmInputChange("range", farm.farm_range);

      handleCancel();
    } else {
      farmFieldData(
        { farm_field_id: currentField, year: year, season: season },
        {
          onSuccess: (data) => {
            handleFieldInputChange(
              "field_name",
              data.data.field_name ? data.data.field_name : 0
            );
            handleFieldInputChange(
              "soil_texture",
              data.data.soil_type ? data.data.soil_type : 0
            );
            handleFieldInputChange(
              "irregation_type",
              data.data.irrigation_type ? data.data.irrigation_type : 0
            );
            handleFieldInputChange("planted_area", data.data.area_in_acre);
            handleFieldInputChange(
              "approximate_crop_height",
              data.data.approximate_crop_height
            );
            handleFieldInputChange(
              "crop_name",
              data.data.farm_field_crops.crop
                ? data.data.farm_field_crops.crop
                : 0
            );
            handleFieldInputChange(
              "crop_stage",
              data.data.farm_field_crops.crop_stage
                ? data.data.farm_field_crops.crop_stage
                : 0
            );
            handleFieldInputChange("farm", data.data.farm ? data.data.farm : 0);
            handleFieldInputChange(
              "weeds",
              data.data.farm_field_crops.farm_field_weeds
                ? data.data.farm_field_crops.farm_field_weeds?.map((weed) => ({
                    weed_name: weed.weed,
                    weed_density: weed.weed_density,
                    leaves_number: weed.weed_stage.number_of_leaves,
                    height: weed.weed_stage.heihgt_in_cm,
                    flowering: weed.weed_stage.flowering,
                  }))
                : []
            );
            dispatch({ type: "getBackUpFieldData", payload: {} });

            handleCancel();
          },
          onError: () =>
            message.error(
              "Something went wrong while discarding the from, Please try again!"
            ),
        }
      );
    }
  }
  const [showCropGoups, setShowCropGroups] = useState(true);
  let errorShown = false;

  const {
    precisionSessionsData,
    isPrecisionSessionsData,
    erroPrecisionSessionsData,
  } = usePrecisionSessions();

  const { sendPrecisionData, isSendPrecisionData, errorSendPrecisionData } =
    useSendPrecisionChat();

  const { editFarmData, isEditFarmData, errorEditFarmData } = useEditFarm();
  const { editFieldData, isEditFieldData, errorEditFieldData } = useEditField();

  const { soilTextureData, isSoilTextureData, errorSoilTextureData } =
    useSoilTexture();
  const {
    irregationTypesData,
    isIrregationTypesData,
    errorIrregationTypesData,
  } = useIrregationTypes();
  const { cropGroupsData, isCropGroups, errorCropGroups } = useCropGroups();

  const { cropData, isCropData } = useCrops();

  const { weedsData, isWeeds, errorWeedsData } = useWeeds();

  //weeds densities data set

  const { weedDensitiesData, isWeedDensities, errorWeedDensitiesData } =
    useWeeedDensities();

  const {
    sendHostPrecisionChatData,
    isHostSendPrecisionChatData,
    errorHostSendPrecisionChatData,
  } = usePrecisionHostSendChat();

  function findCropById(id) {
    // Recursive function to search for the crop by id
    const search = (crops) => {
      if (!isCropGroups && !errorCropGroups) {
        for (let category of crops) {
          // Iterate through the crops array
          for (let crop of category.crops) {
            if (crop.id === id) {
              return crop.crop_name; // Return the crop_name if id matches
            }

            // If there are children, search recursively
            if (crop.children.length > 0) {
              const result = search([{ crops: crop.children }]);
              if (result) return result; // Return if found in children
            }
          }
        }
      }
      return null; // Return null if not found
    };

    return search(cropGroupsData);
  }

  const [textFieldFormData, setTextFieldFormData] = useState({});

  function handleSave() {
    if (sideBarMode === "Farm") {
      editFarmData(
        {
          farm_id: currentFarm,
          data: {
            farm_name: farmFormData.farm_name,
            country: !farmFormData.country ? null : farmFormData.country,
            province_state: !farmFormData.province_state
              ? null
              : farmFormData.province_state,
            municipal_district: !farmFormData.county
              ? null
              : farmFormData.county,
            farm_area_in_acre: farmFormData.planted_area,
            farm_address: "address",
            farm_city: farmFormData.city,
            postal_address: farmFormData.postal_address,
            lld: farmFormData.lld,
            quarter_section: farmFormData.quarter_section,
            section: farmFormData.section,
            township: farmFormData.township,
            farm_range: farmFormData.range,
            meridian: farmFormData.meridian,
          },
        },
        {
          onSuccess: (data) => {
            farmData(
              { farm_id: data.id },
              {
                onSuccess: (data) => {
                  handleFarmInputChange(
                    "country",
                    !data.municipal_district
                      ? 0
                      : data.municipal_district.province.country.id
                  );
                  handleFarmInputChange(
                    "countryText",
                    !data.municipal_district
                      ? ""
                      : data.municipal_district.province.country.country_name
                  );
                  handleFarmInputChange(
                    "province_state",
                    !data.municipal_district
                      ? 0
                      : data.municipal_district.province.id
                  );
                  handleFarmInputChange(
                    "province_stateText",
                    !data.municipal_district
                      ? ""
                      : data.municipal_district.province.name
                  );
                  handleFarmInputChange(
                    "county",
                    !data.municipal_district ? 0 : data.municipal_district.id
                  );
                  handleFarmInputChange(
                    "countyText",
                    !data.municipal_district ? "" : data.municipal_district.name
                  );
                },
              }
            );

            dispatch({
              type: "updateFarms",
              payload: { id: data.id, data: data },
            });
            handleOk();
            message.success("Your Farm has been updated");
          },
          onError: () =>
            message.error(
              "Something went wrong while editing the farm! Please try again!"
            ),
        }
      );
    } else {
      editFieldData(
        {
          farm_field_id: currentField,
          data: {
            farm: currentFarm,
            field_name: fieldFormData.field_name,
            soil_type: fieldFormData.soil_texture
              ? fieldFormData.soil_texture
              : null,
            irrigation_type: fieldFormData.irregation_type
              ? fieldFormData.irregation_type
              : null,
            area_in_acre: fieldFormData.planted_area,
            approximate_crop_height: fieldFormData.approximate_crop_height,
            farm_field_crops: {
              year: year,
              season: season,
              crop: fieldFormData.crop_name ? (fieldFormData.crop_name == "unknown" ? null : fieldFormData.crop_name ) : null,
              crop_stage: fieldFormData.crop_stage
                ? fieldFormData.crop_stage
                : null,
            },
            farm_field_weeds: !fieldFormData.weeds
              ? []
              : (() => {
                  if (
                    fieldFormData.weeds.some(
                      (weed) => !weed.weed_name || !weed.weed_density
                    )
                  ) {
                    if (!errorShown) {
                      message.error("Your weeds should have name and density!");
                      errorShown = true; // Set the flag to true to prevent showing the error again
                    }
                  } else {
                    errorShown = false; // Reset the flag if no error is detected
                    return fieldFormData.weeds?.map((weed) => ({
                      weed: weed.weed_name,
                      weed_density: weed.weed_density,
                      weed_stage: {
                        number_of_leaves: weed.leaves_number,
                        heihgt_in_cm: weed.height,
                        flowering: weed.flowering,
                      },
                    }));
                  }
                })(),
          },
        },
        {
          onSuccess: (data) => {
            if (!fieldFormData.crop_name && !fieldFormData.crop_stage) {
              setTextFieldFormData({
                farm: fieldFormData.farm,
                field_name: fieldFormData.field_name,
                soil_texture: soilTextureData?.find(
                  (soil) => soil.id === data.data.soil_type
                )
                  ? soilTextureData?.find(
                      (soil) => soil.id === data.data.soil_type
                    )?.type_name
                  : 0,
                irregation_type: irregationTypesData?.find(
                  (irregation) => irregation.id === data.data.irrigation_type
                )
                  ? irregationTypesData?.find(
                      (irregation) =>
                        irregation.id === data.data.irrigation_type
                    )?.type_name
                  : 0,
                planted_area: fieldFormData.planted_area,
                approximate_crop_height: fieldFormData.approximate_crop_height,
                crop_name: "",
                crop_stage: "",
                weeds: fieldFormData.weeds
                  ? fieldFormData.weeds?.map((weed) => ({
                      weed_name: weedsData?.find(
                        (secWeed) => secWeed.id === weed?.weed_name
                      )?.weed_type
                        ? weedsData?.find(
                            (secWeed) => secWeed.id === weed?.weed_name
                          )?.weed_type
                        : 0,
                      weed_density: weedDensitiesData?.find(
                        (density) => density.id === weed.weed_density
                      )
                        ? weedDensitiesData?.find(
                            (density) => density.id === weed.weed_density
                          )?.weed_density
                        : 0,
                      leaves_number: weed.leaves_number,
                      height: weed.height,
                      flowering: weed.flowering,
                    }))
                  : [],
              });

              farmFieldData(
                {
                  farm_field_id: data.data.id,
                  year: year,
                  season: season,
                },
                {
                  onSuccess: (data) => {
                    if (data.data.farm_field_crops.id) {
                      handleFieldInputChange(
                        "id",
                        data.data.farm_field_crops.id
                      );
                    }
                  },
                }
              );

              farmFieldsData(
                { farm_id: currentFarm, season: season, year: year },
                {
                  onSuccess: (data) => {
                    dispatch({ type: "getFarmFields", payload: data.fields });
                  },
                }
              );

              handleOk();
              message.success("Your Field has been updated");
            }

            if (fieldFormData.crop_name && !fieldFormData.crop_stage) {
              setTextFieldFormData({
                farm: fieldFormData.farm,
                field_name: fieldFormData.field_name,
                soil_texture: soilTextureData?.find(
                  (soil) => soil.id === data.data.soil_type
                )
                  ? soilTextureData?.find(
                      (soil) => soil.id === data.data.soil_type
                    )?.type_name
                  : 0,
                irregation_type: irregationTypesData?.find(
                  (irregation) => irregation.id === data.data.irrigation_type
                )
                  ? irregationTypesData?.find(
                      (irregation) =>
                        irregation.id === data.data.irrigation_type
                    )?.type_name
                  : 0,
                planted_area: fieldFormData.planted_area,
                approximate_crop_height: fieldFormData.approximate_crop_height,
                crop_name: findCropById(fieldFormData.crop_name),
                crop_stage: "",
                weeds: fieldFormData.weeds
                  ? fieldFormData.weeds?.map((weed) => ({
                      weed_name: weedsData?.find(
                        (secWeed) => secWeed.id === weed?.weed_name
                      )?.weed_type
                        ? weedsData?.find(
                            (secWeed) => secWeed.id === weed?.weed_name
                          )?.weed_type
                        : 0,
                      weed_density: weedDensitiesData?.find(
                        (density) => density.id === weed?.weed_density
                      )
                        ? weedDensitiesData?.find(
                            (density) => density.id === weed?.weed_density
                          )?.weed_density
                        : 0,
                      leaves_number: weed.leaves_number,
                      height: weed.height,
                      flowering: weed.flowering,
                    }))
                  : [],
              });

              farmFieldData(
                {
                  farm_field_id: data.data.id,
                  year: year,
                  season: season,
                },
                {
                  onSuccess: (data) => {
                    if (data.data.farm_field_crops.id) {
                      handleFieldInputChange(
                        "id",
                        data.data.farm_field_crops.id
                      );
                    }
                  },
                }
              );

              farmFieldsData(
                { farm_id: currentFarm, season: season, year: year },
                {
                  onSuccess: (data) => {
                    dispatch({ type: "getFarmFields", payload: data.fields });
                  },
                }
              );

              handleOk();
              message.success("Your Field has been updated");
            }

            if (fieldFormData.crop_name && fieldFormData.crop_stage) {
              cropData(
                { crop_id: fieldFormData?.crop_name },
                {
                  onSuccess: (cropData) => {
                    setTextFieldFormData({
                      farm: fieldFormData.farm,
                      field_name: fieldFormData.field_name,
                      soil_texture: soilTextureData?.find(
                        (soil) => soil.id === data.data.soil_type
                      )
                        ? soilTextureData?.find(
                            (soil) => soil.id === data.data.soil_type
                          )?.type_name
                        : 0,
                      irregation_type: irregationTypesData?.find(
                        (irregation) =>
                          irregation.id === data.data.irrigation_type
                      )
                        ? irregationTypesData?.find(
                            (irregation) =>
                              irregation.id === data.data.irrigation_type
                          )?.type_name
                        : 0,
                      planted_area: fieldFormData.planted_area,
                      approximate_crop_height:
                        fieldFormData.approximate_crop_height,
                      crop_name: findCropById(fieldFormData.crop_name),
                      crop_stage: !cropData.crop_stages
                        ? null
                        : cropData.crop_stages.find(
                            (stage) => stage.id === fieldFormData.crop_stage
                          )?.stage_name,
                      weeds: fieldFormData.weeds
                        ? fieldFormData.weeds?.map((weed) => ({
                            weed_name: weedsData?.find(
                              (secWeed) => secWeed.id === weed?.weed_name
                            )?.weed_type
                              ? weedsData?.find(
                                  (secWeed) => secWeed.id === weed?.weed_name
                                )?.weed_type
                              : 0,
                            weed_density: weedDensitiesData?.find(
                              (density) => density.id === weed.weed_density
                            )
                              ? weedDensitiesData?.find(
                                  (density) => density.id === weed.weed_density
                                )?.weed_density
                              : 0,
                            leaves_number: weed.leaves_number,
                            height: weed.height,
                            flowering: weed.flowering,
                          }))
                        : [],
                    });

                    farmFieldData(
                      {
                        farm_field_id: data.data.id,
                        year: year,
                        season: season,
                      },
                      {
                        onSuccess: (data) => {
                          if (data.data.farm_field_crops.id) {
                            handleFieldInputChange(
                              "id",
                              data.data.farm_field_crops.id
                            );
                          }
                        },
                      }
                    );

                    farmFieldsData(
                      { farm_id: currentFarm, season: season, year: year },
                      {
                        onSuccess: (data) => {
                          dispatch({
                            type: "getFarmFields",
                            payload: data.fields,
                          });
                        },
                      }
                    );

                    handleOk();
                    message.success("Your Field has been updated");
                  },
                },
                // {
                //   onError: (error) => console.error(error),
                // }
              );
            }
          },
          onError: () =>
            message.error(
              "Something went wrong while editing the field, Please try again!"
            ),
        }
      );
    }
  }

  useEffect(() => {
    const isSessions = farmFields.find(
      (field) => field.id === currentField
    )?.sessions;

    if (backUpFieldData && textFieldFormData && isSessions?.length !== 0) {
      const string = compareObjects(backUpFieldData, textFieldFormData);

      dispatch({
        type: "getStringLog",
        payload: string,
      });

      dispatch({ type: "getBackUpFieldData", payload: {} });
    } else {
      dispatch({
        type: "getStringLog",
        payload: "",
      });

      dispatch({ type: "getBackUpFieldData", payload: {} });
    }
  }, [textFieldFormData]);

  //const weeds = fieldFormData.weeds;
  const [weeds, setWeeds] = useState([]);

  useEffect(() => {
    setWeeds(fieldFormData.weeds);
  }, [fieldFormData]);

  //Country data set
  const { countriesData, isCountries, errorCountries } = useCountries();

  const [countryOptoins, setCountryOptions] = useState([]);

  //soil texture data set

  function handleSelectSoilTexture(value) {
    handleFieldInputChange("soil_texture", value);
  }
  //irregation data set

  function handleSelectIrregationType(value) {
    handleFieldInputChange("irregation_type", value);
  }

  //Crop Groups data set
  const [stageOptions, setStageOptions] = useState(null);
  const [treeSelectValue, setTreeSelectValue] = useState(null);
  const [stageValue, setStageValue] = useState("");

  useEffect(() => {
    if (fieldFormData.crop_name) {
      setTreeSelectValue(fieldFormData.crop_name);

      cropData(
        { crop_id: fieldFormData.crop_name },
        {
          onSuccess: (data) => {
            console.log(data)
            setStageOptions(data.crop_stages);
            setStageValue(fieldFormData.crop_stage === 0 ? "Unknown" : fieldFormData.crop_stage);
          },
        }
      );
    } else {
      setStageValue("");
      setTreeSelectValue(null);
      setStageOptions(null);
    }
  }, [cropData, fieldFormData]);

  const treeSelectData = cropGroupsData?.map((group) => ({
    title: group.name,
    value: group.name,
    key: group.name,
    selectable: false,
    children: group.crops.map((crop) => {
      if (crop.children.length === 0) {
        return {
          title: crop.crop_name,
          value: crop.id,
          key: crop.id,
        };
      } else {
        return {
          title: crop.crop_name,
          value: crop.id,
          selectable: false,

          children: crop.children?.map((subCrop) => {
            if (subCrop.children.length === 0) {
              return {
                title: subCrop.crop_name,
                value: subCrop.id,
                key: subCrop.id,
              };
            } else {
              return {
                title: subCrop.crop_name,
                value: subCrop.id,
                key: subCrop.id,
                selectable: false,
              };
            }
          }),
          key: crop.id,
        };
      }
    }),
  }));

  const onTreeSelectChange = (value) => {
    
    setTreeSelectValue(value);
    handleFieldInputChange("crop_name", value);
    setValueGroupCrop(value);

    if (value === "unknown") {
      console.log("Unknown selected - resetting stage options");
      setStageOptions([]); // Reset crop stages
      return; // Skip the cropData API call
    }

    cropData(
      { crop_id: value },
      {
        onSuccess: (data) => {
          setStageOptions(data.crop_stages);
        },
        onError: () => {
          setStageOptions([])
        },
      }
    );
  };

  const [valueGroupCrop, setValueGroupCrop] = useState(null);

  const filterTreeNode = (inputValue, treeNode) => {
    return treeNode.title?.toLowerCase().includes(inputValue.toLowerCase()) ||
      treeNode.label?.toLowerCase().includes(inputValue.toLowerCase());

    // return treeNode.label?.toLowerCase().includes(inputValue.toLowerCase());
  };

  //Crop stages data set

  const onChangeGroupsCrop = (newValue) => {
    handleFieldInputChange("crop_name", newValue);
    setValueGroupCrop(newValue);
    cropData(
      { crop_id: newValue },
      {
        onSuccess: (data) => {
          setStageOptions(data.crop_stages);
        },
        onError: () => {
          return null;
        },
      }
    );
  };

  const onChangeCropStage = (value) => {
    handleFieldInputChange("crop_stage", value);
  };

  const onSelectCropStage = (value, option) => {
    setStageValue(option.label);
  };

  //weeds data set

  //cities/Provinve/states data set

  const { citiesData, isCitiesData, errorCities } = useCities();

  const [citiesDataOptions, setCitiesDataOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);

  const { provinceData, isProvinceData, errorProvinceData } = useProvinces();

  const [provinceStateDataOptions, setProvinceStateDataOptions] = useState([]);
  const [provinceStateOptions, setProvinceStateOptions] = useState([]);

  function onSelectCountry(value, option) {
    handleFarmInputChange("country", value);
    handleFarmInputChange("countryText", option.label);

    provinceData(
      { country_id: value },
      {
        onSuccess: (data) => {
          setProvinceStateDataOptions(data.provinces);
        },
        onError: () => {
          setProvinceStateDataOptions([]);
        },
      }
    );

    citiesData(
      { country: option.label },
      {
        onSuccess: (data) => {
          setCitiesDataOptions(data.data);
        },
        onError: () => {
          setCitiesDataOptions([]);
        },
      }
    );
  }

  function onCountryChange(value) {
    handleFarmInputChange("countryText", value);
  }

  const { countyData, isCountyData, errorCountyData } = useCounties();
  const [countyOptions, setCountyOptions] = useState([]);

  function onSelectProvinceState(value, option) {
    handleFarmInputChange("province_stateText", option.label);
    handleFarmInputChange("province_state", value);

    if (value) {
      countyData(
        { province_id: value },
        {
          onSuccess: (data) => {
            setCountyOptions(data?.counties);
          },
          onError: () => {
            setCountyOptions([]);
          },
        }
      );
    }
  }

  useEffect(() => {
    if (!farmFieldData.county) {
      setCountyOptions([]);
    }
  }, [farmFieldData.county]);

  function onProvinceStateChange(value) {
    handleFarmInputChange("province_stateText", value);
  }

  function onCityChange(value) {
    handleFarmInputChange("city", value);
  }

  const [countyValue, setCountyValue] = useState(farmFormData.countyText);

  function onSelectCounty(value, option) {
    handleFarmInputChange("county", value);
    setCountyValue(option.label);
  }

  function onCountyChange(value) {
    handleFarmInputChange("county", value);
    setCountyValue(value);
  }

  function ModalBtn({ text, icon, handleClick }) {
    return (
      <button onClick={!handleClick ? null : handleClick}>
        <div className="flex gap-2">
          {icon}
          <p>{text}</p>
        </div>
      </button>
    );
  }

  const handleModalMenuClick = ({ key }) => {
    if (key === "Field") {
      farmFieldData(
        {
          farm_field_id: currentField,
          year: year,
          season: season,
        },
        {
          onSuccess: (data) => {

            if (
              !data.data.farm_field_crops?.crop &&
              !data.data.farm_field_crops?.crop_stage
            ) {
              dispatch({
                type: "getBackUpFieldData",
                payload: {
                  farm: currentFarm,
                  field_name: data.data.field_name,
                  soil_texture: soilTextureData?.find(
                    (soil) => soil.id === data.data.soil_type
                  )
                    ? soilTextureData?.find(
                        (soil) => soil.id === data.data.soil_type
                      )?.type_name
                    : 0,
                  irregation_type: irregationTypesData?.find(
                    (irregation) => irregation.id === data.data.irrigation_type
                  )
                    ? irregationTypesData?.find(
                        (irregation) =>
                          irregation.id === data.data.irrigation_type
                      )?.type_name
                    : 0,
                  planted_area: data?.data.area_in_acre,
                  approximate_crop_height: data.data?.approximate_crop_height,
                  crop_name: "",
                  crop_stage: "",
                  weeds: data.data?.farm_field_crops?.farm_field_weeds
                    ? data.data.farm_field_crops.farm_field_weeds?.map(
                        (weed) => ({
                          weed_name: weedsData?.find(
                            (secWeed) => secWeed.id === weed.weed
                          )
                            ? weedsData?.find(
                                (secWeed) => secWeed.id === weed.weed
                              )?.weed_type
                            : 0,
                          weed_density: weedDensitiesData?.find(
                            (density) => density.id === weed.weed_density
                          )
                            ? weedDensitiesData?.find(
                                (density) => density.id === weed.weed_density
                              )?.weed_density
                            : 0,
                          leaves_number: weed.weed_stage.number_of_leaves,
                          height: weed.weed_stage.heihgt_in_cm,
                          flowering: weed.weed_stage.flowering,
                        })
                      )
                    : [],
                  id: 0,
                },
              });
              dispatch({ type: "selectSideBarMode", payload: key });
            }

            if (
              data.data.farm_field_crops.crop &&
              !data.data.farm_field_crops.crop_stage
            ) {
              dispatch({
                type: "getBackUpFieldData",
                payload: {
                  farm: currentFarm,
                  field_name: data.data.field_name,
                  soil_texture: soilTextureData?.find(
                    (soil) => soil.id === data.data.soil_type
                  )
                    ? soilTextureData?.find(
                        (soil) => soil.id === data.data.soil_type
                      )?.type_name
                    : 0,
                  irregation_type: irregationTypesData?.find(
                    (irregation) => irregation.id === data.data.irrigation_type
                  )
                    ? irregationTypesData?.find(
                        (irregation) =>
                          irregation.id === data.data.irrigation_type
                      )?.type_name
                    : 0,
                  planted_area: data?.data.area_in_acre,
                  approximate_crop_height: data.data?.approximate_crop_height,
                  crop_name: findCropById(data.data.farm_field_crops?.crop),
                  crop_stage: "",
                  weeds: data.data?.farm_field_crops?.farm_field_weeds
                    ? data.data.farm_field_crops.farm_field_weeds?.map(
                        (weed) => ({
                          weed_name: weedsData?.find(
                            (secWeed) => secWeed.id === weed.weed
                          )
                            ? weedsData?.find(
                                (secWeed) => secWeed.id === weed.weed
                              )?.weed_type
                            : 0,
                          weed_density: weedDensitiesData?.find(
                            (density) => density.id === weed.weed_density
                          )
                            ? weedDensitiesData?.find(
                                (density) => density.id === weed.weed_density
                              )?.weed_density
                            : 0,
                          leaves_number: weed.weed_stage.number_of_leaves,
                          height: weed.weed_stage.heihgt_in_cm,
                          flowering: weed.weed_stage.flowering,
                        })
                      )
                    : [],
                  id: data.data.farm_field_crops?.id,
                },
              });
              dispatch({ type: "selectSideBarMode", payload: key });
            }

            if (
              data.data.farm_field_crops.crop &&
              data.data.farm_field_crops.crop_stage
            ) {
              cropData(
                { crop_id: data.data.farm_field_crops?.crop },
                {
                  onSuccess: (seccondData) => {
                    dispatch({
                      type: "getBackUpFieldData",
                      payload: {
                        farm: currentFarm,
                        field_name: data.data.field_name,
                        soil_texture: soilTextureData?.find(
                          (soil) => soil.id === data.data.soil_type
                        )
                          ? soilTextureData?.find(
                              (soil) => soil.id === data.data.soil_type
                            )?.type_name
                          : 0,
                        irregation_type: irregationTypesData?.find(
                          (irregation) =>
                            irregation.id === data.data.irrigation_type
                        )
                          ? irregationTypesData?.find(
                              (irregation) =>
                                irregation.id === data.data.irrigation_type
                            )?.type_name
                          : 0,
                        planted_area: data?.data.area_in_acre,
                        approximate_crop_height:
                          data.data?.approximate_crop_height,
                        crop_name: findCropById(
                          data.data.farm_field_crops?.crop
                        ),
                        crop_stage: !seccondData.crop_stages
                          ? null
                          : seccondData.crop_stages.find(
                              (stage) =>
                                stage.id ===
                                data.data.farm_field_crops?.crop_stage
                            )?.stage_name,
                        weeds: data.data?.farm_field_crops?.farm_field_weeds
                          ? data.data.farm_field_crops.farm_field_weeds?.map(
                              (weed) => ({
                                weed_name: weedsData?.find(
                                  (secWeed) => secWeed.id === weed.weed
                                )
                                  ? weedsData?.find(
                                      (secWeed) => secWeed.id === weed.weed
                                    )?.weed_type
                                  : 0,
                                weed_density: weedDensitiesData?.find(
                                  (density) => density.id === weed.weed_density
                                )
                                  ? weedDensitiesData?.find(
                                      (density) =>
                                        density.id === weed.weed_density
                                    )?.weed_density
                                  : 0,
                                leaves_number: weed.weed_stage.number_of_leaves,
                                height: weed.weed_stage.heihgt_in_cm,
                                flowering: weed.weed_stage.flowering,
                              })
                            )
                          : [],
                        id: data.data.farm_field_crops?.id,
                      },
                    });

                    dispatch({ type: "selectSideBarMode", payload: key });
                  },
                  onError: () => {
                    return null;
                  },
                }
              );
            }
          },
        }
      );
    } else {
      dispatch({
        type: "getBackUpFieldData",
        payload: {},
      });

      dispatch({ type: "selectSideBarMode", payload: key });
    }
  };

  //adding field

  const { createFieldData, isCreateFieldData, errorCreateFieldData } =
    useCreateField();

  useEffect(() => {
    if (farmFormData.country && farmFormData.countryText) {
      setCountryOptions(() => {
        if (!farmFormData.countryText) return [];
        return countriesData
          ?.filter(
            (country) =>
              country.country_name
                ?.toUpperCase()
                .indexOf(farmFormData.countryText.toUpperCase()) !== -1
          )
          ?.map((country) => ({
            value: country.id,
            label: country.country_name,
          }));
      });

      provinceData(
        { country_id: farmFormData.country },
        {
          onSuccess: (data) => {
            setProvinceStateDataOptions(data.provinces);
          },
          onError: () => {
            setProvinceStateDataOptions([]);
          },
        }
      );

      citiesData(
        { country: farmFormData.countryText },
        {
          onSuccess: (data) => {
            setCitiesDataOptions(data.data);
          },
          onError: () => {
            setCitiesDataOptions([]);
          },
        }
      );
    }
  }, [farmFormData.country, farmFormData.countryText]);

  useEffect(() => {
    if (farmFormData.province_state && farmFormData.province_stateText) {
      setProvinceStateOptions(() => {
        if (!farmFormData.province_stateText) return [];

        return provinceStateDataOptions
          .filter(
            (provinceState) =>
              provinceState.name
                ?.toUpperCase()
                .indexOf(farmFormData.province_stateText.toUpperCase()) !== -1
          )
          ?.map((provinceState) => ({
            value: provinceState.id,
            label: provinceState.name,
          }));
      });

      countyData(
        { province_id: farmFormData.province_state },
        {
          onSuccess: (data) => {
            setCountyOptions(data?.counties);
          },
          onError: () => {
            setCountyOptions([]);
          },
        }
      );
    }
  }, [farmFormData.province_state, farmFormData.province_stateText]);

  useEffect(() => {
    if (farmFormData.county && farmFormData.countyText) {
      setCountyValue(farmFormData.countyText);
    }
  }, [farmFormData.county && farmFormData.countyText]);

  //switching between fields

  // Content based on selected menu item
  // console.log(fieldFormData.crop_name)

  return (
    <Modal
      centered
      open={open}
      onOk={handleSave}
      onCancel={handleCancel}
      width={900}
      okText={
        <ModalBtn
          text={"Save"}
          icon={<CheckCircle />}
          handleClick={handleSave}
        />
      }
      cancelText={
        <ModalBtn
          text={"Discard"}
          icon={<XmarkCircle />}
          handleClick={handleDiscard}
        />
      }
      okButtonProps={{
        className: "py-6",
      }}
      cancelButtonProps={{
        className: "py-6",
      }}
    >
      {sideBarMode === "Farm" && (
        <>
          <Title level={3}>Edit Farm</Title>
          <Text className="font-normal text-neutral-500">
            Here you can manage your farm settings, crops, and other details
            related to the farm.
          </Text>
        </>
      )}
      {sideBarMode === "Field" && (
        <>
          <Title level={3}>Edit Field</Title>
          <Text className="font-normal text-neutral-500">
            Here you can manage your field data, track crop growth, and adjust
            irrigation settings.
          </Text>
        </>
      )}
      <Divider />
      <Layout style={{ display: "flex", flexDirection: "row" }}>
        <Sider
          width={200}
          style={{
            background: "#fff",
          }}
        >
          <Menu
            mode="inline"
            selectedKeys={[sideBarMode]}
            style={{ height: "100%", borderRight: 0 }}
            onClick={handleModalMenuClick}
          >
            <Menu.Item key="Farm" icon={<Farm />}>
              Farm
            </Menu.Item>
            <Menu.Item key="Field" icon={<SquareDashed />}>
              Field
            </Menu.Item>
          </Menu>
        </Sider>
        <Content
          style={{
            padding: "0px 24px 24px 32px",
            background: "#fff",
            maxHeight: "400px",
            overflowY: "auto",
          }}
        >
          {sideBarMode === "Farm" && (
            <Form layout="vertical">
              <Form.Item
                label="Farm Name"
                rules={[{ required: true, message: "Please enter Farm Name!" }]}
              >
                <Input
                  type="text"
                  disabled={isEditFarmData}
                  placeholder="Enter a name"
                  value={
                    !farmFormData.farm_name ? null : farmFormData.farm_name
                  }
                  onChange={(e) => {
                    handleFarmInputChange("farm_name", e.target.value);
                    handleFieldInputChange("farm", e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Country"
                rules={[{ required: true, message: "Please enter Country!" }]}
              >
                <AutoComplete
                  name="country"
                  value={farmFormData.countryText}
                  disabled={isCountries || isEditFarmData}
                  options={
                    !countryOptoins
                      ? [{ value: null, label: "Unknown" }]
                      : [{ value: null, label: "Unknown" }, ...countryOptoins]
                  }
                  placeholder="Enter a country"
                  onChange={onCountryChange}
                  onSelect={onSelectCountry}
                  onSearch={(inputValue) => {
                    setCountryOptions(() => {
                      if (!inputValue) return [];
                      return countriesData
                        ?.filter(
                          (country) =>
                            country.country_name
                              ?.toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                        )
                        ?.map((country) => ({
                          value: country.id,
                          label: country.country_name,
                        }));
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Province/State"
                rules={[
                  {
                    required: true,
                    message: "Please enter Province/State!",
                  },
                ]}
              >
                <AutoComplete
                  disabled={isProvinceData || errorProvinceData}
                  value={farmFormData.province_stateText}
                  options={[
                    { value: null, label: "Unknown" },
                    ...provinceStateOptions,
                  ]}
                  placeholder="Enter a province/state"
                  onChange={onProvinceStateChange}
                  onSelect={onSelectProvinceState}
                  onSearch={(inputValue) => {
                    setProvinceStateOptions(() => {
                      if (!inputValue) return [];

                      return provinceStateDataOptions
                        .filter(
                          (provinceState) =>
                            provinceState.name
                              ?.toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                        )
                        ?.map((provinceState) => ({
                          value: provinceState.id,
                          label: provinceState.name,
                        }));
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="City"
                rules={[{ required: true, message: "Please enter City!" }]}
              >
                <AutoComplete
                  disabled={isCitiesData || errorCities || isEditFarmData}
                  value={
                    farmFormData.city === null ? "Unkonwn" : farmFormData.city
                  }
                  options={[
                    { value: null, label: "Unknown" },
                    ...citiesOptions,
                  ]}
                  placeholder="Enter a city"
                  onChange={onCityChange}
                  onSearch={(inputValue) => {
                    setCitiesOptions(() => {
                      if (!inputValue) return [];

                      return citiesDataOptions
                        ?.filter(
                          (city) =>
                            city
                              ?.toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                        )
                        ?.map((city) => ({
                          value: city,
                          label: city,
                        }));
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Municipal District(County)"
                rules={[
                  {
                    required: true,
                    message: "Please enter Municipal District(County)!",
                  },
                ]}
              >
                <Select
                  disabled={isCountyData || errorCountyData || isEditFarmData}
                  showSearch
                  key={countyValue}
                  value={!farmFormData.county ? null : countyValue}
                  size="large"
                  placeholder="Select a county"
                  onChange={onCountyChange}
                  onSelect={onSelectCounty}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={
                    countyOptions.length !== 0
                      ? [
                          { value: null, label: "Unknown" },
                          ...countyOptions?.map((county) => ({
                            value: county?.id,
                            label: county?.name,
                          })),
                        ]
                      : [{ value: null, label: "Unknown" }]
                  }
                />
              </Form.Item>
              <Form.Item
                label="Planted Area"
                rules={[{ required: true, message: "Please enter acres!" }]}
              >
                <InputNumber
                  placeholder="Enter planted area"
                  disabled={isEditFarmData}
                  value={
                    farmFormData.planted_area === 0
                      ? null
                      : farmFormData.planted_area
                  }
                  addonAfter="Acres"
                  style={{ width: "100%" }}
                  onChange={(value) =>
                    handleFarmInputChange("planted_area", value)
                  }
                />
              </Form.Item>
              <Form.Item
                label="Postal Number"
                rules={[
                  {
                    required: true,
                    message: "Please enter Postal Number!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter the postal address"
                  value={
                    farmFormData.postal_address === "0"
                      ? null
                      : farmFormData.postal_address
                  }
                  onChange={(e) =>
                    handleFarmInputChange("postal_address", e.target.value)
                  }
                />
              </Form.Item>
              <Collapse
                defaultActiveKey={["lld"]}
                key={"lld"}
                expandIconPosition="end"
                ghost
              >
                {/* Collapsible Crop Section */}
                <Panel
                  header="Legal Land Description"
                  key="1"
                  className="col-right-sidebar"
                >
                  <Form.Item
                    label="LLD"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Legal Land Description",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter the lld"
                      disabled={isEditFarmData}
                      value={farmFormData.lld === "0" ? null : farmFormData.lld}
                      onChange={(e) =>
                        handleFarmInputChange("lld", e.target.value)
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="Quarter Section"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Quarter Section",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter the quater_section"
                      disabled={isEditFarmData}
                      value={
                        farmFormData.quarter_section === "0"
                          ? null
                          : farmFormData.quarter_section
                      }
                      onChange={(e) =>
                        handleFarmInputChange("quarter_section", e.target.value)
                      }
                    />
                  </Form.Item>
                  <Row gutter={8}>
                    <Col className="gutter-row" span={12}>
                      <Form.Item
                        label="Section"
                        rules={[
                          {
                            required: true,
                            message: "Please enter LLD Section",
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="Enter the section"
                          disabled={isEditFarmData}
                          value={
                            farmFormData.section === "0"
                              ? null
                              : farmFormData.section
                          }
                          style={{ width: "100%" }}
                          onChange={(value) =>
                            handleFarmInputChange("section", value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                      <Form.Item
                        label="Township"
                        rules={[
                          {
                            required: true,
                            message: "Please enter LLD Township",
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="Enter the township"
                          disabled={isEditFarmData}
                          value={
                            farmFormData.township === "0"
                              ? null
                              : farmFormData.township
                          }
                          style={{ width: "100%" }}
                          onChange={(value) =>
                            handleFarmInputChange("township", value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                      <Form.Item
                        label="Range"
                        rules={[
                          {
                            required: true,
                            message: "Please enter LLD Range",
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="Enter the range"
                          disabled={isEditFarmData}
                          value={
                            farmFormData.range === "0"
                              ? null
                              : farmFormData.range
                          }
                          style={{ width: "100%" }}
                          onChange={(value) =>
                            handleFarmInputChange("range", value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                      <Form.Item
                        label="Meridian"
                        rules={[
                          {
                            required: true,
                            message: "Please enter LLD Meridian",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter the meridian"
                          disabled={isEditFarmData}
                          value={
                            farmFormData.meridian === "0"
                              ? null
                              : farmFormData.meridian
                          }
                          onChange={(e) =>
                            handleFarmInputChange("meridian", e.target.value)
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Form>
          )}
          {sideBarMode === "Field" && (
            <Form layout="vertical" disabled={isEditFieldData}>
              <Form.Item>
                <div className="flex justify-between">
                  <Select
                    style={{ width: "35%" }}
                    value={currentField}
                    showSearch
                    disabled={isEditFieldData || isCreateFieldData}
                    placeholder="Select a field"
                    onChange={(value) => {
                      dispatch({ type: "selectField", payload: value });
                      farmFieldData(
                        { farm_field_id: value, year: year, season: season },
                        {
                          onSuccess: (data) => {
                            handleFieldInputChange(
                              "field_name",
                              data.data.field_name ? data.data.field_name : 0
                            );
                            handleFieldInputChange(
                              "soil_texture",
                              data.data.soil_type ? data.data.soil_type : 0
                            );
                            handleFieldInputChange(
                              "irregation_type",
                              data.data.irrigation_type
                                ? data.data.irrigation_type
                                : 0
                            );
                            handleFieldInputChange(
                              "planted_area",
                              data.data.area_in_acre
                            );
                            handleFieldInputChange(
                              "approximate_crop_height",
                              data.data.approximate_crop_height
                            );
                            handleFieldInputChange(
                              "id",
                              data.data.farm_field_crops.id
                                ? data.data.farm_field_crops.id
                                : 0
                            );
                            handleFieldInputChange(
                              "crop_name",
                              data.data.farm_field_crops.crop
                                ? data.data.farm_field_crops.crop
                                : 0
                            );
                            handleFieldInputChange(
                              "crop_stage",
                              data.data.farm_field_crops.crop_stage
                                ? data.data.farm_field_crops.crop_stage
                                : 0
                            );
                            handleFieldInputChange(
                              "weeds",
                              data.data.farm_field_crops.farm_field_weeds
                                ? data.data.farm_field_crops.farm_field_weeds?.map(
                                    (weed) => ({
                                      weed_name: weed.weed,
                                      weed_density: weed.weed_density,
                                      leaves_number:
                                        weed.weed_stage.number_of_leaves,
                                      height: weed.weed_stage.heihgt_in_cm,
                                      flowering: weed.weed_stage.flowering,
                                    })
                                  )
                                : []
                            );
                          },
                        }
                      );
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={farmFields?.map((field) => ({
                      value: field.id,
                      label: field.field_name,
                    }))}
                  />
                  <Button
                    disabled={isCreateFieldData || isEditFieldData}
                    onClick={() => {
                      createFieldData(
                        {
                          data: {
                            farm: currentFarm,
                            field_name: `My Field ${farmFields.length + 1}`,
                            soil_type: null,
                            irrigation_type: null,
                            area_in_acre: 0,
                            approximate_crop_height: 0,
                            farm_field_crops: {
                              year: year,
                              season: season,
                              crop: null,
                              crop_stage: null,
                            },

                            farm_field_weeds: [],
                          },
                        },
                        {
                          onSuccess: (data) => {

                            dispatch({ type: "selectField", payload: data.id });

                            farmFieldData(
                              {
                                farm_field_id: data.id,
                                year: year,
                                season: season,
                              },
                              {
                                onSuccess: (data) => {
                                  if (data.data.farm_field_crops.id) {
                                    handleFieldInputChange(
                                      "id",
                                      data.data.farm_field_crops.id
                                    );
                                  }
                                },
                              }
                            );

                            dispatch({
                              type: "getFarmFields",
                              payload: [
                                ...farmFields,
                                {
                                  id: data.id,
                                  field_name: data.field_name,
                                  sessions: [],
                                },
                              ],
                            });
                            dispatch({ type: "selectField", payload: data.id });
                            handleFieldInputChange(
                              "field_name",
                              data.field_name
                            );
                            handleFieldInputChange("soil_texture", 0);
                            handleFieldInputChange("irregation_type", 0);
                            handleFieldInputChange("planted_area", 0);
                            handleFieldInputChange(
                              "approximate_crop_height",
                              0
                            );
                            handleFieldInputChange("crop_name", 0);
                            handleFieldInputChange("crop_stage", 0);
                            handleFieldInputChange("weeds", []);

                            localStorage.removeItem("precisionHash");
                            navigate("/c/precision");

                            message.success("Your Field has been created");
                          },
                          onError: () => {
                            message.error(
                              "Something went wrong while adding the field, Please try again!"
                            );
                          },
                        }
                      );
                    }}
                  >
                    <Plus /> Add
                  </Button>
                </div>
                <Divider />
              </Form.Item>
              <Form.Item
                label="Field Name"
                rules={[
                  { required: true, message: "Please enter Field Name!" },
                ]}
              >
                <Input
                  disabled={isEditFieldData}
                  placeholder="Enter a name"
                  value={
                    !fieldFormData.field_name ? null : fieldFormData.field_name
                  }
                  onChange={(e) =>
                    handleFieldInputChange("field_name", e.target.value)
                  }
                />
              </Form.Item>
              <Form.Item label="Soil Texture">
                <Select
                  disabled={isSoilTextureData || isEditFieldData}
                  value={
                    !fieldFormData.soil_texture
                      ? null
                      : fieldFormData.soil_texture
                  }
                  showSearch
                  placeholder="Select a texture"
                  onChange={handleSelectSoilTexture}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    { value: null, label: "Unknown" },
                    ...(Array.isArray(soilTextureData)
                      ? soilTextureData.map((soliTexture) => ({
                          value: soliTexture.id,
                          label: soliTexture.type_name,
                        }))
                      : []),
                  ]}
                />
              </Form.Item>
              <Form.Item label="Irrigation Type">
                <Select
                  disabled={isIrregationTypesData || isEditFieldData}
                  showSearch
                  value={
                    !fieldFormData.irregation_type
                      ? null
                      : fieldFormData.irregation_type
                  }
                  placeholder="Select a type"
                  onChange={handleSelectIrregationType}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={
                    !isIrregationTypesData && !errorIrregationTypesData
                      ? [
                          { value: null, label: "Unknown" },
                          ...irregationTypesData.map((irrigationType) => ({
                            value: irrigationType.id,
                            label: irrigationType.type_name,
                          })),
                        ]
                      : [{ value: null, label: "Unknown" }]
                  }
                />
              </Form.Item>
              <Form.Item
                label="Planted Area"
                rules={[{ required: true, message: "Please enter acres!" }]}
              >
                <InputNumber
                  placeholder="Enter the planted area"
                  addonAfter="Acres"
                  disabled={isEditFieldData}
                  value={
                    !fieldFormData.planted_area
                      ? null
                      : fieldFormData.planted_area
                  }
                  style={{ width: "100%" }}
                  onChange={(value) =>
                    handleFieldInputChange("planted_area", value)
                  }
                />
              </Form.Item>
              <Form.Item
                label="Approximate Crop Height"
                rules={[
                  {
                    required: true,
                    message: "Please enter crop height!",
                  },
                ]}
              >
                <InputNumber
                  addonAfter="CM"
                  disabled={isEditFieldData}
                  value={
                    !fieldFormData.approximate_crop_height
                      ? null
                      : fieldFormData.approximate_crop_height
                  }
                  placeholder="Enter approximate crop height"
                  style={{ width: "100%" }}
                  onChange={(value) =>
                    handleFieldInputChange("approximate_crop_height", value)
                  }
                />
              </Form.Item>
              <Collapse
                defaultActiveKey={["cropCollapse"]}
                expandIconPosition="end"
                key={"cropCollapse"}
                ghost
              >
                {/* Collapsible Crop Section */}
                <Panel header="Crop" key="1" className="col-right-sidebar">
                  <Form.Item
                    label="Crop Name"
                    name="cropName"
                    rules={[
                      {
                        required: true,
                        message: "Please select a crop",
                      },
                    ]}
                  >
                    <TreeSelect
                      showSearch
                      key={currentField || fieldFormData.crop_name}
                      value={
                        fieldFormData.crop_name === 0 ? "unknown" : treeSelectValue
                      }
                      // value={fieldFormData.crop_name === 0 || !treeSelectValue ? "unknown" : treeSelectValue}
                      treeData={
                        treeSelectData
                          ? [{ value: "unknown", label: "Unknown", title: "Unknown", key: "unknown",children: [] }, ...treeSelectData].map((item) => {
                            // console.log(item)
                            return item
                          })
                          : [{ value: "unknown", label: "Unknown", title: "Unknown", key: "unknown", children: [] }]
                      }
                      onChange={onTreeSelectChange}
                      defaultValue={
                        fieldFormData.crop_name === 0 ? "unknown" : treeSelectValue
                      }
                      // defaultValue={
                      //   fieldFormData.crop_name === 0 || !treeSelectValue ? "unknown" : treeSelectValue
                      // }
                      // filterTreeNode={filterTreeNode}
                      placeholder="Please select a crop"
                      disabled={isCropGroups || isEditFieldData}
                    />
                  </Form.Item>

                  <Form.Item label="Crop Stage" name="cropStage">
                    <Select
                      disabled={isCropData || isEditFieldData}
                      key={fieldFormData.crop_stage || currentField}
                      value={fieldFormData.crop_name === 0 ? null : stageValue}
                      defaultValue={
                        fieldFormData.crop_name === 0 ? null : stageValue
                      }
                      showSearch
                      onSelect={onSelectCropStage}
                      onChange={onChangeCropStage}
                      placeholder="Select a stage"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={
                        !stageOptions
                          ? null
                          : [
                              { value: null, label: "Unknown" },
                              ...stageOptions?.map((stage) => ({
                                value: stage.id,
                                label: stage.stage_name,
                              })),
                            ]
                      }
                    />
                  </Form.Item>
                </Panel>

                <Panel header="Weeds" key="2" className="col-right-sidebar">
                  <Form.List name="weeds">
                    {(fields, { add, remove }) => (
                      <>
                        {weeds?.map(
                          (
                            weed,
                            index,
                            { key, name, fieldKey, ...restField }
                          ) => (
                            <div
                              key={index || weed.weed_name}
                              style={{
                                marginBottom: "16px",
                                border: "1px solid #f0f0f0",
                                padding: "16px",
                                borderRadius: "5px",
                              }}
                            >
                              {/* Weed Name Field */}

                              <Form.Item
                                {...restField}
                                label="Weed Name"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select a weed name",
                                  },
                                ]}
                              >
                                <Select
                                  disabled={isWeeds || isEditFieldData}
                                  showSearch
                                  value={
                                    !weed.weed_name ? null : weed.weed_name
                                  }
                                  onChange={(value) => {
                                    updateWeed(index, "weed_name", value);
                                  }}
                                  placeholder="Select a weed"
                                  filterOption={(input, option) =>
                                    (option?.label ?? "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  options={[
                                    { value: null, label: "Unknown" },
                                    ...weedsData?.map((weed) => ({
                                      value: weed.id,
                                      label: weed.weed_type,
                                    })),
                                  ]}
                                />
                              </Form.Item>
                              {/* Weed Density Field */}
                              <Form.Item
                                {...restField}
                                label="Weed Density"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select weed density",
                                  },
                                ]}
                              >
                                <Select
                                  disabled={isWeedDensities || isEditFieldData}
                                  value={
                                    !weed.weed_density
                                      ? null
                                      : weed.weed_density
                                  }
                                  showSearch
                                  onChange={(value) => {
                                    updateWeed(index, "weed_density", value);
                                  }}
                                  placeholder="Select a Density"
                                  filterOption={(input, option) =>
                                    (option?.label ?? "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  options={[
                                    { value: null, label: "Unknown" },
                                    ...weedDensitiesData?.map(
                                      (weedDensity) => ({
                                        value: weedDensity.id,
                                        label: weedDensity.weed_density,
                                      })
                                    ),
                                  ]}
                                />
                              </Form.Item>

                              {/* Number of Leaves Field */}
                              <Form.Item
                                {...restField}
                                label="Number of Leaves"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please input the number of leaves",
                                  },
                                ]}
                              >
                                <InputNumber
                                  min={0}
                                  style={{ width: "100%" }}
                                  disabled={isEditFieldData}
                                  value={
                                    !weed.leaves_number
                                      ? null
                                      : weed.leaves_number
                                  }
                                  placeholder="Enter the leaves number"
                                  onChange={(value) => {
                                    updateWeed(index, "leaves_number", value);
                                  }}
                                />
                              </Form.Item>

                              {/* Height Field */}
                              <Form.Item
                                {...restField}
                                label="Height"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input the height",
                                  },
                                ]}
                              >
                                <Input
                                  addonAfter="CM"
                                  value={!weed.height ? null : weed.height}
                                  type="number"
                                  disabled={isEditFieldData}
                                  placeholder="Enter height"
                                  onChange={(e) =>
                                    updateWeed(
                                      index,
                                      "height",
                                      Number(e.target.value)
                                    )
                                  }
                                />
                              </Form.Item>

                              {/* Flowering Toggle */}
                              <Form.Item
                                {...restField}
                                label="Flowering"
                                valuePropName="checked"
                              >
                                <Switch
                                  value={weed?.flowering}
                                  disabled={isEditFieldData}
                                  onChange={(value) =>
                                    updateWeed(index, "flowering", value)
                                  }
                                />
                              </Form.Item>

                              {/* Delete Button */}
                              <Form.Item>
                                <Button
                                  className="float-right"
                                  danger
                                  type="primary"
                                  disabled={isEditFieldData}
                                  onClick={() => {
                                    dispatch({
                                      type: "deleteWeed",
                                      payload: index,
                                    });
                                    remove(name);
                                  }}
                                  icon={<DeleteOutlined />}
                                  style={{
                                    backgroundColor: "#FFF",
                                    color: "#F5222D",
                                    boxShadow:
                                      "0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)",
                                  }}
                                >
                                  Delete
                                </Button>
                              </Form.Item>
                            </div>
                          )
                        )}

                        {/* Add Weed Button */}
                        <Form.Item>
                          <Button
                            type="dashed"
                            disabled={isEditFieldData}
                            onClick={() => {
                              dispatch({
                                type: "addWeed",
                              });
                              add();
                            }}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Weed
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Panel>
              </Collapse>
            </Form>
          )}
        </Content>
      </Layout>
    </Modal>
  );
}

export default ChatModal;
