import { ReactComponent as ErrorIcon } from "../assets/Vector2.svg";
import React, { useEffect, useState } from "react";
import {
  DownOutlined,
  LeftOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  message,
  Avatar,
  Modal,
  Form,
  Input,
  Select,
  Flex,
} from "antd";
import { useChat } from "../Context/ChatProvider";
import { useAuth } from "../Context/AuthProvider";
import {
  LogOut,
  ProfileCircle,
  XmarkCircle,
  CheckCircle,
  Mail,
  Lock,
  EditPencil,
} from "iconoir-react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useEditUser } from "../Features/Authentication/useEditUser";
import { useEditPassword } from "../Features/Authentication/useEditPassword";
import { useDeactivateRequest } from "../Features/Chat/useDeactivateRequest";
import { useDeactivateVerification } from "../Features/Chat/useDeactivateVerification";
const { Option } = Select;

const handleButtonClick = (e) => {
  message.info("Click on left button.");
};

export default function HeaderProfile({isMobile}) {
  const { bloomUser, handleEditBloomUser, dispatch, deactivateOtp } = useChat();
  const {
    userTypes,
    setUser: localUser,
    setUserToken,
    setUser: setAuthUser,
  } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  //const [passForm] = Form.useForm();
  const navigate = useNavigate();
  const { userToken } = useAuth();
  const [editProfileMode, setEditProfileMode] = useState("profile");
  const [deactiveSteps, setDeactiveSteps] = useState(1);
  const { editUserData, isEditingUser, errorEditUserData } = useEditUser();
  const { editPasswordData, isEditPasswordData } = useEditPassword();
  const [otp, setOtp] = useState("");

  const {
    deactiveRequestData,
    isDeactiveRequestData,
    errorDeactiveRequestData,
  } = useDeactivateRequest();

  const { deactiveVerifyData, isDeactiveVerifyData, errorDeactiveVerifyData } =
    useDeactivateVerification();

  const handleMenuProfileClick = () => {
    setIsModalVisible(true);
  };

  function errorMessage(message) {
    return (
      <>
        <ErrorIcon className="inline-block mb-0.5" />
        <span className="inline-block font-medium	pl-1">{message}</span>
      </>
    );
  }

  const handleLogOut = () => {
    localStorage.removeItem("userToken");
    message.success("You have logged out successfully.");
    window.location.reload();
    navigate("/login");
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (editProfileMode === "profile") {

          editUserData(
            {
              first_name: values.firstname,
              last_name: values.lastname,
              email: values.email,
              address: "address",
              user_type: values.role,
              access: JSON.parse(localStorage.getItem("userToken")).access,
            },
            {
              onSuccess: (data) => {
                setUserToken(data);
                setAuthUser(jwtDecode(data.access));
                localStorage.setItem("userToken", JSON.stringify(data));
                setIsModalVisible(false);
                message.success("Your profile has been updated");
              },
              onError: () => {
                message.error(
                  "Connection to the server faild, please try again!"
                );
              },
            }
          );
        } else if (editProfileMode === "password") {
          editPasswordData(
            {
              password: values.currentPass,
              new_password: values.newPass,
              confirm_password: values.confirmPass,
            },
            {
              onSuccess: (data) => {
                setIsModalVisible(false);
                setEditProfileMode("profile");
                message.success("Your password has been changed");
              },
              onError: (error) => {
                if (error.response && error.response.status === 406) {
                  message.error("Please fill out the fields correctly!");
                } else if (error.response && error.response.status === 422) {

                  if (error.response.data[0] === "worng credentials.") {
                    message.error(
                      "Current password is wrong, please try again!"
                    );
                  } else if (
                    error.response.data[0] ===
                    "This password is too short. It must contain at least 8 characters."
                  ) {
                    message.error(
                      "The password is too short. It must contain at least 8 characters!"
                    );
                  } else {
                    message.error("Passwords do not match. Please try again!");
                  }
                } else {
                  message.error(
                    "Connection to the server faild, please try again!"
                  );
                }
              },
            }
          );
        } else {
          if (deactiveSteps === 1) {
            if (otp.length === 6) {
              dispatch({ type: "storeDeactiveOtp", payload: otp });
              setDeactiveSteps(2);
            } else {
              message.error("Please enter 6 digits!");
            }
          } else {
            deactiveVerifyData(
              { code: deactivateOtp },
              {
                onSuccess: (data) => {
                  localStorage.removeItem("userToken");
                  localUser(null);
                  setIsModalVisible(true);
                  window.location.reload();
                },
                onError: (error) => {
                  if (error.response && error.response.status === 422) {
                    message.error("The otp is not correct,please try again!");
                    setDeactiveSteps(1);
                    setEditProfileMode("deactivate");
                    setOtp("");
                  } else {
                    message.error(
                      "Connection with the server faild,please try again!"
                    );
                    setDeactiveSteps(1);
                    setOtp("");
                    setEditProfileMode("profile");
                  }
                },
              }
            );
          }
        }
      })
      .catch((info) => {
        message.error("Please fill out the form with valid data!");
      });
  };

  const handleCancel = () => {
    setEditProfileMode("profile");
    setIsModalVisible(false);
  };
  const [user, setUser] = useState(bloomUser);
  const [isDiscarding, setIsDiscarding] = useState(false);

  const handleDiscard = (e) => {
    e.preventDefault();

    if (editProfileMode === "profile") {
      setIsDiscarding(true);
      dispatch({ type: "setBloomUser", payload: jwtDecode(userToken.access) });
    } else if (editProfileMode === "password") {
      form.setFieldsValue({
        currentPass: "",
        newPass: "",
        confirmPass: "",
      });
      setEditProfileMode("profile");
    } else {
      if (deactiveSteps === 1) {
        setOtp("");
        setEditProfileMode("profile");
      } else {
        setOtp("");
        setEditProfileMode("profile");
        setDeactiveSteps(1);
        dispatch({ type: "storeDeactiveOtp", payload: "" });
      }
    }

    setIsModalVisible(false);
  };

  useEffect(() => {
    if (isDiscarding && user) {
      form.setFieldsValue({
        firstname: bloomUser?.first_name || "",
        lastname: bloomUser?.last_name || "",
        email: bloomUser?.email || "",
        password: "********",
        address: bloomUser?.address || "",
        role:
          userTypes?.find((type) => type.id === bloomUser?.user_type)?.id || null,
      });

      setIsDiscarding(false);
    }
  }, [bloomUser]);

  useEffect(() => {
    setUser(bloomUser);
  }, [bloomUser]);

  const deactivateHandler = () => {
    setEditProfileMode("deactivate");

    deactiveRequestData({
      onSuccess: (data) => {},
    });
  };

  const backProfileHandler = () => {
    setEditProfileMode("profile");
  };
  const [isHovered, setIsHovered] = useState(false);

  const items = [
    {
      label: "Profile",
      key: "1",
      icon: <ProfileCircle />,
      onClick: handleMenuProfileClick,
    },
    {
      label: "Log out",
      key: "3",
      icon: <LogOut />,
      danger: true,
      onClick: handleLogOut,
    },
  ];

  const menuProps = {
    items,
  };

  return (
    <>
      <Dropdown menu={menuProps}>
        <Button
          style={{
            padding: "8px",
            // width: "250px",
            overflow: "hidden",
          }}
          className="justify-start h-auto rounded-lg w-auto lg:w-64"
        >
          <Avatar size={38} icon={<UserOutlined />} />
          <div
            style={{ flex: "1 0 0" }}
            className="flex flex-col justify-center items-start"
          >
            {isMobile ? null : <>
              <p className="text-neutral-500 text-xs">
                {userTypes
                  ? userTypes?.filter((type) => type?.id === user?.user_type)[0]
                    ?.type_name
                  : ""}
              </p>

              <h3 className="text-neutral-800 text-sm font-semibold">
                {!user?.first_name && !user?.last_name
                  ? ""
                  : `${user?.first_name.length > 10
                    ? user?.first_name.slice(0, 10) + "..."
                    : user?.first_name
                  } ${user?.last_name.length > 10
                    ? user?.last_name.slice(0, 10) + "..."
                    : user?.last_name
                  }`}
              </h3>
            </>}
            
          </div>
          <DownOutlined />
        </Button>
      </Dropdown>
      <Modal
        className="profile-modal"
        title={
          editProfileMode === "profile"
            ? "Edit Profile"
            : editProfileMode === "password"
            ? "Change Password"
            : "Deactivate Account"
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          deactiveSteps !== 2
            ? [
                <Button
                  key="deactivate"
                  className={`bg-white border ${
                    editProfileMode === "profile" ? "text-red-500" : null
                  } border-neutral-300 float-left shadow deactivate-account-btn hidden`}
                  size="large"
                  onClick={
                    editProfileMode === "profile"
                      ? deactivateHandler
                      : backProfileHandler
                  }
                  disabled={
                    isEditingUser ||
                    isEditPasswordData ||
                    isDeactiveRequestData ||
                    isDeactiveVerifyData
                  }
                >
                  {}
                  {editProfileMode === "profile" ? "" : <LeftOutlined />}
                  {editProfileMode === "profile"
                    ? "Deactivate Account"
                    : "Back"}
                </Button>,
                <Button
                  icon={<XmarkCircle width={18} color="#262626" />}
                  key="discard"
                  onClick={handleDiscard}
                  className={`shadow discard-profile-btn ${
                    isEditingUser ||
                    isEditPasswordData ||
                    isDeactiveRequestData ||
                    isDeactiveVerifyData
                      ? "bg-neutral-100 opacity-50"
                      : null
                  }`}
                  size="large"
                  disabled={
                    isEditPasswordData ||
                    isEditingUser ||
                    isDeactiveRequestData ||
                    isDeactiveVerifyData
                  }
                  type="button"
                >
                  Discard
                </Button>,

                <Button
                  icon={
                    editProfileMode === "profile" ? (
                      <CheckCircle width={18} />
                    ) : (
                      <LockOutlined />
                    )
                  }
                  disabled={
                    isEditPasswordData ||
                    isEditingUser ||
                    isDeactiveRequestData ||
                    isDeactiveVerifyData
                  }
                  key="save"
                  type="primary"
                  onClick={handleOk}
                  style={isHovered ? { backgroundColor: "#992020" } : null}
                  onMouseEnter={() =>
                    editProfileMode === "deactivate" ? setIsHovered(true) : null
                  }
                  onMouseLeave={() =>
                    editProfileMode === "deactivate"
                      ? setIsHovered(false)
                      : null
                  }
                  className={`shadow ${
                    editProfileMode === "deactivate"
                      ? "bg-red-500 -translate-y-2"
                      : null
                  } ${
                    editProfileMode === "password" ? " -translate-y-2" : null
                  } `}
                  size="large"
                >
                  {editProfileMode === "profile"
                    ? "Save"
                    : editProfileMode === "password"
                    ? "Set password"
                    : "Deactivate Account"}
                </Button>,
              ]
            : null
        }
        centered
      >
        <p className="text-sm text-neutral-500 mb-4 pb-4 border-b border-neutral-300">
          {editProfileMode === "profile"
            ? "Update your profile details below. You can edit your name, email, and other information as needed."
            : editProfileMode === "password"
            ? "Enter your current password and choose a new one."
            : "Take a break by deactivating your account, with the option to restore it later."}
        </p>

        {editProfileMode === "profile" ? (
          <Form
            form={form}
            layout="vertical"
            name="profileForm"
            className="profile-form"
            requiredMark={false}
            initialValues={{
              firstname: user?.first_name || "",
              lastname: user?.last_name || "",
              email: user?.email || "",
              password: "********",
              address: user?.address || "",
              role:
                userTypes?.find((type) => type.id === user?.user_type)?.id || null,
            }}
          >
            <div className="flex gap-4">
              <Form.Item
                label="First Name"
                name="firstname"
                rules={[
                  { required: true, message: "Please input your first name!" },
                ]}
                required={false}
                className="w-1/2"
              >
                <Input
                  maxLength={14}
                  onChange={(e) =>
                    handleEditBloomUser("first_name", e.target.value)
                  }
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="lastname"
                rules={[
                  { required: true, message: "Please input your last name!" },
                ]}
                required={false}
                className="w-1/2"
              >
                <Input
                  maxLength={14}
                  size="large"
                  onChange={(e) =>
                    handleEditBloomUser("last_name", e.target.value)
                  }
                />
              </Form.Item>
            </div>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please input a valid email!",
                },
              ]}
              required={false}
            >
              <Input
                type="email"
                size="large"
                onChange={(e) => handleEditBloomUser("email", e.target.value)}
                prefix={<Mail width={18} height={18} color="#262626" />}
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
              required={false}
            >
              <div className="flex gap-2">
                <Input.Password
                  size="large"
                  value={"*******"}
                  disabled
                  prefix={<Lock width={18} height={18} color="#262626" />}
                />
                <Button
                  onClick={() => setEditProfileMode("password")}
                  size="large"
                  icon={<EditPencil width={18} height={18} />}
                />
              </div>
            </Form.Item>
            <Form.Item
              label="Role"
              name="role"
              rules={[{ required: true, message: "Please select your role!" }]}
              required={false}
            >
              <Select
                size="large"
                onChange={(value) => handleEditBloomUser("user_type", value)}
                defaultValue={null}
              >
                {userTypes ? (
                  [
                    <Option value={null} label={"Unknown"}>
                      Unknown
                    </Option>,
                    ...userTypes?.map((type) => (
                      <Option value={type.id} label={type.type_name}>
                        {type.type_name}
                      </Option>
                    )),
                  ]
                ) : (
                  <Option value={null} label={"Unknown"}>
                    Unknown
                  </Option>
                )}
              </Select>
            </Form.Item>
          </Form>
        ) : editProfileMode === "password" ? (
          <Form
            form={form}
            layout="vertical"
            name="passwordForm"
            className="profile-form"
            requiredMark={false}
            initialValues={{
              currentPass: "",
              newPass: "",
              confirmPass: "",
            }}
          >
            <Form.Item
              label="Current Password"
              name="currentPass"
              rules={[
                {
                  required: true,
                  message: "Please input your current password!",
                },
              ]}
              required={false}
            >
              <Input.Password
                min={10}
                size="large"
                prefix={<Lock width={18} height={18} color="#262626" />}
              />
            </Form.Item>
            <Form.Item
              label="New Password"
              name="newPass"
              rules={[
                {
                  required: true,
                  message: "Please input your new password!",
                },
              ]}
              required={false}
            >
              <Input.Password
                size="large"
                prefix={<Lock width={18} height={18} color="#262626" />}
              />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirmPass"
              rules={[
                {
                  required: true,
                  message: "Please input a valid email!",
                },
              ]}
              required={false}
            >
              <Input.Password
                size="large"
                prefix={<Lock width={18} height={18} color="#262626" />}
              />
            </Form.Item>
          </Form>
        ) : deactiveSteps === 1 ? (
          <Form
            form={form}
            layout="vertical"
            name="verifyOtp"
            className="profile-form"
            requiredMark={false}
          >
            <Form.Item
              name="otp"
              className="mx-20 py-10"
              rules={[
                {
                  required: true,
                  message: "Please enter the otp!",
                },
              ]}
            >
              <Flex gap="middle" vertical className="mb-2">
                <Input.OTP
                  length={6}
                  formatter={(str) => str.replace(/\D/g, "")}
                  value={otp}
                  variant="filled"
                  onChange={(value) => {
                    setOtp(value);
                  }}
                />
              </Flex>
            </Form.Item>
          </Form>
        ) : (
          <div className="flex justify-end gap-4">
            <Button
              icon={<XmarkCircle width={18} color="#262626" />}
              key="discard"
              onClick={handleDiscard}
              className="shadow discard-profile-btn"
              size="large"
              type="button"
            >
              Discard
            </Button>
            <Button
              icon={<LockOutlined />}
              key="save"
              type="primary"
              disabled={isDeactiveVerifyData}
              onClick={handleOk}
              style={isHovered ? { backgroundColor: "#992020" } : null}
              onMouseEnter={() =>
                editProfileMode === "deactivate" ? setIsHovered(true) : null
              }
              onMouseLeave={() =>
                editProfileMode === "deactivate" ? setIsHovered(false) : null
              }
              className={"bg-red-500 "}
              size="large"
            >
              "Deactivate Account"
            </Button>
          </div>
        )}
      </Modal>
    </>
  );
}
