import { useMutation } from "react-query";
import { precisionHostSendChat } from "../../Services/apiBloom";

export function usePrecisionHostSendChat() {
  const {
    mutate: sendHostPrecisionChatData,
    isLoading: isHostSendPrecisionChatData,
    error: errorHostSendPrecisionChatData,
  } = useMutation({
    mutationFn: precisionHostSendChat,
  });

  return {
    sendHostPrecisionChatData,
    isHostSendPrecisionChatData,
    errorHostSendPrecisionChatData,
  };
}
