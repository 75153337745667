import { useMutation } from "react-query";
import { postDeactivateVerification } from "../../Services/apiBloom";

export function useDeactivateVerification() {
  const {
    mutate: deactiveVerifyData,
    isLoading: isDeactiveVerifyData,
    error: errorDeactiveVerifyData,
  } = useMutation({
    mutationFn: postDeactivateVerification,
  });

  return {
    deactiveVerifyData,
    isDeactiveVerifyData,
    errorDeactiveVerifyData,
  };
}
