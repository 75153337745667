import { useQuery } from "react-query";
import { userTypes } from "../../Services/apiAuth";

export function useUserType() {
  const {
    data: userTypesData,
    isLoading: isUserTypesData,
    error: errorUserTypes,
  } = useQuery({
    queryKey: ["userTypes"],
    queryFn: userTypes,
  });

  return {
    userTypesData,
    isUserTypesData,
    errorUserTypes,
  };
}
