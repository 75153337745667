// src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App"; // Main App component
import { ConfigProvider } from "antd";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#16a34a",
          colorInfo: "#22c55e",
          colorError: "#ef4444",
        },
        components: {
          Button: {
            colorPrimary: "#22c55e",
            colorPrimaryHover: "#16a34a",
            colorLinkHover: "#16a34a",
          },
          Typography: {
            colorLinkHover: "#16a34a",
          },
          Menu: {
            itemMarginInline: 0,
            itemSelectedBg: "rgba(245,245,245)",
            itemHoverBg: "rgba(255,255,255,0.06)",
            itemSelectedColor: "rgba(22,163,74,1)",
            itemActiveBg: "rgb(255,255,255)",
            horizontalItemHoverColor: "rgb(255,255,255)",
            margin: 0,
            marginXS: 0,
            itemHeight: 44,
            itemColor: "rgb(38,38,38)",
            subMenuItemBg: "rgba(255,255,255,0)",
          },
          Form: {
            labelColor: "rgb(115,115,115)",
            colorBorder: "rgb(212,212,212)",
            labelRequiredMarkColor: "rgb(115,115,115)",
            controlHeight: 36,
            controlHeightSM: 36,
            controlHeightLG: 36,
            verticalLabelPadding: "0 0 6px",
          },
        },
        hashed: false,
      }}
    >
      <App />
    </ConfigProvider>
  </React.StrictMode>
);
