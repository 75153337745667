import { useMutation } from "react-query";
import { precisionSendChat } from "../../Services/apiBloom";
import { message } from "antd";

export function useSendPrecisionChat() {
  const {
    mutate: sendPrecisionData,
    isLoading: isSendPrecisionData,
    error: errorSendPrecisionData,
  } = useMutation({
    mutationFn: precisionSendChat,
    onError: (error) => {
      if (error.response && error.response.status === 406) {
        message.error("Please select a crop for your field!");
      } else {
        message.error("Connection to the server faild, please try again!");
      }
    },
  });

  return {
    sendPrecisionData,
    isSendPrecisionData,
    errorSendPrecisionData,
  };
}
