import { useMutation } from "react-query";
import { registerMarketing } from "../../Services/apiAuth";

export function useRegisterMarketing() {

  const { mutate: registerMarketingData, isLoading: isRegisterMarketing } =
    useMutation({
      mutationFn: registerMarketing,
    });
  return {
    registerMarketingData,
    isRegisterMarketing,
  };
}
